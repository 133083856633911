import { fontLineHeight, getSkeletonStyle } from '@porsche-design-system/components-react/styles'
import styled, { CSSObject } from 'styled-components'

const SkeletonStyleLightRetyped = (() => {
    const baseType = getSkeletonStyle({ theme: 'light' })
    const explicitSubType: CSSObject = baseType['@keyframes skeletonAnimation']
    return { ...baseType, '@keyframes skeletonAnimation': explicitSubType }
})()

const SkeletonDiv = styled.div(SkeletonStyleLightRetyped)

export const SkeletonText = styled(SkeletonDiv)({
    height: fontLineHeight,
    width: '100px',
})
