/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LikertQuestionEvent } from './LikertQuestionEvent';
import {
    LikertQuestionEventFromJSON,
    LikertQuestionEventFromJSONTyped,
    LikertQuestionEventToJSON,
} from './LikertQuestionEvent';

/**
 * 
 * @export
 * @interface LikertQuestion
 */
export interface LikertQuestion {
    /**
     * 
     * @type {LikertQuestionEvent}
     * @memberof LikertQuestion
     */
    event: LikertQuestionEvent;
    /**
     * 
     * @type {string}
     * @memberof LikertQuestion
     */
    questionTitle: string;
    /**
     * 
     * @type {string}
     * @memberof LikertQuestion
     */
    answerLabel: string;
}

/**
 * Check if a given object implements the LikertQuestion interface.
 */
export function instanceOfLikertQuestion(value: object): value is LikertQuestion {
    if (!('event' in value) || value['event'] === undefined) return false;
    if (!('questionTitle' in value) || value['questionTitle'] === undefined) return false;
    if (!('answerLabel' in value) || value['answerLabel'] === undefined) return false;
    return true;
}

export function LikertQuestionFromJSON(json: any): LikertQuestion {
    return LikertQuestionFromJSONTyped(json, false);
}

export function LikertQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LikertQuestion {
    if (json == null) {
        return json;
    }
    return {
        
        'event': LikertQuestionEventFromJSON(json['event']),
        'questionTitle': json['questionTitle'],
        'answerLabel': json['answerLabel'],
    };
}

export function LikertQuestionToJSON(value?: LikertQuestion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event': LikertQuestionEventToJSON(value['event']),
        'questionTitle': value['questionTitle'],
        'answerLabel': value['answerLabel'],
    };
}

