import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface SearchLinkParametersQueryProps {
    purchaseRequestId: string
    dealerEntryDn: string
    languageTag: string
}

export function getSearchLinkParametersQueryKey(id: string, dealerEntryDn: string, languageTag: string) {
    return ['searchLinkParametersData', id, dealerEntryDn, languageTag]
}

export const useSearchLinkParametersQuery = ({
    purchaseRequestId,
    dealerEntryDn,
    languageTag,
}: SearchLinkParametersQueryProps) =>
    useQuery({
        queryKey: getSearchLinkParametersQueryKey(purchaseRequestId, dealerEntryDn, languageTag),
        queryFn: () =>
            purchaseRequestDealerAppEndpointsApi.getVehicleSearchParameters({
                id: purchaseRequestId,
                dealerEntryDn: dealerEntryDn,
                languageTag,
            }),
    })
