import { createContext, PropsWithChildren, useContext } from 'react'

import type { AppProps } from '~/app/app'

export type Environment = AppProps['context']['environment']
const supportedEnvironments = ['dev', 'preview', 'prod', 'local'] as const
type SupportedEnvironment = (typeof supportedEnvironments)[number]

export const map = (environment: Environment): SupportedEnvironment => {
    if (!environment) throw new Error('Environment must not be undefined or null')
    if (!supportedEnvironments.includes(environment)) throw Error('Unsupported environment')

    return environment
}

export type Config = object

const ConfigContext = createContext<Config>({})

type ConfigProviderProps = {
    environment: Environment
} & PropsWithChildren

export const FeatureFlagProvider = ({ children }: ConfigProviderProps) => {
    const config: Config = {}

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

export const useFeatureFlagContext = () => useContext(ConfigContext)
