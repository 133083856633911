import {
    borderRadius,
    getMediaQueryMin,
    headingMediumStyle,
    themeLightContrastLow,
} from '@porsche-design-system/components-react/styles'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { TextXSmallDiv } from '~/app/base-components/styled/Text'

import { ContentBox } from './PriceEstimation.styled'

type PriceTileProps = {
    elements: {
        label: string
        element: ReactNode
    }[]
    FeedbackElement?: ReactNode
    heading?: string
}
export const PriceTile = ({ heading, FeedbackElement, elements }: PriceTileProps) => {
    return (
        <div>
            {heading ? <HeadingMedium>{heading}</HeadingMedium> : null}
            <PriceTileGrid>
                <ContentBox>
                    <PriceTileContent>
                        {elements.map((item) => item.element)}
                        {elements.map((item) => (
                            <TextXSmallDiv>{item.label}</TextXSmallDiv>
                        ))}
                    </PriceTileContent>
                </ContentBox>
                {FeedbackElement}
            </PriceTileGrid>
        </div>
    )
}

const Grid = styled.div`
    display: grid;
`
export const PriceTileGrid = styled(Grid)`
    border: 1px solid ${themeLightContrastLow};
    border-radius: ${borderRadius.medium};
    align-content: center;
`

export const PriceTileContent = styled(Grid)`
    grid-template-columns: 1fr;

    ${getMediaQueryMin('xs')} {
        grid-template-columns: 1fr 1fr;
    }
`
export const HeadingMedium = styled.h5`
    ${headingMediumStyle};
    margin: 0;
`
