import {
    border,
    spacingFluidLarge,
    spacingFluidMedium,
    spacingFluidSmall,
    themeLightBackgroundShading,
    themeLightBackgroundSurface,
} from '@porsche-design-system/components-react/styles'
import React from 'react'
import { styled } from 'styled-components'

import { TextSmallSpan, TextSmallSpanSemiBold } from '~/app/base-components/styled/Text'
import { CopyToClipboardButton } from '~/app/components/copy-to-clipboard-button'

interface OfferStatusStatisticsCardProps {
    content:
        | { label: string; value: string | React.ReactNode; copyToClipboardButton?: boolean }[]
        | NonNullable<React.ReactNode>
    backgroundColor?: string
}

const OfferStatusCardWrapper = styled.div<{ $backgroundColor?: string }>`
    display: flex;
    flex-direction: row;
    border-radius: ${border.radius.medium};
    background: ${({ $backgroundColor }) => $backgroundColor || themeLightBackgroundSurface};
    padding: ${spacingFluidMedium} ${spacingFluidLarge} ${spacingFluidMedium} ${spacingFluidMedium};
    max-width: fit-content;
    gap: ${spacingFluidMedium};
`

const OfferStatusContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: ${spacingFluidSmall} 0;
`

const OfferStatusStatisticsCardLabel = styled(TextSmallSpan)`
    color: ${themeLightBackgroundShading};
    margin: 0;
    padding: 0;
`

const OfferStatusStatisticsCardValue = styled(TextSmallSpanSemiBold)`
    margin: 0;
    padding: 0;
`

function OfferStatusStatisticsCard({ content, backgroundColor }: OfferStatusStatisticsCardProps) {
    return (
        <OfferStatusCardWrapper $backgroundColor={backgroundColor}>
            {Array.isArray(content)
                ? content.map((item, index) => (
                      <OfferStatusContentWrapper key={`offer-status-statistics-card-${item.label}-${index}`}>
                          <OfferStatusStatisticsCardLabel>{item.label}</OfferStatusStatisticsCardLabel>
                          <OfferStatusStatisticsCardValue>
                              {item.value}
                              {item.copyToClipboardButton && typeof item.value === 'string' && (
                                  <>
                                      &nbsp;
                                      <CopyToClipboardButton text={item.value} />
                                  </>
                              )}
                          </OfferStatusStatisticsCardValue>
                      </OfferStatusContentWrapper>
                  ))
                : content}
        </OfferStatusCardWrapper>
    )
}

export default OfferStatusStatisticsCard
