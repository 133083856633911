/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelInformationResponseDto
 */
export interface ModelInformationResponseDto {
    /**
     * VIN of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    vin?: string;
    /**
     * Derivative / Model of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    derivative?: string;
    /**
     * Generation of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    generation?: string;
    /**
     * Engine Type of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    engineType?: string;
    /**
     * Body Type of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    bodyType?: string;
    /**
     * Drive train of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    driveTrain?: string;
    /**
     * Transmission type of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    transmission?: string;
    /**
     * (Exterior) Colour of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    colour?: string;
    /**
     * Country specification of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    countrySpecification?: string;
    /**
     * Model year of the Vehicle
     * @type {number}
     * @memberof ModelInformationResponseDto
     */
    modelYear?: number;
    /**
     * Marketplace formatted power of the Vehicle
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    power?: string;
    /**
     * Interior of the Vehicle (includes colour and materials)
     * @type {string}
     * @memberof ModelInformationResponseDto
     */
    interior?: string;
}

/**
 * Check if a given object implements the ModelInformationResponseDto interface.
 */
export function instanceOfModelInformationResponseDto(value: object): value is ModelInformationResponseDto {
    return true;
}

export function ModelInformationResponseDtoFromJSON(json: any): ModelInformationResponseDto {
    return ModelInformationResponseDtoFromJSONTyped(json, false);
}

export function ModelInformationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelInformationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'vin': json['vin'] == null ? undefined : json['vin'],
        'derivative': json['derivative'] == null ? undefined : json['derivative'],
        'generation': json['generation'] == null ? undefined : json['generation'],
        'engineType': json['engineType'] == null ? undefined : json['engineType'],
        'bodyType': json['bodyType'] == null ? undefined : json['bodyType'],
        'driveTrain': json['driveTrain'] == null ? undefined : json['driveTrain'],
        'transmission': json['transmission'] == null ? undefined : json['transmission'],
        'colour': json['colour'] == null ? undefined : json['colour'],
        'countrySpecification': json['countrySpecification'] == null ? undefined : json['countrySpecification'],
        'modelYear': json['modelYear'] == null ? undefined : json['modelYear'],
        'power': json['power'] == null ? undefined : json['power'],
        'interior': json['interior'] == null ? undefined : json['interior'],
    };
}

export function ModelInformationResponseDtoToJSON(value?: ModelInformationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vin': value['vin'],
        'derivative': value['derivative'],
        'generation': value['generation'],
        'engineType': value['engineType'],
        'bodyType': value['bodyType'],
        'driveTrain': value['driveTrain'],
        'transmission': value['transmission'],
        'colour': value['colour'],
        'countrySpecification': value['countrySpecification'],
        'modelYear': value['modelYear'],
        'power': value['power'],
        'interior': value['interior'],
    };
}

