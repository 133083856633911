import { PText } from '@porsche-design-system/components-react'
import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { DescriptionList, DescriptionListElement } from '~/app/components/shared/DescriptionList'

import { messages } from './messages'

export interface AdditionalInformationProps {
    tradeInInterest?: boolean
    customerComment?: string
}

export const AdditionalInformation = ({
    tradeInInterest,
    customerComment,
}: PropsWithChildren<AdditionalInformationProps>) => {
    const intl = useIntl()

    function getTradeInterestValue(tradeInInterest?: boolean) {
        if (tradeInInterest) {
            return intl.formatMessage(messages.tradeInInterestYes)
        }

        if (tradeInInterest === false) {
            return intl.formatMessage(messages.tradeInInterestNo)
        }

        return intl.formatMessage(messages.tradeInInterestNotAvailable)
    }

    const content: DescriptionListElement[] = [
        {
            label: intl.formatMessage(messages.tradeInInterestLabel),
            value: getTradeInterestValue(tradeInInterest),
        },
    ]

    if (customerComment) {
        content.push({
            label: intl.formatMessage(messages.customerCommentLabel),
            value: <PTextWithPreLine weight="semi-bold">{customerComment}</PTextWithPreLine>,
        })
    }

    return <DescriptionList heading={intl.formatMessage(messages.heading)} content={content} />
}

const PTextWithPreLine = styled(PText)`
    white-space: pre-line;
`
