import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    howDoesItWorkExplanation: {
        id: 'page.details.howDoesItWorkExplanation',
        defaultMessage: `
        <bold>1. Make an offer</bold>
        {newLine}
        If you are interested in the offered vehicle, click the „Make offer“ button on the top right.You can then create an initial, legally non-binding offer based on the vehicle data provided by the seller in his request and send it to the seller.
        {newLine}{newLine}
        <bold>2. Get in contact</bold>
        {newLine}
        If the seller is interested in your offer, you will receive an e-mail with their contact details.
        Now it’s on you to get in touch with the seller in order to clarify next steps, such as arranging on an appointment to check the condition of the vehicle.
        {newLine}{newLine}
        <bold>Good to know</bold>
        {newLine}
        The number of offers a seller can receive depends a) on the number of Porsche Centers the seller decided to offer his vehicle to (which can be one, a few or all in the market) and b) the number of Porsche Centers that are interested in the vehicle and send an offer.
        {newLine}
        But as soon as a seller accepts any offer, no further offers can be made by dealers. However, the seller can still show interest in all offers that they have received in their inbox so far.
        `,
    },
})
