import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface EquipmentQueryProps {
    purchaseRequestId: string
    dealerEntryDn: string
    languageTag: string
}

export function getEquipmentQueryKey(id: string, dealerEntryDn: string, languageTag: string) {
    return ['EQUIPMENT', id, dealerEntryDn, languageTag]
}

export const useEquipmentQuery = ({ purchaseRequestId, dealerEntryDn, languageTag }: EquipmentQueryProps) =>
    useQuery({
        queryKey: getEquipmentQueryKey(purchaseRequestId, dealerEntryDn, languageTag),
        queryFn: () =>
            purchaseRequestDealerAppEndpointsApi.getEquipment({
                id: purchaseRequestId,
                dealerEntryDn,
                languageTag,
            }),
    })
