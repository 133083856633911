import { MileageDtoUnitEnum } from 'dealer-prs-api-client'
import { IntlShape } from 'react-intl'

import { messages } from './messages'

export const formatDistance = (value: number | string, distanceUnit: 'KM' | 'SMI', intl: IntlShape) =>
    value === -1
        ? intl.formatMessage(messages.unknownDistance)
        : intl.formatNumber(Number(value), {
              style: 'unit',
              maximumFractionDigits: 0,
              // Unit identifier according to https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers
              unit: unitMap[distanceUnit],
              unitDisplay: 'short',
          })

/**
 * Maps the key of a MileageDtoUnit into a unit identifier according to https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers, to be used by internationalization function (e.g. for number formatting)
 */
const unitMap: { [key in MileageDtoUnitEnum]: 'kilometer' | 'mile' } = {
    KM: 'kilometer',
    SMI: 'mile',
}
