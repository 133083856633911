import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export const PURCHASE_REQUEST_QUERY_KEY = 'PURCHASE_REQUEST'

export interface PurchaseRequestQueryProps {
    id: string
    dealerEntryDn: string
}

export function getPurchaseRequestQueryKey(id: string, dealerEntryDn: string) {
    return [PURCHASE_REQUEST_QUERY_KEY, id, dealerEntryDn]
}

export const usePurchaseRequestQuery = ({ id, dealerEntryDn }: PurchaseRequestQueryProps) =>
    useQuery({
        queryKey: getPurchaseRequestQueryKey(id, dealerEntryDn),
        queryFn: () => purchaseRequestDealerAppEndpointsApi.getPurchaseRequest({ id, dealerEntryDn }),
    })
