import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    open: {
        id: 'components.overAllStatus.open',
        defaultMessage: 'Open',
    },
    offerSent: {
        id: 'components.overAllStatus.offerSent',
        defaultMessage: 'Offer sent',
    },
    offerAccepted: {
        id: 'components.overAllStatus.offerAccepted',
        defaultMessage: 'Offer accepted',
    },
    offerExpired: {
        id: 'components.overAllStatus.offerExpired',
        defaultMessage: 'Offer expired',
    },
    closed: {
        id: 'components.overAllStatus.closed',
        defaultMessage: 'Closed',
    },
    purchased: {
        id: 'components.overAllStatus.purchased',
        defaultMessage: 'Purchased',
    },
    purchasedByOther: {
        id: 'components.overAllStatus.purchasedByOther',
        defaultMessage: 'Purchased by other PC',
    },
    withdrawn: {
        id: 'components.overAllStatus.withdrawn',
        defaultMessage: 'Withdrawn by customer',
    },
    offerAcceptedByOtherInfoText: {
        id: 'components.overAllStatus.offerAcceptedByOtherInfoText',
        defaultMessage:
            'The customer has already shown interest in at least one offer from another Porsche Centre. However, he can still show interest in the offer you made.',
    },
    offerExpiredInfoText: {
        id: 'components.overAllStatus.offerExpiredInfoText',
        defaultMessage:
            'Offer expired (14-day limit). If the customer tries to accept it, they will be informed and can still contact you.',
    },
    withdrawnAfterOfferInfoText: {
        id: 'components.overAllStatus.withdrawnAfterOfferInfoText',
        defaultMessage:
            'The customer has actively rejected to receive further offers. However, he can still show interest in the offer you made.',
    },
})
