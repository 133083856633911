/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MSRPResponseDto
 */
export interface MSRPResponseDto {
    /**
     * localized marketPrice as recommended by manufacturer
     * @type {string}
     * @memberof MSRPResponseDto
     */
    totalPrice: string;
    /**
     * localized sum of option prices
     * @type {string}
     * @memberof MSRPResponseDto
     */
    optionSum: string;
}

/**
 * Check if a given object implements the MSRPResponseDto interface.
 */
export function instanceOfMSRPResponseDto(value: object): value is MSRPResponseDto {
    if (!('totalPrice' in value) || value['totalPrice'] === undefined) return false;
    if (!('optionSum' in value) || value['optionSum'] === undefined) return false;
    return true;
}

export function MSRPResponseDtoFromJSON(json: any): MSRPResponseDto {
    return MSRPResponseDtoFromJSONTyped(json, false);
}

export function MSRPResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MSRPResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'totalPrice': json['totalPrice'],
        'optionSum': json['optionSum'],
    };
}

export function MSRPResponseDtoToJSON(value?: MSRPResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalPrice': value['totalPrice'],
        'optionSum': value['optionSum'],
    };
}

