import { PText } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { messages } from './messages'

export const HowDoesItWork = () => {
    const intl = useIntl()

    return (
        <PText>
            {/* Not using our i18n() helper here due to tricky typing issues when using JSX elements as variables */}
            {intl.formatMessage(messages.howDoesItWorkExplanation, {
                bold: (str) => <b>{str}</b>,
                newLine: <br />,
            })}
        </PText>
    )
}
