/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceEstimationFeedbackDto
 */
export interface PriceEstimationFeedbackDto {
    /**
     * Is the price estimation helpful or not?
     * @type {boolean}
     * @memberof PriceEstimationFeedbackDto
     */
    priceEstimationIsHelpful: boolean;
    /**
     * Locale of the dealer
     * @type {string}
     * @memberof PriceEstimationFeedbackDto
     */
    locale: string;
    /**
     * Optional free text field for more specific feedback
     * @type {string}
     * @memberof PriceEstimationFeedbackDto
     */
    freeText?: string;
}

/**
 * Check if a given object implements the PriceEstimationFeedbackDto interface.
 */
export function instanceOfPriceEstimationFeedbackDto(value: object): value is PriceEstimationFeedbackDto {
    if (!('priceEstimationIsHelpful' in value) || value['priceEstimationIsHelpful'] === undefined) return false;
    if (!('locale' in value) || value['locale'] === undefined) return false;
    return true;
}

export function PriceEstimationFeedbackDtoFromJSON(json: any): PriceEstimationFeedbackDto {
    return PriceEstimationFeedbackDtoFromJSONTyped(json, false);
}

export function PriceEstimationFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceEstimationFeedbackDto {
    if (json == null) {
        return json;
    }
    return {
        
        'priceEstimationIsHelpful': json['priceEstimationIsHelpful'],
        'locale': json['locale'],
        'freeText': json['freeText'] == null ? undefined : json['freeText'],
    };
}

export function PriceEstimationFeedbackDtoToJSON(value?: PriceEstimationFeedbackDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'priceEstimationIsHelpful': value['priceEstimationIsHelpful'],
        'locale': value['locale'],
        'freeText': value['freeText'],
    };
}

