/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DistanceDto } from './DistanceDto';
import {
    DistanceDtoFromJSON,
    DistanceDtoFromJSONTyped,
    DistanceDtoToJSON,
} from './DistanceDto';
import type { ModelDetailsDto } from './ModelDetailsDto';
import {
    ModelDetailsDtoFromJSON,
    ModelDetailsDtoFromJSONTyped,
    ModelDetailsDtoToJSON,
} from './ModelDetailsDto';
import type { StatisticsDto } from './StatisticsDto';
import {
    StatisticsDtoFromJSON,
    StatisticsDtoFromJSONTyped,
    StatisticsDtoToJSON,
} from './StatisticsDto';
import type { MileageDto } from './MileageDto';
import {
    MileageDtoFromJSON,
    MileageDtoFromJSONTyped,
    MileageDtoToJSON,
} from './MileageDto';

/**
 * 
 * @export
 * @interface PurchaseRequestDto
 */
export interface PurchaseRequestDto {
    /**
     * ID of purchase request
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    id: string;
    /**
     * Details about the model
     * @type {ModelDetailsDto}
     * @memberof PurchaseRequestDto
     */
    modelDetails: ModelDetailsDto;
    /**
     * Date of first registration
     * @type {Date}
     * @memberof PurchaseRequestDto
     */
    firstRegistration: Date;
    /**
     * Mileage of the vehicle to be sold
     * @type {MileageDto}
     * @memberof PurchaseRequestDto
     */
    mileage: MileageDto;
    /**
     * Date when purchase request came in, e.g. 2023-07-10T10:26:17.741Z
     * @type {Date}
     * @memberof PurchaseRequestDto
     */
    requestDate: Date;
    /**
     * Status of the purchase request
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    status: PurchaseRequestDtoStatusEnum;
    /**
     * Status of the offer
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    offerStatus: PurchaseRequestDtoOfferStatusEnum;
    /**
     * Number of previous owners
     * @type {number}
     * @memberof PurchaseRequestDto
     */
    previousOwners: number;
    /**
     * Indicates whether the vehicle is accident free
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    isAccidentFree: boolean;
    /**
     * User-provided freetext regarding vehicle accident(s)
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    accidentDescription?: string;
    /**
     * Indicates whether the vehicle is damage free
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    isDamageFree: boolean;
    /**
     * User-provided freetext regarding vehicle damage(s)
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    damageDescription?: string;
    /**
     * Does the vehicle have a full service history?
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    fullServiceHistory: boolean;
    /**
     * Postal code of customer
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    postalCode: string;
    /**
     * URL to PCSS
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    pcssUrl?: string;
    /**
     * Whether the seller can deduct VAT from the sale (only set in Germany otherwise undefined)
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    vatDeductible?: boolean;
    /**
     * Whether the purchase request has been hidden by the requesting dealership
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    hidden: boolean;
    /**
     * Whether the purchase request is currently promoted / highlighted
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    promoted: boolean;
    /**
     * Distance from the dealership to the customer. If distance could not be determined for the request, the value is -1.
     * @type {DistanceDto}
     * @memberof PurchaseRequestDto
     */
    distance: DistanceDto;
    /**
     * Statistics about the purchase request, e.g. amount of offers
     * @type {StatisticsDto}
     * @memberof PurchaseRequestDto
     */
    statistics: StatisticsDto;
    /**
     * Aggregated status of purchase request and offer. One of OPEN, CLOSED, WITHDRAWN, WITHDRAWN_AFTER_OFFER, OFFER_SENT, OFFER_ACCEPTED, OFFER_ACCEPTED_BY_OTHER, PURCHASED, PURCHASED_BY_OTHER, OFFER_EXPIRED
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    overallStatus?: PurchaseRequestDtoOverallStatusEnum;
    /**
     * Additional notes added by customer
     * @type {string}
     * @memberof PurchaseRequestDto
     */
    additionalNotes?: string;
    /**
     * Indicates whether the customer is interested in more than just selling their vehicle
     * @type {boolean}
     * @memberof PurchaseRequestDto
     */
    tradeInInterest?: boolean;
}


/**
 * @export
 */
export const PurchaseRequestDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    CLOSED: 'CLOSED',
    ARCHIVED: 'ARCHIVED'
} as const;
export type PurchaseRequestDtoStatusEnum = typeof PurchaseRequestDtoStatusEnum[keyof typeof PurchaseRequestDtoStatusEnum];

/**
 * @export
 */
export const PurchaseRequestDtoOfferStatusEnum = {
    OPEN: 'OPEN',
    ACCEPTED: 'ACCEPTED',
    EXPIRED: 'EXPIRED',
    NO_OFFER: 'NO_OFFER'
} as const;
export type PurchaseRequestDtoOfferStatusEnum = typeof PurchaseRequestDtoOfferStatusEnum[keyof typeof PurchaseRequestDtoOfferStatusEnum];

/**
 * @export
 */
export const PurchaseRequestDtoOverallStatusEnum = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    WITHDRAWN: 'WITHDRAWN',
    WITHDRAWN_AFTER_OFFER: 'WITHDRAWN_AFTER_OFFER',
    OFFER_SENT: 'OFFER_SENT',
    OFFER_ACCEPTED: 'OFFER_ACCEPTED',
    OFFER_ACCEPTED_BY_OTHER: 'OFFER_ACCEPTED_BY_OTHER',
    PURCHASED: 'PURCHASED',
    PURCHASED_BY_OTHER: 'PURCHASED_BY_OTHER',
    OFFER_EXPIRED: 'OFFER_EXPIRED'
} as const;
export type PurchaseRequestDtoOverallStatusEnum = typeof PurchaseRequestDtoOverallStatusEnum[keyof typeof PurchaseRequestDtoOverallStatusEnum];


/**
 * Check if a given object implements the PurchaseRequestDto interface.
 */
export function instanceOfPurchaseRequestDto(value: object): value is PurchaseRequestDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('modelDetails' in value) || value['modelDetails'] === undefined) return false;
    if (!('firstRegistration' in value) || value['firstRegistration'] === undefined) return false;
    if (!('mileage' in value) || value['mileage'] === undefined) return false;
    if (!('requestDate' in value) || value['requestDate'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('offerStatus' in value) || value['offerStatus'] === undefined) return false;
    if (!('previousOwners' in value) || value['previousOwners'] === undefined) return false;
    if (!('isAccidentFree' in value) || value['isAccidentFree'] === undefined) return false;
    if (!('isDamageFree' in value) || value['isDamageFree'] === undefined) return false;
    if (!('fullServiceHistory' in value) || value['fullServiceHistory'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('hidden' in value) || value['hidden'] === undefined) return false;
    if (!('promoted' in value) || value['promoted'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    if (!('statistics' in value) || value['statistics'] === undefined) return false;
    return true;
}

export function PurchaseRequestDtoFromJSON(json: any): PurchaseRequestDto {
    return PurchaseRequestDtoFromJSONTyped(json, false);
}

export function PurchaseRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'modelDetails': ModelDetailsDtoFromJSON(json['modelDetails']),
        'firstRegistration': (new Date(json['firstRegistration'])),
        'mileage': MileageDtoFromJSON(json['mileage']),
        'requestDate': (new Date(json['requestDate'])),
        'status': json['status'],
        'offerStatus': json['offerStatus'],
        'previousOwners': json['previousOwners'],
        'isAccidentFree': json['isAccidentFree'],
        'accidentDescription': json['accidentDescription'] == null ? undefined : json['accidentDescription'],
        'isDamageFree': json['isDamageFree'],
        'damageDescription': json['damageDescription'] == null ? undefined : json['damageDescription'],
        'fullServiceHistory': json['fullServiceHistory'],
        'postalCode': json['postalCode'],
        'pcssUrl': json['pcssUrl'] == null ? undefined : json['pcssUrl'],
        'vatDeductible': json['vatDeductible'] == null ? undefined : json['vatDeductible'],
        'hidden': json['hidden'],
        'promoted': json['promoted'],
        'distance': DistanceDtoFromJSON(json['distance']),
        'statistics': StatisticsDtoFromJSON(json['statistics']),
        'overallStatus': json['overallStatus'] == null ? undefined : json['overallStatus'],
        'additionalNotes': json['additionalNotes'] == null ? undefined : json['additionalNotes'],
        'tradeInInterest': json['tradeInInterest'] == null ? undefined : json['tradeInInterest'],
    };
}

export function PurchaseRequestDtoToJSON(value?: PurchaseRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'modelDetails': ModelDetailsDtoToJSON(value['modelDetails']),
        'firstRegistration': ((value['firstRegistration']).toISOString().substring(0,10)),
        'mileage': MileageDtoToJSON(value['mileage']),
        'requestDate': ((value['requestDate']).toISOString()),
        'status': value['status'],
        'offerStatus': value['offerStatus'],
        'previousOwners': value['previousOwners'],
        'isAccidentFree': value['isAccidentFree'],
        'accidentDescription': value['accidentDescription'],
        'isDamageFree': value['isDamageFree'],
        'damageDescription': value['damageDescription'],
        'fullServiceHistory': value['fullServiceHistory'],
        'postalCode': value['postalCode'],
        'pcssUrl': value['pcssUrl'],
        'vatDeductible': value['vatDeductible'],
        'hidden': value['hidden'],
        'promoted': value['promoted'],
        'distance': DistanceDtoToJSON(value['distance']),
        'statistics': StatisticsDtoToJSON(value['statistics']),
        'overallStatus': value['overallStatus'],
        'additionalNotes': value['additionalNotes'],
        'tradeInInterest': value['tradeInInterest'],
    };
}

