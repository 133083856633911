import { PDivider, PIcon, PTabsBar, PText } from '@porsche-design-system/components-react'
import { spacingFluidMedium } from '@porsche-design-system/components-react/styles'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { useRouterState } from '~/app/hooks/useRouterState'

export function PurchaseRequestTableTabBar() {
    const intl = useIntl()
    const { setTabIndex, tabIndex } = useRouterState()

    return (
        <>
            <StyledPTabsBar activeTabIndex={tabIndex}>
                <StyledPTabsButton type={'button'} onClick={() => void setTabIndex(0)}>
                    <PText weight={'semi-bold'}>{intl.formatMessage(messages.allRequests)}</PText>
                </StyledPTabsButton>
                <StyledPTabsButton type={'button'} onClick={() => void setTabIndex(1)}>
                    <StyledPIcon name={'purchase'} aria-hidden />
                    <PText weight={'semi-bold'}>{intl.formatMessage(messages.myOffers)}</PText>
                </StyledPTabsButton>
                <StyledPTabsButton type={'button'} onClick={() => void setTabIndex(2)}>
                    <StyledPIcon name={'view-off'} aria-hidden />
                    <PText weight={'semi-bold'}>{intl.formatMessage(messages.hidden)}</PText>
                </StyledPTabsButton>
            </StyledPTabsBar>
            <StyledPDivider />
        </>
    )
}

const StyledPDivider = styled(PDivider)`
    margin-bottom: ${spacingFluidMedium};
    margin-top: -2px;
`

const StyledPTabsBar = styled(PTabsBar)`
    text-decoration: underline;
`

const StyledPTabsButton = styled.button`
    & * {
        display: inline-block;
    }

    &:hover {
        text-decoration: underline;
    }
`

const StyledPIcon = styled(PIcon)`
    padding: 0 0.2rem;
`

const messages = defineMessages({
    allRequests: { id: 'page.overview.tabBar.allRequests', defaultMessage: 'All requests' },
    myOffers: { id: 'page.overview.tabBar.myOffers', defaultMessage: 'My offers' },
    hidden: { id: 'page.overview.tabBar.hidden', defaultMessage: 'Hidden requests' },
})
