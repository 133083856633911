import { PInlineNotification, PSpinner } from '@porsche-design-system/components-react'
import { spacing } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { FullWidth } from '~/app/base-components/layout/full-width/FullWidth'
import { HeadingLarge } from '~/app/base-components/styled/Heading'
import { SpacingFluidMedium } from '~/app/base-components/styled/Spacer'
import { HiddenPersonalDataVehicleDetails } from '~/app/components/layout/HiddenPersonalData'
import { VehicleDetailGrid, VehicleDetailsItem } from '~/app/components/layout/VehicleDetails'

import { messages } from './messages'

export interface ModelInformationData {
    bodyType?: string
    colour?: string
    countrySpecification?: string
    derivative?: string
    driveTrain?: string
    engineType?: string
    generation?: string
    interior?: string
    modelYear?: number
    power?: string
    transmission?: string
    vin?: string
}

export interface ModelInformationViewProps {
    isLoading: boolean
    data?: ModelInformationData
}

export const ModelInformationView = ({ isLoading, data }: ModelInformationViewProps) => {
    const intl = useIntl()

    if (isLoading) {
        return <PSpinner />
    }

    if (!data) {
        return (
            <PInlineNotification state="error" dismissButton={false}>
                {intl.formatMessage(messages.noDataError)}
            </PInlineNotification>
        )
    }

    return (
        <ModelInformationLayout>
            <HeadingLarge>{intl.formatMessage(messages.heading)}</HeadingLarge>
            <SpacingFluidMedium />
            <VehicleDetailGrid>
                {/* 1st row */}
                {data.vin ? (
                    <VehicleDetailsItem
                        label={intl.formatMessage(messages.vin)}
                        value={data.vin}
                        copyToClipboardButton
                    />
                ) : (
                    <HiddenPersonalDataVehicleDetails
                        label={intl.formatMessage(messages.vin)}
                        reason={'NOT_DEALER_USER'}
                    />
                )}
                {data.driveTrain && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.drive)} value={data.driveTrain} />
                )}
                {data.countrySpecification && (
                    <VehicleDetailsItem
                        label={intl.formatMessage(messages.countrySpecification)}
                        value={data.countrySpecification}
                    />
                )}
                {data.bodyType && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.bodyType)} value={data.bodyType} />
                )}
                {/* 2nd row */}
                {data.derivative && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.derivative)} value={data.derivative} />
                )}
                {data.generation && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.generation)} value={data.generation} />
                )}
                {data.modelYear && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.modelYear)} value={data.modelYear} />
                )}
                {data.engineType && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.engineType)} value={data.engineType} />
                )}
                {/* 3rd row */}
                {data.transmission && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.transmission)} value={data.transmission} />
                )}
                {data.power && <VehicleDetailsItem label={intl.formatMessage(messages.power)} value={data.power} />}
                {data.colour && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.exteriorColour)} value={data.colour} />
                )}
                {data.interior && (
                    <VehicleDetailsItem label={intl.formatMessage(messages.interior)} value={data.interior} />
                )}
            </VehicleDetailGrid>
        </ModelInformationLayout>
    )
}

const ModelInformationLayout = styled(FullWidth)`
    margin: ${spacing.fluid.medium} 0;
`
