import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    heading: {
        id: 'page.details.makeOffer.conditionAndHistory.heading',
        defaultMessage: 'Condition & History',
    },
    mileage: {
        id: 'page.details.makeOffer.conditionAndHistory.mileage',
        defaultMessage: 'Mileage',
    },
    firstRegistration: {
        id: 'page.details.makeOffer.conditionAndHistory.firstRegistration',
        defaultMessage: 'First Registration',
    },
    noOfPreviousOwners: {
        id: 'page.details.makeOffer.conditionAndHistory.noOfPreviousOwners',
        defaultMessage: 'Number of previous owners (including you)',
    },
    fullServiceHistory: {
        id: 'page.details.makeOffer.conditionAndHistory.fullServiceHistory',
        defaultMessage: 'Full Service History available',
    },
    fullServiceHistoryYes: {
        id: 'page.details.makeOffer.conditionAndHistory.fullServiceHistory.yes',
        defaultMessage: 'Yes',
    },
    fullServiceHistoryNo: {
        id: 'page.details.makeOffer.conditionAndHistory.fullServiceHistory.no',
        defaultMessage: 'No',
    },
    noDataError: {
        id: 'page.details.makeOffer.conditionAndHistory.noDataError',
        defaultMessage: 'Failed to load condition and history data. Please try again later.',
    },
    vatDeductible: {
        id: 'page.details.conditionAndHistory.vatDeductible',
        defaultMessage: 'VAT deductible',
    },
    vatDeductibleYes: {
        id: 'page.details.conditionAndHistory.vatDeductible.yes',
        defaultMessage: 'Yes',
    },
    vatDeductibleNo: {
        id: 'page.details.conditionAndHistory.vatDeductible.no',
        defaultMessage: 'No',
    },
})
