import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    heading: {
        id: 'page.details.modelInformation.heading',
        defaultMessage: 'Model Information',
    },
    noDataError: {
        id: 'page.details.modelInformation.noDataError',
        defaultMessage: 'Failed to load "model information" data. Please try again later.',
    },
    vin: {
        id: 'page.details.modelInformation.vin',
        defaultMessage: 'VIN',
    },
    derivative: {
        id: 'page.details.modelInformation.derivative',
        defaultMessage: 'Model derivative',
    },
    transmission: {
        id: 'page.details.modelInformation.transmission',
        defaultMessage: 'Transmission',
    },
    drive: {
        id: 'page.details.modelInformation.drive',
        defaultMessage: 'Drive',
    },
    generation: {
        id: 'page.details.modelInformation.generation',
        defaultMessage: 'Model Generation',
    },
    power: {
        id: 'page.details.modelInformation.power',
        defaultMessage: 'Power',
    },
    countrySpecification: {
        id: 'page.details.modelInformation.countrySpecification',
        defaultMessage: 'Country Specification',
    },
    modelYear: {
        id: 'page.details.modelInformation.modelYear',
        defaultMessage: 'Model Year',
    },
    exteriorColour: {
        id: 'page.details.modelInformation.exteriorColour',
        defaultMessage: 'Exterior Colour',
    },
    bodyType: {
        id: 'page.details.modelInformation.bodyType',
        defaultMessage: 'Body Type',
    },
    engineType: {
        id: 'page.details.modelInformation.engineType',
        defaultMessage: 'Engine Type',
    },
    interior: {
        id: 'page.details.modelInformation.interior',
        defaultMessage: 'Interior',
    },
})
