/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealershipSettingsDealerResponseDto,
  DealershipSettingsMerchantRequestDto,
  DealershipSettingsPurchaseRequestVisibilityRequestDto,
  DealershipSettingsPurchaseRequestVisibilityResponsesDto,
} from '../models/index';
import {
    DealershipSettingsDealerResponseDtoFromJSON,
    DealershipSettingsDealerResponseDtoToJSON,
    DealershipSettingsMerchantRequestDtoFromJSON,
    DealershipSettingsMerchantRequestDtoToJSON,
    DealershipSettingsPurchaseRequestVisibilityRequestDtoFromJSON,
    DealershipSettingsPurchaseRequestVisibilityRequestDtoToJSON,
    DealershipSettingsPurchaseRequestVisibilityResponsesDtoFromJSON,
    DealershipSettingsPurchaseRequestVisibilityResponsesDtoToJSON,
} from '../models/index';

export interface GetDealershipSettingsRequest {
    dealerEntryDn: string;
    marketplace: string;
}

export interface GetDealershipSettingsMerchantRequest {
    dealerEntryDn: string;
    marketplace: string;
}

export interface PatchPurchaseRequestVisibilitySettingsRequest {
    purchaseRequestId: string;
    dealerEntryDn: string;
    marketplace: string;
    dealershipSettingsPurchaseRequestVisibilityRequestDto: DealershipSettingsPurchaseRequestVisibilityRequestDto;
}

export interface StoreDealershipSettingsRequest {
    dealerEntryDn: string;
    marketplace: string;
    dealershipSettingsMerchantRequestDto: DealershipSettingsMerchantRequestDto;
}

/**
 * 
 */
export class DealershipSettingsEndpointsApi extends runtime.BaseAPI {

    /**
     * Get settings by dealership entryDn
     */
    async getDealershipSettingsRaw(requestParameters: GetDealershipSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealershipSettingsDealerResponseDto>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getDealershipSettings().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling getDealershipSettings().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/settings/{dealerEntryDn}`.replace(`{${"dealerEntryDn"}}`, encodeURIComponent(String(requestParameters['dealerEntryDn']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealershipSettingsDealerResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get settings by dealership entryDn
     */
    async getDealershipSettings(requestParameters: GetDealershipSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealershipSettingsDealerResponseDto> {
        const response = await this.getDealershipSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get settings by dealership entryDn
     */
    async getDealershipSettingsMerchantRaw(requestParameters: GetDealershipSettingsMerchantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealershipSettingsDealerResponseDto>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getDealershipSettingsMerchant().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling getDealershipSettingsMerchant().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-settings/{dealerEntryDn}`.replace(`{${"dealerEntryDn"}}`, encodeURIComponent(String(requestParameters['dealerEntryDn']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealershipSettingsDealerResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get settings by dealership entryDn
     */
    async getDealershipSettingsMerchant(requestParameters: GetDealershipSettingsMerchantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealershipSettingsDealerResponseDto> {
        const response = await this.getDealershipSettingsMerchantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Hide/Show purchase request for given dealership entryDn
     */
    async patchPurchaseRequestVisibilitySettingsRaw(requestParameters: PatchPurchaseRequestVisibilitySettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealershipSettingsPurchaseRequestVisibilityResponsesDto>> {
        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling patchPurchaseRequestVisibilitySettings().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling patchPurchaseRequestVisibilitySettings().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling patchPurchaseRequestVisibilitySettings().'
            );
        }

        if (requestParameters['dealershipSettingsPurchaseRequestVisibilityRequestDto'] == null) {
            throw new runtime.RequiredError(
                'dealershipSettingsPurchaseRequestVisibilityRequestDto',
                'Required parameter "dealershipSettingsPurchaseRequestVisibilityRequestDto" was null or undefined when calling patchPurchaseRequestVisibilitySettings().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dealer-app/settings/purchase-request/{purchaseRequestId}/dealer-settings/{dealerEntryDn}/visibility-settings`.replace(`{${"purchaseRequestId"}}`, encodeURIComponent(String(requestParameters['purchaseRequestId']))).replace(`{${"dealerEntryDn"}}`, encodeURIComponent(String(requestParameters['dealerEntryDn']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DealershipSettingsPurchaseRequestVisibilityRequestDtoToJSON(requestParameters['dealershipSettingsPurchaseRequestVisibilityRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealershipSettingsPurchaseRequestVisibilityResponsesDtoFromJSON(jsonValue));
    }

    /**
     * Hide/Show purchase request for given dealership entryDn
     */
    async patchPurchaseRequestVisibilitySettings(requestParameters: PatchPurchaseRequestVisibilitySettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealershipSettingsPurchaseRequestVisibilityResponsesDto> {
        const response = await this.patchPurchaseRequestVisibilitySettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stores settings for specified dealership
     */
    async storeDealershipSettingsRaw(requestParameters: StoreDealershipSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling storeDealershipSettings().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling storeDealershipSettings().'
            );
        }

        if (requestParameters['dealershipSettingsMerchantRequestDto'] == null) {
            throw new runtime.RequiredError(
                'dealershipSettingsMerchantRequestDto',
                'Required parameter "dealershipSettingsMerchantRequestDto" was null or undefined when calling storeDealershipSettings().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dealer-settings/{dealerEntryDn}`.replace(`{${"dealerEntryDn"}}`, encodeURIComponent(String(requestParameters['dealerEntryDn']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DealershipSettingsMerchantRequestDtoToJSON(requestParameters['dealershipSettingsMerchantRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stores settings for specified dealership
     */
    async storeDealershipSettings(requestParameters: StoreDealershipSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storeDealershipSettingsRaw(requestParameters, initOverrides);
    }

}
