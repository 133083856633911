import { PInlineNotification } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { messages } from './messages'

export const GenericInlineError = () => {
    const intl = useIntl()
    return (
        <PInlineNotification
            heading={intl.formatMessage(messages.errorHeader)}
            description={intl.formatMessage(messages.errorDescription)}
            state={'error'}
            dismissButton={false}
        />
    )
}
