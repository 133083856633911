/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealershipSettingsDealerResponseDto
 */
export interface DealershipSettingsDealerResponseDto {
    /**
     * Purchase request email notifications are enabled
     * @type {boolean}
     * @memberof DealershipSettingsDealerResponseDto
     */
    notificationsEnabled: boolean;
}

/**
 * Check if a given object implements the DealershipSettingsDealerResponseDto interface.
 */
export function instanceOfDealershipSettingsDealerResponseDto(value: object): value is DealershipSettingsDealerResponseDto {
    if (!('notificationsEnabled' in value) || value['notificationsEnabled'] === undefined) return false;
    return true;
}

export function DealershipSettingsDealerResponseDtoFromJSON(json: any): DealershipSettingsDealerResponseDto {
    return DealershipSettingsDealerResponseDtoFromJSONTyped(json, false);
}

export function DealershipSettingsDealerResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealershipSettingsDealerResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationsEnabled': json['notificationsEnabled'],
    };
}

export function DealershipSettingsDealerResponseDtoToJSON(value?: DealershipSettingsDealerResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notificationsEnabled': value['notificationsEnabled'],
    };
}

