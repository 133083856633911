/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LikertQuestionEvent
 */
export interface LikertQuestionEvent {
    /**
     * 
     * @type {string}
     * @memberof LikertQuestionEvent
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof LikertQuestionEvent
     */
    action: string;
}

/**
 * Check if a given object implements the LikertQuestionEvent interface.
 */
export function instanceOfLikertQuestionEvent(value: object): value is LikertQuestionEvent {
    if (!('category' in value) || value['category'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    return true;
}

export function LikertQuestionEventFromJSON(json: any): LikertQuestionEvent {
    return LikertQuestionEventFromJSONTyped(json, false);
}

export function LikertQuestionEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): LikertQuestionEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'category': json['category'],
        'action': json['action'],
    };
}

export function LikertQuestionEventToJSON(value?: LikertQuestionEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'category': value['category'],
        'action': value['action'],
    };
}

