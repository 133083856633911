/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DealershipsDto } from './DealershipsDto';
import {
    DealershipsDtoFromJSON,
    DealershipsDtoFromJSONTyped,
    DealershipsDtoToJSON,
} from './DealershipsDto';

/**
 * 
 * @export
 * @interface GetDealershipsResponseDto
 */
export interface GetDealershipsResponseDto {
    /**
     * List of Porsche Centres
     * @type {Array<DealershipsDto>}
     * @memberof GetDealershipsResponseDto
     */
    items: Array<DealershipsDto>;
}

/**
 * Check if a given object implements the GetDealershipsResponseDto interface.
 */
export function instanceOfGetDealershipsResponseDto(value: object): value is GetDealershipsResponseDto {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function GetDealershipsResponseDtoFromJSON(json: any): GetDealershipsResponseDto {
    return GetDealershipsResponseDtoFromJSONTyped(json, false);
}

export function GetDealershipsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDealershipsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(DealershipsDtoFromJSON)),
    };
}

export function GetDealershipsResponseDtoToJSON(value?: GetDealershipsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(DealershipsDtoToJSON)),
    };
}

