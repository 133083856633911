import { PModal, PTextList, PTextListItem } from '@porsche-design-system/components-react'
import { useState } from 'react'

import {
    HeadingTextLarge,
    PriceEstimationWarningIcon,
    PriceWithWarnings,
    SpacingFluidSmall,
} from '~/app/components/market-price/price-estimation/PriceEstimation.styled'

import { FormattedMarketPrice } from './FormattedMarketPrice'

interface LabeledPriceTextWithWarningsProps {
    currency: string
    explainerText: string
    marketPrice: number
    marketPriceWarnings?: string[]
}

export function PriceTagWithWarnings({
    currency,
    explainerText,
    marketPrice,
    marketPriceWarnings,
}: LabeledPriceTextWithWarningsProps) {
    const [priceEstimationWarningsOpen, setPriceEstimationWarningsOpen] = useState<boolean>(false)

    return (
        <PriceWithWarnings>
            <HeadingTextLarge>
                <FormattedMarketPrice marketPrice={marketPrice} currency={currency} />
            </HeadingTextLarge>
            <SpacingFluidSmall />
            {marketPriceWarnings && (
                <PriceEstimationWarningIcon
                    name="exclamation"
                    color="notification-error"
                    onClick={() => setPriceEstimationWarningsOpen(true)}
                />
            )}
            <PModal open={priceEstimationWarningsOpen} onDismiss={() => setPriceEstimationWarningsOpen(false)}>
                {explainerText}
                <PTextList>
                    {marketPriceWarnings?.map((warning, index: number) => (
                        <PTextListItem key={`warning-${index}`}>{warning}</PTextListItem>
                    ))}
                </PTextList>
            </PModal>
        </PriceWithWarnings>
    )
}
