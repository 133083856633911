/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MileageDto
 */
export interface MileageDto {
    /**
     * Mileage of vehicle in given unit
     * @type {number}
     * @memberof MileageDto
     */
    value: number;
    /**
     * Unit of mileage value
     * @type {string}
     * @memberof MileageDto
     */
    unit: MileageDtoUnitEnum;
}


/**
 * @export
 */
export const MileageDtoUnitEnum = {
    KM: 'KM',
    SMI: 'SMI'
} as const;
export type MileageDtoUnitEnum = typeof MileageDtoUnitEnum[keyof typeof MileageDtoUnitEnum];


/**
 * Check if a given object implements the MileageDto interface.
 */
export function instanceOfMileageDto(value: object): value is MileageDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('unit' in value) || value['unit'] === undefined) return false;
    return true;
}

export function MileageDtoFromJSON(json: any): MileageDto {
    return MileageDtoFromJSONTyped(json, false);
}

export function MileageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MileageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'unit': json['unit'],
    };
}

export function MileageDtoToJSON(value?: MileageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'unit': value['unit'],
    };
}

