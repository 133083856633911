import { PTag } from '@porsche-design-system/components-react'
import {
    spacing,
    spacingFluidSmall,
    themeLightNotificationErrorSoft,
    themeLightNotificationInfoSoft,
    themeLightNotificationSuccessSoft,
    themeLightNotificationWarningSoft,
} from '@porsche-design-system/components-react/styles'
import { OfferResponseDto } from 'dealer-prs-api-client'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { FullWidth } from '~/app/base-components/layout/full-width/FullWidth'
import { SkeletonText } from '~/app/base-components/skeleton'
import { HeadingLarge } from '~/app/base-components/styled/Heading'
import { SpacingFluidMedium } from '~/app/base-components/styled/Spacer'
import { HiddenPersonalDataInfo } from '~/app/components/layout/HiddenPersonalData'
import { OverallStatusTag } from '~/app/components/overall-status/OverallStatusTag'
import { OverallStatus } from '~/app/components/overall-status/overallStatusTag.shared'
import { formatDateWithTime } from '~/app/utils/FormatDateWithTime'

import { messages } from './messages'
import OfferStatusStatisticsCard from './OfferStatusStatisticsCard'

export interface PurchaseRequestStatistics {
    totalNumberOfOffers: number
    totalNumberOfReceivingDealerships: number
}

export interface OfferStatusViewProps {
    offer?: OfferResponseDto
    statistics?: PurchaseRequestStatistics
    overallStatus: OverallStatus
    showPersonalDataBox: boolean
    showPersonalData: boolean
}

export const OfferStatusView = ({
    offer,
    overallStatus,
    statistics,
    showPersonalDataBox,
    showPersonalData,
}: OfferStatusViewProps) => {
    const intl = useIntl()

    const statisticsView = statistics && [
        {
            label: intl.formatMessage(messages.offerStatusStatisticsCardLabelTotalNumberOfOffers),
            value: statistics.totalNumberOfOffers.toString(),
        },
        {
            label: intl.formatMessage(messages.offerStatusStatisticsCardLabelTotalNumberOfReceivingDealerships),
            value: statistics.totalNumberOfReceivingDealerships.toString(),
        },
    ]

    const offerData = offer && [
        {
            label: intl.formatMessage(messages.date),
            value: formatDateWithTime(offer.offerDate, intl.locale),
            copyToClipboardButton: true,
        },
        {
            label: intl.formatMessage(messages.dealerFullName),
            value: `${offer.dealerFirstname} ${offer.dealerLastname}`,
            copyToClipboardButton: true,
        },
        {
            label: intl.formatMessage(messages.amount),
            value: intl.formatNumber(offer.price.value, {
                style: 'currency',
                currency: offer.price.currency,
            }),
            copyToClipboardButton: true,
        },
    ]

    let sellerData = undefined

    if (offer && showPersonalDataBox && showPersonalData) {
        sellerData = [
            {
                label: intl.formatMessage(messages.fullName),
                value: offer.customerPersonalDetails!.name,
                copyToClipboardButton: true,
            },
            {
                label: intl.formatMessage(messages.emailAddress),
                value: offer.customerPersonalDetails!.email,
                copyToClipboardButton: true,
            },
            {
                label: intl.formatMessage(messages.phoneNumber),
                value: offer.customerPersonalDetails!.phoneNumber,
                copyToClipboardButton: true,
            },
        ]
    } else if (offer && showPersonalDataBox && !showPersonalData) {
        sellerData = [
            {
                label: intl.formatMessage(messages.fullName),
                value: <HiddenPersonalDataInfo reason={offer.reason} />,
                copyToClipboardButton: true,
            },
            {
                label: intl.formatMessage(messages.emailAddress),
                value: <HiddenPersonalDataInfo reason={offer.reason} />,
                copyToClipboardButton: true,
            },
            {
                label: intl.formatMessage(messages.phoneNumber),
                value: <HiddenPersonalDataInfo reason={offer.reason} />,
                copyToClipboardButton: true,
            },
        ]
    }

    return (
        <OfferStatusLayout>
            <HeadingRow>
                <Heading>{intl.formatMessage(messages.overallStatusHeading)}</Heading>
                <CenterVertically>
                    <OverallStatusTag status={overallStatus} />
                </CenterVertically>
            </HeadingRow>
            <SpacingFluidMedium />
            <FlexDiv>
                {offer && (
                    <>
                        {showPersonalDataBox && sellerData && (
                            <OfferStatusStatisticsCard
                                content={sellerData}
                                backgroundColor={themeLightNotificationSuccessSoft}
                            />
                        )}
                        {offerData && (
                            <OfferStatusStatisticsCard
                                content={offerData}
                                backgroundColor={overallStatus2offerStatusCardColor(overallStatus)}
                            />
                        )}
                    </>
                )}
                {statisticsView && (
                    <>
                        <OfferStatusStatisticsCard content={statisticsView} />
                    </>
                )}
            </FlexDiv>
        </OfferStatusLayout>
    )
}

// These are the OfferStatusTypes supported by the frontend.
// tsc will give as a warning when PurchaseRequestListItemDtoOfferStatusEnum will be extended
type OfferStatusType = 'LOADING' | 'ERROR' | 'NO_OFFER' | 'OPEN' | 'ACCEPTED'

interface OfferStatusTagProps {
    offerStatus: OfferStatusType
}

export const OfferStatusTag = ({ offerStatus }: OfferStatusTagProps) => {
    const intl = useIntl()
    const map: { [key in OfferStatusType]: () => ReactElement } = {
        LOADING: () => <SkeletonText />,
        NO_OFFER: () => <PTag icon={'disable'}>{intl.formatMessage(messages.noOffer)}</PTag>,
        OPEN: () => (
            <PTag color={'notification-info-soft'} icon={'purchase'}>
                {intl.formatMessage(messages.offerMade)}
            </PTag>
        ),
        ACCEPTED: () => (
            <PTag color={'notification-success-soft'} icon={'purchase'}>
                {intl.formatMessage(messages.offerGotChosen)}
            </PTag>
        ),
        ERROR: () => <PTag color={'notification-error-soft'}>{intl.formatMessage(messages.offerStatusError)}</PTag>,
    }
    return <CenterVertically>{map[offerStatus]()}</CenterVertically>
}

const CenterVertically = styled.div`
    display: flex;
    align-items: center;
`

const HeadingRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${spacing.fluid.small};
`

const Heading = styled(HeadingLarge)``

export const OfferStatusLayout = styled(FullWidth)`
    margin: ${spacing.fluid.medium} 0;
`

export const FlexDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacingFluidSmall};
`

const overallStatus2offerStatusCardColor = (status?: OverallStatus) => {
    switch (status) {
        case 'OFFER_EXPIRED':
            return themeLightNotificationErrorSoft
        case 'OFFER_ACCEPTED_BY_OTHER':
        case 'WITHDRAWN_AFTER_OFFER':
            return themeLightNotificationWarningSoft
    }

    return themeLightNotificationInfoSoft
}
