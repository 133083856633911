import {
    DealershipSettingsDealerResponseDto,
    PurchaseRequestListPage,
    PurchaseRequestListPageFiltersEnum,
} from 'dealer-prs-api-client'
import { createContext, useContext } from 'react'

export interface PurchaseRequestSearchFilters {
    areaSearchDistance?: string
    dataView?: PurchaseRequestListPageFiltersEnum
}

export const PurchaseRequestTableContext = createContext<{
    getPurchaseRequestsCall: (
        activePage: number,
        itemsPerPage: number,
        searchFilters: PurchaseRequestSearchFilters,
        storeAreaSearchDistance?: string,
    ) => () => Promise<PurchaseRequestListPage>
    getDealershipSettings: () => Promise<DealershipSettingsDealerResponseDto>
}>({
    getDealershipSettings: () => {
        throw new Error('not implemented')
    },
    getPurchaseRequestsCall: () => () => {
        throw new Error('not implemented')
    },
})

export const usePurchaseRequestTableContext = () => {
    return useContext(PurchaseRequestTableContext)
}
