import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    heading: {
        id: 'page.details.makeOffer.accidentsAndDamages.heading',
        defaultMessage: 'Accidents & Damages',
    },
    noDataError: {
        id: 'page.details.makeOffer.accidentsAndDamages.noDataError',
        defaultMessage: 'Failed to load "accidents and damages" data. Please try again later.',
    },
    accidents: {
        id: 'page.details.makeOffer.accidentsAndDamages.accidents',
        defaultMessage: 'Accidents',
    },
    damages: {
        id: 'page.details.makeOffer.accidentsAndDamages.damages',
        defaultMessage: 'Damages',
    },
    no: {
        id: 'page.details.makeOffer.accidentsAndDamages.no',
        defaultMessage: 'No',
    },
})
