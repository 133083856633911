import { MarketConfig, marketConfig, MarketplaceKey } from 'marketconfig'
import { createContext, PropsWithChildren, useContext } from 'react'

type MarketConfigType = MarketConfig & { marketplace: MarketplaceKey }
const MarketplaceConfigContext = createContext<MarketConfigType>({
    ...marketConfig['de'],
    marketplace: 'de',
})

type MarketConfigProviderProps = {
    marketplace: MarketplaceKey
} & PropsWithChildren

export const MarketConfigProvider = ({ marketplace, children }: MarketConfigProviderProps) => (
    <MarketplaceConfigContext.Provider value={{ ...marketConfig[marketplace], marketplace }}>
        {children}
    </MarketplaceConfigContext.Provider>
)

export const useMarketConfig = () => useContext<MarketConfigType>(MarketplaceConfigContext)
