import { spacingFluidMedium } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import type { AppProps } from '~/app/app'
import { SkeletonText } from '~/app/base-components/skeleton'
import { useMarketConfig } from '~/app/hooks/useMarketConfig'
import { useDealerFrontendContext } from '~/dealerFrontendProvider'

import MarketplaceLinkButton from './MarketplaceLinkButton'
import { marketplaceLinksMessages as msg } from './messages'

export interface SearchProps {
    mileageInKmOrMiles: number
    modelSeries?: string
    modelGeneration?: string
    derivative?: string
    // The finder internally resolves the proper unit to use here, likely based
    // on the marketplace/locale, so we just pass what we get here
    firstRegistrationDate?: Date
}

export interface SearchFiltersData {
    modelSeriesKey?: string
    modelGenerationKey?: string
    derivativeKey?: string
    firstRegistration: Date
    mileage: {
        value: number
    }
}

export interface MarketplaceLinksViewProps {
    searchFilterData?: SearchFiltersData
    isLoading: boolean
}

export enum MOBILE_DE_MAGIC_NUMBER {
    P_356 = '2',
    P_911ER_REIHE = '40',
    P_911_URMODELL = '3',
    P_930 = '24',
    P_964 = '5',
    P_991 = '21',
    P_992 = '28',
    P_993 = '6',
    P_996 = '7',
    P_997 = '17',
    P_912 = '8',
    P_914 = '9',
    P_918 = '23',
    P_924 = '10',
    P_928 = '11',
    P_944 = '12',
    P_959 = '13',
    P_962 = '14',
    P_968 = '15',
    P_BOXSTER = '16',
    P_CARRERA_GT = '20',
    P_CAYENNE = '18',
    P_CAYMAN = '19',
    P_MACAN = '22',
    P_PANAMERA = '4',
    P_TAYCAN = '30',
}

// Some selectable models on mobile.de are model generations
const mobileDe911ModelGenerationMapping: Record<string, MOBILE_DE_MAGIC_NUMBER> = {
    '992': MOBILE_DE_MAGIC_NUMBER.P_992,
    '991-1': MOBILE_DE_MAGIC_NUMBER.P_991,
    '991-2': MOBILE_DE_MAGIC_NUMBER.P_991,
    '997-1': MOBILE_DE_MAGIC_NUMBER.P_997,
    '997-2': MOBILE_DE_MAGIC_NUMBER.P_997,
    '996-1': MOBILE_DE_MAGIC_NUMBER.P_996,
    '996-2': MOBILE_DE_MAGIC_NUMBER.P_996,
    '993-1': MOBILE_DE_MAGIC_NUMBER.P_993,
    '993-2': MOBILE_DE_MAGIC_NUMBER.P_993,
    '964': MOBILE_DE_MAGIC_NUMBER.P_964,
    // f-model and g-model used to be just slang words, but we use them now as model series for early 911s
    'g-model-2': MOBILE_DE_MAGIC_NUMBER.P_911_URMODELL,
    'g-model-1': MOBILE_DE_MAGIC_NUMBER.P_911_URMODELL,
    'f-model': MOBILE_DE_MAGIC_NUMBER.P_911_URMODELL,
}

// Some selectable models on mobile.de are model series
const mobileDeModelSeriesMapping: Record<string, MOBILE_DE_MAGIC_NUMBER> = {
    '356': MOBILE_DE_MAGIC_NUMBER.P_356,
    '911': MOBILE_DE_MAGIC_NUMBER.P_911ER_REIHE,
    '914': MOBILE_DE_MAGIC_NUMBER.P_914,
    '918': MOBILE_DE_MAGIC_NUMBER.P_918,
    '924': MOBILE_DE_MAGIC_NUMBER.P_924,
    '928': MOBILE_DE_MAGIC_NUMBER.P_928,
    '944': MOBILE_DE_MAGIC_NUMBER.P_944,
    '959': MOBILE_DE_MAGIC_NUMBER.P_959,
    '962': MOBILE_DE_MAGIC_NUMBER.P_962,
    '968': MOBILE_DE_MAGIC_NUMBER.P_968,
    'carrera-gt': MOBILE_DE_MAGIC_NUMBER.P_CARRERA_GT,
    cayenne: MOBILE_DE_MAGIC_NUMBER.P_CAYENNE,
    macan: MOBILE_DE_MAGIC_NUMBER.P_MACAN,
    panamera: MOBILE_DE_MAGIC_NUMBER.P_PANAMERA,
    taycan: MOBILE_DE_MAGIC_NUMBER.P_TAYCAN,
}

export const getMobileDeModelMagicNumber = (
    modelSeriesKey?: string,
    modelGenerationKey?: string,
    derivativeKey?: string,
): MOBILE_DE_MAGIC_NUMBER | undefined => {
    if (modelSeriesKey === '911') {
        if (derivativeKey?.includes('930')) {
            return MOBILE_DE_MAGIC_NUMBER.P_930
        }

        if (derivativeKey?.includes('912')) {
            return MOBILE_DE_MAGIC_NUMBER.P_912
        }

        const modelGenerationMagicNumber = modelGenerationKey && mobileDe911ModelGenerationMapping[modelGenerationKey]
        if (modelGenerationMagicNumber) {
            return modelGenerationMagicNumber
        }
    } else if (modelSeriesKey === '718') {
        if (derivativeKey?.includes('boxster')) {
            return MOBILE_DE_MAGIC_NUMBER.P_BOXSTER
        }
        if (derivativeKey?.includes('cayman')) {
            return MOBILE_DE_MAGIC_NUMBER.P_CAYMAN
        }
    }

    return modelSeriesKey ? mobileDeModelSeriesMapping[modelSeriesKey] : undefined
}

export const getMinMaxMileage = (mileageInKmOrMiles: number, mileageOffset = 20000) => {
    const minimumMileage = mileageInKmOrMiles - mileageOffset < 0 ? 0 : mileageInKmOrMiles - mileageOffset
    const maximumMileage = mileageInKmOrMiles + mileageOffset
    return { minimumMileage, maximumMileage }
}

type Stage = AppProps['context']['environment']
export const stageBaseUrl: Record<'local' | 'dev' | 'preview' | 'prod', string> = {
    local: 'finder-k.porsche.com',
    dev: 'finder-k.porsche.com',
    preview: 'finder-preview.porsche.com',
    prod: 'finder.porsche.com',
}

export const finderURL = (stage: Stage, pathname: string): URL => {
    const baseUrl = (stage && stageBaseUrl[stage]) ?? 'finder-k.porsche.com'
    return new URL(`https://${baseUrl}/${pathname}`)
}

export const mobileURL = (locale = 'de-DE') => {
    // Note: If we ever need other languages than German, be aware, that France has an own website mobile.fr while other languages do not
    const language = locale.split('-')[0]
    return new URL(`https://suchen.mobile.de/fahrzeuge/search.html?isSearchRequest=true&lang=${language}`)
}

/**
 * Generates a link to mobile.de with the given search Parameters (see SOC-454)
 * @param modelSeries
 * @param modelGeneration
 * @param derivative
 * @param mileageInKmOrMiles
 * @param firstRegistrationDate
 */
export const buildMobileDeLink = ({
    modelSeries,
    modelGeneration,
    derivative,
    mileageInKmOrMiles,
    firstRegistrationDate,
}: SearchProps): string => {
    const porscheMobileManufacturerId = '20100'

    const { minimumMileage, maximumMileage } = getMinMaxMileage(mileageInKmOrMiles)

    const url = mobileURL()

    const modelMagicNumber = getMobileDeModelMagicNumber(modelSeries, modelGeneration, derivative)
    const firstRegistrationYear = firstRegistrationDate?.getFullYear()

    if (firstRegistrationYear) {
        url.searchParams.set('fr', `${firstRegistrationYear - 2}:${firstRegistrationYear + 2}`)
    }
    if (0 <= minimumMileage && 0 <= maximumMileage) {
        url.searchParams.set('ml', `${minimumMileage}:${maximumMileage}`)
    }
    if (modelMagicNumber) {
        url.searchParams.set('ms', `${porscheMobileManufacturerId};${modelMagicNumber};;`)
    } else {
        url.searchParams.set('ms', `${porscheMobileManufacturerId};;;`)
    }

    return url.toString()
}

/**
 * SOC-453#AC5: following filters should be set:
 * - model (= model series)
 * - model-generation (= model generation)
 * - mileage from (-20.000 km of trade-in)
 * - mileage to (+20.000 km of trade-in)
 * (SOC-454: +firstRegistrationDate)
 * @param environment e.g. preview
 * @param locale e.g. de-DE
 * @param marketplaceKey e.g. de
 * @param modelSeries e.g. 911 or undefined
 * @param modelGeneration e.g. 922 or undefined
 * @param derivative e.g. 911-carrera-4s-convertible or undefined
 * @param mileageInKmOrMiles e.g. 60000 - we don't need to know if its miles or km here
 * @returns e.g. // https://finder.porsche.com/de/de-DE/search?model=911&model-generation=992&minimum-mileage=40000&maximum-mileage=80000
 */
export const buildFinderLink = ({
    environment,
    locale,
    marketplaceKey,
    modelSeries,
    modelGeneration,
    derivative,
    mileageInKmOrMiles,
}: {
    environment: AppProps['context']['environment']
    locale: AppProps['context']['locale']
    marketplaceKey: AppProps['context']['marketplaceKey']
} & SearchProps): string => {
    const infix = `${marketplaceKey}/${locale}/search`
    // On local host (=no env) we fall back to creating prod-finder links
    const url = finderURL(environment ?? 'prod', infix)

    const { minimumMileage, maximumMileage } = getMinMaxMileage(mileageInKmOrMiles)

    // ?minimum-mileage=40000
    url.searchParams.set('minimum-mileage', `${minimumMileage}`)
    // &maximum-mileage=80000
    url.searchParams.set('maximum-mileage', `${maximumMileage}`)
    if (modelSeries) {
        // &model=911
        url.searchParams.set('model', modelSeries)
    }
    // Note: With modelSeries missing and modelGeneration set the results are
    //       filtered correctly, but the rest of the UI doesn't reflect this
    //       state
    if (modelGeneration) {
        // &model-generation=992
        url.searchParams.set('model-generation', modelGeneration)
    }
    if (derivative) {
        // &model-generation=911-carrera-4s-convertible
        url.searchParams.set('category', derivative)
    }
    return url.toString()
}

export const MarketplaceLinksView = ({ searchFilterData, isLoading }: MarketplaceLinksViewProps) => {
    if (isLoading) {
        return <SkeletonText />
    }

    if (!searchFilterData) {
        return null
    }

    return <MarketplaceLinksViewWithData searchFilterData={searchFilterData} />
}

function MarketplaceLinksViewWithData({
    searchFilterData,
}: Required<Pick<MarketplaceLinksViewProps, 'searchFilterData'>>) {
    const intl = useIntl()
    const {
        dealerContext: { locale, environment, marketplaceKey },
    } = useDealerFrontendContext()
    const { showThirdPartyMarketProvider } = useMarketConfig()

    const finderLinkText = intl.formatMessage(msg.finderButton) // SOC-453
    const mobileDeLinkText = intl.formatMessage(msg.mobileDeButton) // SOC-454

    const { modelSeriesKey, modelGenerationKey, derivativeKey, firstRegistration, mileage } = searchFilterData
    const firstRegistrationDateToDate = new Date(firstRegistration)

    const finderLink = buildFinderLink({
        environment,
        locale,
        marketplaceKey,
        modelSeries: modelSeriesKey, // VDS data
        modelGeneration: modelGenerationKey, // VDS data
        derivative: derivativeKey, // VDS data
        mileageInKmOrMiles: mileage.value, // Customer data
    })

    const renderMobileDeLinkButton = () => {
        const mobileDeLink = buildMobileDeLink({
            modelSeries: modelSeriesKey,
            modelGeneration: modelGenerationKey,
            derivative: derivativeKey,
            mileageInKmOrMiles: mileage.value,
            firstRegistrationDate: firstRegistrationDateToDate,
        })
        return <MarketplaceLinkButton title={mobileDeLinkText} link={mobileDeLink} />
    }

    // Note: Mobile.de currently only for German marketplaces. Might change in the future
    return (
        <Flex>
            <div>
                <MarketplaceLinkButton title={finderLinkText} link={finderLink} />
            </div>
            {showThirdPartyMarketProvider && (
                <FlexItemWithMarginTop>{renderMobileDeLinkButton()}</FlexItemWithMarginTop>
            )}
        </Flex>
    )
}

const Flex = styled.div`
    display: flex;
    flex-direction: column;
`

const FlexItemWithMarginTop = styled.div`
    margin-top: ${spacingFluidMedium};
`
