import { PText } from '@porsche-design-system/components-react'
import {
    fontWeightSemiBold,
    textLargeStyle,
    textMediumStyle,
    textSmallStyle,
    textXLargeStyle,
    textXSmallStyle,
    textXXSmallStyle,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

// Text
export const TextXLarge = styled.p({
    ...textXLargeStyle,
})

export const TextLarge = styled.p({
    ...textLargeStyle,
})

export const TextMedium = styled.p({
    ...textMediumStyle,
})

export const TextSmall = styled.p({
    ...textSmallStyle,
})

export const TextSmallSpan = styled.span({
    ...textSmallStyle,
})

export const TextSmallSemiBold = styled(TextSmall)`
    font-weight: ${fontWeightSemiBold};
`

export const TextSmallSpanSemiBold = styled(TextSmallSpan)`
    font-weight: ${fontWeightSemiBold};
`

export const TextXSmall = styled.p({
    ...textXSmallStyle,
})

export const TextXSmallDiv = styled.div({
    ...textXSmallStyle,
})

export const TextXXSmall = styled.p({
    ...textXXSmallStyle,
})

export const InlinePText = styled(PText)`
    display: inline-block;
`
