import { PToast } from '@porsche-design-system/components-react'
import { PropsWithChildren } from 'react'

import { PurchaseRequestTableTabBar } from './PurchaseRequestTableTabBar'

export function PurchaseRequestTableLayout({ children }: PropsWithChildren) {
    return (
        <>
            <PurchaseRequestTableTabBar />
            {children}
            {/* Toast is shown when addText() from useToastManager() is called somewhere */}
            <PToast />
        </>
    )
}
