import { PButtonPure } from '@porsche-design-system/components-react'
import { JSX } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { MatomoCategory } from '~/app/context/matomoContext'
import { useMatomo } from '~/app/hooks/useMatomo'

export function HideButton({
    hidden,
    disabled,
    onHide,
    location,
}: {
    onHide: (currentVisibilityState: boolean) => Promise<void>
    disabled: boolean
    hidden: boolean
    location: 'details-page' | 'overview'
}): JSX.Element {
    const { trackEvent } = useMatomo()
    const intl = useIntl()

    return (
        <PButtonPure
            role={'button'}
            disabled={disabled}
            icon={hidden ? 'view' : 'view-off'}
            onClick={() => {
                trackEvent({
                    category: location === 'overview' ? MatomoCategory.OVERVIEW : MatomoCategory.VEHICLE_DETAILS,
                    action: hidden ? 'show-purchase-request' : 'hide-purchase-request',
                })
                void onHide(hidden)
            }}
            hideLabel={location === 'overview' ? { base: true } : undefined}
            title={intl.formatMessage(hidden ? messages.show : messages.hide)}
        >
            {intl.formatMessage(hidden ? messages.show : messages.hide)}
        </PButtonPure>
    )
}

const messages = defineMessages({
    hide: {
        id: 'components.hideButton.label.hide',
        defaultMessage: 'Hide in list',
    },
    show: {
        id: 'components.hideButton.label.show',
        defaultMessage: 'Show in list',
    },
})
