/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DistanceDto } from './DistanceDto';
import {
    DistanceDtoFromJSON,
    DistanceDtoFromJSONTyped,
    DistanceDtoToJSON,
} from './DistanceDto';
import type { ModelDetailsDto } from './ModelDetailsDto';
import {
    ModelDetailsDtoFromJSON,
    ModelDetailsDtoFromJSONTyped,
    ModelDetailsDtoToJSON,
} from './ModelDetailsDto';

/**
 * 
 * @export
 * @interface HeadlineDataResponseDto
 */
export interface HeadlineDataResponseDto {
    /**
     * Postal code
     * @type {string}
     * @memberof HeadlineDataResponseDto
     */
    postalCode: string;
    /**
     * Distance from the dealership to the customer
     * @type {DistanceDto}
     * @memberof HeadlineDataResponseDto
     */
    distance: DistanceDto;
    /**
     * Details about the model
     * @type {ModelDetailsDto}
     * @memberof HeadlineDataResponseDto
     */
    modelDetails: ModelDetailsDto;
    /**
     * Status of the purchase request
     * @type {string}
     * @memberof HeadlineDataResponseDto
     */
    status: HeadlineDataResponseDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof HeadlineDataResponseDto
     */
    offerMadeByDealer: boolean;
    /**
     * Date when purchase request came in, e.g. 2023-07-10T10:26:17.741Z
     * @type {Date}
     * @memberof HeadlineDataResponseDto
     */
    requestDate: Date;
    /**
     * Whether the Purchase Request has been hidden by the requesting dealership
     * @type {boolean}
     * @memberof HeadlineDataResponseDto
     */
    hidden: boolean;
}


/**
 * @export
 */
export const HeadlineDataResponseDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    CLOSED: 'CLOSED',
    ARCHIVED: 'ARCHIVED'
} as const;
export type HeadlineDataResponseDtoStatusEnum = typeof HeadlineDataResponseDtoStatusEnum[keyof typeof HeadlineDataResponseDtoStatusEnum];


/**
 * Check if a given object implements the HeadlineDataResponseDto interface.
 */
export function instanceOfHeadlineDataResponseDto(value: object): value is HeadlineDataResponseDto {
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    if (!('modelDetails' in value) || value['modelDetails'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('offerMadeByDealer' in value) || value['offerMadeByDealer'] === undefined) return false;
    if (!('requestDate' in value) || value['requestDate'] === undefined) return false;
    if (!('hidden' in value) || value['hidden'] === undefined) return false;
    return true;
}

export function HeadlineDataResponseDtoFromJSON(json: any): HeadlineDataResponseDto {
    return HeadlineDataResponseDtoFromJSONTyped(json, false);
}

export function HeadlineDataResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeadlineDataResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'postalCode': json['postalCode'],
        'distance': DistanceDtoFromJSON(json['distance']),
        'modelDetails': ModelDetailsDtoFromJSON(json['modelDetails']),
        'status': json['status'],
        'offerMadeByDealer': json['offerMadeByDealer'],
        'requestDate': (new Date(json['requestDate'])),
        'hidden': json['hidden'],
    };
}

export function HeadlineDataResponseDtoToJSON(value?: HeadlineDataResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'postalCode': value['postalCode'],
        'distance': DistanceDtoToJSON(value['distance']),
        'modelDetails': ModelDetailsDtoToJSON(value['modelDetails']),
        'status': value['status'],
        'offerMadeByDealer': value['offerMadeByDealer'],
        'requestDate': ((value['requestDate']).toISOString()),
        'hidden': value['hidden'],
    };
}

