/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferPriceDto
 */
export interface OfferPriceDto {
    /**
     * Price in given currency (without cents)
     * @type {number}
     * @memberof OfferPriceDto
     */
    value: number;
    /**
     * Currency of price value
     * @type {string}
     * @memberof OfferPriceDto
     */
    currency: OfferPriceDtoCurrencyEnum;
}


/**
 * @export
 */
export const OfferPriceDtoCurrencyEnum = {
    EUR: 'EUR',
    GBP: 'GBP'
} as const;
export type OfferPriceDtoCurrencyEnum = typeof OfferPriceDtoCurrencyEnum[keyof typeof OfferPriceDtoCurrencyEnum];


/**
 * Check if a given object implements the OfferPriceDto interface.
 */
export function instanceOfOfferPriceDto(value: object): value is OfferPriceDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function OfferPriceDtoFromJSON(json: any): OfferPriceDto {
    return OfferPriceDtoFromJSONTyped(json, false);
}

export function OfferPriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPriceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'currency': json['currency'],
    };
}

export function OfferPriceDtoToJSON(value?: OfferPriceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'currency': value['currency'],
    };
}

