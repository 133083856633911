import { PaginationUpdateEventDetail } from '@porsche-design-system/components-react'
import { PurchaseRequestDto } from 'dealer-prs-api-client'
import { useCallback, useEffect, useRef } from 'react'

import { usePurchaseRequestListQuery } from '~/app/hooks/queries/usePurchaseRequestListQuery'
import { usePurchaseRequestVisibility } from '~/app/hooks/usePurchaseRequestVisibility'
import { useRouterState } from '~/app/hooks/useRouterState'
import { scrollToElement } from '~/app/utils/scrollToElement'

import { GenericInlineError } from './GenericInlineError'
import NoDataText from './NoDataText'
import PurchaseRequestTable from './PurchaseRequestTable'
import PurchaseRequestTableSkeleton from './PurchaseRequestTableSkeleton'

const PURCHASE_REQUEST_TABLE_ID = 'purchase-request-table'

/**
 * - parent has page title
 * - Fetches data via useQuery
 *   - is given a fetch method
 * - shows skeleton while waiting for data
 * - shows error if no data arrives
 */
export const PurchaseRequestTableContainer = () => {
    const { areaSearchDistance, setActivePage, activePage, dataView } = useRouterState()

    const lastActivePage = useRef(activePage)

    const filter = {
        dataView,
        areaSearchDistance: areaSearchDistance?.toString(),
    }

    const { data, isLoading, isError } = usePurchaseRequestListQuery({ activePage, filter })

    const { mutatePurchaseRequestVisibility, mutatePurchaseRequestVisibilityIsPending } = usePurchaseRequestVisibility()

    const onPageChange = useCallback(
        ({ detail }: CustomEvent<PaginationUpdateEventDetail>) => {
            setActivePage(detail.page)
        },
        [setActivePage],
    )

    const onHide = useCallback(
        async (item: PurchaseRequestDto) => {
            await mutatePurchaseRequestVisibility({ purchaseRequestId: item.id, hidden: !item.hidden })
        },
        [mutatePurchaseRequestVisibility],
    )

    useEffect(() => {
        // Scroll up, when loading rendering new table content
        if (isLoading || isError || !data || !data?.items?.length || lastActivePage.current === activePage) {
            return
        }

        lastActivePage.current = activePage
        setTimeout(() => {
            scrollToElement(PURCHASE_REQUEST_TABLE_ID)
        })
    }, [activePage, data, isError, isLoading])

    if (isError) {
        return <GenericInlineError />
    }
    if (isLoading) {
        return <PurchaseRequestTableSkeleton />
    }

    if (!data || !data?.items?.length) {
        return <NoDataText />
    }

    return (
        <PurchaseRequestTable
            id={PURCHASE_REQUEST_TABLE_ID}
            items={data.items}
            activePage={activePage}
            onPageChange={(event) => {
                void onPageChange(event)
            }}
            totalItemsCount={data.totalNrOfItems}
            onHide={onHide}
            hideIsPending={mutatePurchaseRequestVisibilityIsPending}
        />
    )
}

export default PurchaseRequestTableContainer
