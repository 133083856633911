import { useQuery } from '@tanstack/react-query'

import {
    PurchaseRequestSearchFilters,
    usePurchaseRequestTableContext,
} from '~/app/components/table/PurchaseRequestTableContext'
import { ITEMS_PER_PAGE } from '~/app/components/table/StaticConfig'

interface UsePurchaseRequestListQueryProps {
    activePage: number
    filter?: PurchaseRequestSearchFilters
}

export const PURCHASE_REQUEST_LIST_QUERY_KEY = 'PURCHASE_REQUESTS'

export function usePurchaseRequestListQuery({ activePage, filter }: UsePurchaseRequestListQueryProps) {
    const { getPurchaseRequestsCall } = usePurchaseRequestTableContext()

    const { data, isLoading, isError } = useQuery({
        queryKey: [PURCHASE_REQUEST_LIST_QUERY_KEY, `${activePage}`, filter],
        /* tabListFilter is added into search filters here as its state is managed via URL path (see routes in app.tsx)
           The other search filters are taken from PurchaseRequestTableContext */
        queryFn: getPurchaseRequestsCall(activePage, ITEMS_PER_PAGE, filter ?? {}),
    })

    return { data, isLoading, isError }
}
