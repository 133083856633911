import { BasePathMiddleware } from './BasePathMiddleware'
import { Configuration, DealershipSettingsEndpointsApi, PurchaseRequestEndpointsApi } from './lib/generated'
import { TokenMiddleware } from './TokenMiddleware'

export * from './lib/generated/apis'
export * from './lib/generated/models'
export * from './lib/generated/runtime'

// export const tokenMiddleware = new TokenMiddleware()
export const tokenMiddleware = new TokenMiddleware()
export const basePathMiddleware = new BasePathMiddleware()

const config = new Configuration({
    middleware: [basePathMiddleware, tokenMiddleware],
})

export const dealershipSettingsDealerAppEndpointsApi = new DealershipSettingsEndpointsApi(config)
export const purchaseRequestDealerAppEndpointsApi = new PurchaseRequestEndpointsApi(config)
