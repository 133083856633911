import { defineMessages } from 'react-intl'

export const marketplaceLinksMessages = defineMessages({
    finderButton: {
        id: 'page.details.marketPriceResearch.marketplaceLinks.finder.button',
        defaultMessage: 'Show similar vehicles in Porsche Finder',
    },
    mobileDeButton: {
        id: 'page.details.marketPriceResearch.marketplaceLinks.mobileDe.button',
        defaultMessage: 'Show similar vehicles at mobile.de',
    },
})
