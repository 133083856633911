import {
    headingLargeStyle,
    headingMediumStyle,
    headingSmallStyle,
    headingXLargeStyle,
    headingXXLargeStyle,
    themeLightPrimary,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

const typographyBaseStyle = {
    color: themeLightPrimary,
    margin: 0,
} as const

export const HeadingXXLarge = styled.h2({
    ...typographyBaseStyle,
    ...headingXXLargeStyle,
})

export const HeadingXLarge = styled.h3({
    ...typographyBaseStyle,
    ...headingXLargeStyle,
})

export const HeadingLarge = styled.h4({
    ...typographyBaseStyle,
    ...headingLargeStyle,
})

export const HeadingMedium = styled.h5({
    ...typographyBaseStyle,
    ...headingMediumStyle,
})

export const HeadingSmall = styled.h6({
    ...typographyBaseStyle,
    ...headingSmallStyle,
})
