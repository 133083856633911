import { spacing } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { FullWidth } from '~/app/base-components/layout/full-width/FullWidth'
import { HeadingLarge } from '~/app/base-components/styled/Heading'
import { SpacingFluidMedium } from '~/app/base-components/styled/Spacer'
import { useMarketConfig } from '~/app/hooks/useMarketConfig'

import { MarketplaceLinksView, MarketplaceLinksViewProps } from './market-place-links/MarketplaceLinksView'
import { MarketPriceResearchLayoutGrid, PriceTileColumn } from './MarketPriceResearch.styled'
import { messages } from './messages'
import { MSRPPriceView, MSRPPriceViewProps } from './msrp-price/MSRPPriceView'
import { PriceEstimationView, PriceEstimationViewProps } from './price-estimation/PriceEstimationView'

const MarketPriceLayout = styled(FullWidth)`
    margin: ${spacing.fluid.medium} 0;
`

export type MarketPriceResearchViewProps = {
    marketPriceLinksProps: MarketplaceLinksViewProps
    msrpPriceProps: MSRPPriceViewProps
    priceEstimationProps: PriceEstimationViewProps
}

export const MarketPriceResearchView = ({
    marketPriceLinksProps,
    priceEstimationProps,
    msrpPriceProps,
}: MarketPriceResearchViewProps) => {
    const { showMarketPriceEstimation } = useMarketConfig()

    const intl = useIntl()

    return (
        <MarketPriceLayout>
            <HeadingLarge>{intl.formatMessage(messages.heading)}</HeadingLarge>
            <SpacingFluidMedium />
            <MarketPriceResearchLayoutGrid>
                {showMarketPriceEstimation && (
                    <PriceTileColumn>
                        <PriceEstimationView {...priceEstimationProps} />
                        <MSRPPriceView {...msrpPriceProps} />
                    </PriceTileColumn>
                )}
                <MarketplaceLinksView {...marketPriceLinksProps} />
            </MarketPriceResearchLayoutGrid>
        </MarketPriceLayout>
    )
}
