/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MileageInputDto } from './MileageInputDto';
import {
    MileageInputDtoFromJSON,
    MileageInputDtoFromJSONTyped,
    MileageInputDtoToJSON,
} from './MileageInputDto';

/**
 * 
 * @export
 * @interface VehicleInputDto
 */
export interface VehicleInputDto {
    /**
     * VIN of the vehicle to be sold
     * @type {string}
     * @memberof VehicleInputDto
     */
    vin: string;
    /**
     * Mileage of the vehicle to be sold
     * @type {MileageInputDto}
     * @memberof VehicleInputDto
     */
    mileage: MileageInputDto;
    /**
     * Date of first registration
     * @type {Date}
     * @memberof VehicleInputDto
     */
    firstRegistration: Date;
    /**
     * Number of previous owners
     * @type {number}
     * @memberof VehicleInputDto
     */
    previousOwners: number;
    /**
     * Is a full service history available
     * @type {boolean}
     * @memberof VehicleInputDto
     */
    fullServiceHistory: boolean;
    /**
     * Indicates whether the Vehicle is accident free
     * @type {boolean}
     * @memberof VehicleInputDto
     */
    isAccidentFree: boolean;
    /**
     * Description containing which accidents the vehicle might have had
     * @type {string}
     * @memberof VehicleInputDto
     */
    accidentDescription?: string;
    /**
     * Indicates whether the Vehicle is damage free
     * @type {boolean}
     * @memberof VehicleInputDto
     */
    isDamageFree: boolean;
    /**
     * Description containing which damages the vehicle might have
     * @type {string}
     * @memberof VehicleInputDto
     */
    damageDescription?: string;
    /**
     * Indicates whether VAT is deductible on the vehicle (this field is only available in some markets)
     * @type {boolean}
     * @memberof VehicleInputDto
     */
    vatDeductible?: boolean;
}

/**
 * Check if a given object implements the VehicleInputDto interface.
 */
export function instanceOfVehicleInputDto(value: object): value is VehicleInputDto {
    if (!('vin' in value) || value['vin'] === undefined) return false;
    if (!('mileage' in value) || value['mileage'] === undefined) return false;
    if (!('firstRegistration' in value) || value['firstRegistration'] === undefined) return false;
    if (!('previousOwners' in value) || value['previousOwners'] === undefined) return false;
    if (!('fullServiceHistory' in value) || value['fullServiceHistory'] === undefined) return false;
    if (!('isAccidentFree' in value) || value['isAccidentFree'] === undefined) return false;
    if (!('isDamageFree' in value) || value['isDamageFree'] === undefined) return false;
    return true;
}

export function VehicleInputDtoFromJSON(json: any): VehicleInputDto {
    return VehicleInputDtoFromJSONTyped(json, false);
}

export function VehicleInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleInputDto {
    if (json == null) {
        return json;
    }
    return {
        
        'vin': json['vin'],
        'mileage': MileageInputDtoFromJSON(json['mileage']),
        'firstRegistration': (new Date(json['firstRegistration'])),
        'previousOwners': json['previousOwners'],
        'fullServiceHistory': json['fullServiceHistory'],
        'isAccidentFree': json['isAccidentFree'],
        'accidentDescription': json['accidentDescription'] == null ? undefined : json['accidentDescription'],
        'isDamageFree': json['isDamageFree'],
        'damageDescription': json['damageDescription'] == null ? undefined : json['damageDescription'],
        'vatDeductible': json['vatDeductible'] == null ? undefined : json['vatDeductible'],
    };
}

export function VehicleInputDtoToJSON(value?: VehicleInputDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vin': value['vin'],
        'mileage': MileageInputDtoToJSON(value['mileage']),
        'firstRegistration': ((value['firstRegistration']).toISOString().substring(0,10)),
        'previousOwners': value['previousOwners'],
        'fullServiceHistory': value['fullServiceHistory'],
        'isAccidentFree': value['isAccidentFree'],
        'accidentDescription': value['accidentDescription'],
        'isDamageFree': value['isDamageFree'],
        'damageDescription': value['damageDescription'],
        'vatDeductible': value['vatDeductible'],
    };
}

