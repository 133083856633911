import { defineMessages } from '@formatjs/intl'
import {
    PBanner,
    PButton,
    PIcon,
    PSegmentedControl,
    PSegmentedControlItem,
    PTextareaWrapper,
    SegmentedControlUpdateEventDetail,
    Theme,
} from '@porsche-design-system/components-react'
import { PropsWithChildren, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'

import { TextLarge, TextMedium, TextSmall } from '~/app/base-components/styled/Text'
import { MatomoCategory, MatomoFeedbackEventName } from '~/app/context/matomoContext'
import { useMatomo } from '~/app/hooks/useMatomo'
import { scrollToElement } from '~/app/utils/scrollToElement'

import {
    FeedbackSubmitButtonSpacing,
    FeedbackTextarea,
    GridContent,
    GridContentWrapper,
    LayoutWithBackground,
    LikertQuestion,
    SegmentControlSpacing,
    SuccessWrapper,
    VerticalSpacingFluidMedium,
} from './Feedback.styled'
import type { FeedbackPayload } from './FeedbackContainer'

export type LikertAnswer = {
    label: string
    value: MatomoFeedbackEventName
}

export type LocalizedQuestionWithCanonical = {
    localized: string
    canonical: string
}

export type FeedbackPage = 'overview' | 'vehicle-details'
export type FeedbackProps = {
    onSubmit: (params: FeedbackPayload) => Promise<boolean>
    likert: {
        id: string
        question: LocalizedQuestionWithCanonical
        answers: LikertAnswer[]
    }
    feedbackQuestion: LocalizedQuestionWithCanonical
    alwaysShowFeedbackQuestion?: boolean
    page: FeedbackPage
    theme: Theme
}

/**
 * Mapping of Feedback page to Matomo category
 * We use this mapping to separate Matomo naming from the naming displayed in
 * the feedback-services rendered Slack messages
 */
export const matomoCategoryByPage: { [key in FeedbackPage]: MatomoCategory } = {
    overview: MatomoCategory.OVERVIEW,
    'vehicle-details': MatomoCategory.VEHICLE_DETAILS,
}

export const Feedback = ({
    onSubmit,
    likert,
    feedbackQuestion,
    alwaysShowFeedbackQuestion,
    page,
    theme,
}: FeedbackProps) => {
    const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<string>('')
    const [score, setScore] = useState<number>()
    const intl = useIntl()
    const { trackEvent } = useMatomo()

    const handleSubmit = async () => {
        setShowErrorBanner(false)
        if (typeof score !== 'number') return

        const humanReadableScore = Math.round((score * 4) / (likert.answers.length - 1) + 1)
        trackEvent({
            category: matomoCategoryByPage[page],
            action: `syp-submit-feedback-${likert.id}`,
            name: likert.answers[score].value,
        })

        setIsLoading(true)
        const feedbackSubmitted = await onSubmit({
            feedbackQuestion: feedbackQuestion.canonical,
            timestamp: new Date(),
            likertQuestion: {
                event: {
                    category: `dealer-${page}-feedback`,
                    action: humanReadableScore.toString(),
                },
                answerLabel: likert.answers[score].value,
                questionTitle: likert.question.canonical,
            },
            feedback,
        })

        if (!feedbackSubmitted) {
            setIsLoading(false)
            setShowErrorBanner(true)
        }

        setIsSubmitted(feedbackSubmitted)
    }

    const handleUpdate = useCallback(
        (e: CustomEvent<SegmentedControlUpdateEventDetail>) => {
            const score = Number(e.detail.value)
            if (isNaN(score)) throw new Error(`Invalid selected value: ${e.detail.value}`)

            trackEvent({
                category: matomoCategoryByPage[page],
                action: `syp-selected-feedback-${likert.id}`,
                name: likert.answers[score].value,
            })
            setScore(score)

            setTimeout(() => {
                scrollToElement('feedback-likert-segment-control')
            })
        },
        [trackEvent, page, likert.id, likert.answers],
    )

    if (isSubmitted) return <FeedbackSuccessful theme={theme} />

    return (
        <>
            <PBanner
                open={showErrorBanner}
                heading={intl.formatMessage(feedbackMessages.errorBannerHeading)}
                description={intl.formatMessage(feedbackMessages.errorBannerDescription)}
                dismissButton
                onDismiss={() => setShowErrorBanner(false)}
                state="error"
            />
            <FeedbackLayout theme={theme}>
                <LikertQuestion data-testid="likert-question">
                    <TextLarge>{likert.question.localized}</TextLarge>
                </LikertQuestion>
                <SegmentControlSpacing>
                    <PSegmentedControl
                        theme={theme}
                        value={score}
                        aria-label={likert.question.localized}
                        onUpdate={handleUpdate}
                        id={'feedback-likert-segment-control'}
                        data-testid={'feedback-likert-segment-control'}
                        columns={{ base: 1, s: 1, m: likert.answers.length }}
                    >
                        {likert.answers.map(({ label, value }, index) => (
                            <PSegmentedControlItem
                                value={index}
                                key={`${index}-${value}`}
                                data-testid={`segmented-control-${index}`}
                            >
                                {label}
                            </PSegmentedControlItem>
                        ))}
                    </PSegmentedControl>
                </SegmentControlSpacing>
                {(alwaysShowFeedbackQuestion || score !== undefined) && (
                    <>
                        <TextMedium>{feedbackQuestion.localized}</TextMedium>
                        <PTextareaWrapper theme={theme}>
                            <FeedbackTextarea
                                value={feedback ?? ''}
                                data-testid={'feedback-text-area'}
                                onChange={(e) => setFeedback(e.currentTarget.value)}
                            />
                        </PTextareaWrapper>
                        <FeedbackSubmitButtonSpacing>
                            <PButton
                                theme={theme}
                                data-testid={'feedback-submit-button'}
                                onClick={() => {
                                    void handleSubmit()
                                }}
                                disabled={score === undefined}
                                loading={isLoading}
                            >
                                {intl.formatMessage(feedbackMessages.submit)}
                            </PButton>
                        </FeedbackSubmitButtonSpacing>
                    </>
                )}
            </FeedbackLayout>
        </>
    )
}

type FeedbackLayoutProps = {
    theme: Theme
} & PropsWithChildren
const FeedbackLayout = ({ children, theme }: FeedbackLayoutProps) => (
    <LayoutWithBackground $theme={theme}>
        <GridContent>
            <GridContentWrapper>{children}</GridContentWrapper>
        </GridContent>
    </LayoutWithBackground>
)

export const FeedbackSuccessful = ({ theme }: { theme: Theme }) => {
    const intl = useIntl()
    return (
        <FeedbackLayout theme={theme}>
            <SuccessWrapper>
                <VerticalSpacingFluidMedium>
                    <PIcon theme={theme} name={'check'} color={'notification-success'} size={'large'} />
                </VerticalSpacingFluidMedium>
                <TextLarge>{intl.formatMessage(feedbackMessages.submittedHeading)}</TextLarge>
                <TextSmall>{intl.formatMessage(feedbackMessages.submittedSubHeading)}</TextSmall>
            </SuccessWrapper>
        </FeedbackLayout>
    )
}

const feedbackMessages = defineMessages({
    submit: {
        id: 'feedback.button.submit',
        description: 'Button used to submit the feedback',
        defaultMessage: 'Submit feedback',
    },
    submittedHeading: {
        id: 'feedback.label.submitted.heading',
        description: 'Heading displayed after Feedback has been submitted',
        defaultMessage: 'Thanks for your participation',
    },
    submittedSubHeading: {
        id: 'feedback.label.submitted.subheading',
        description: 'Subheading displayed after Feedback has been submitted',
        defaultMessage: 'Your opinion matters to us!',
    },
    errorBannerHeading: {
        id: 'feedback.error.banner.heading',
        description: 'heading of the error banner',
        defaultMessage: 'Error',
    },
    errorBannerDescription: {
        id: 'feedback.error.banner.description',
        description: 'description written in the error banner',
        defaultMessage: 'The feedback could not be submitted. Please try again later.',
    },
})
