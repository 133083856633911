import { PLink } from '@porsche-design-system/components-react'
import { defineMessages, useIntl } from 'react-intl'

import { MatomoCategory } from '~/app/context/matomoContext'
import { useMatomo } from '~/app/hooks/useMatomo'

interface PCSSLinkProps {
    pcssUrl: string
}

const messages = defineMessages({
    pcssLinkButtonText: {
        id: 'page.details.conditionAndHistory.button.pcssLink',
        defaultMessage: 'Show vehicle history in PCSS',
    },
})

export const PCSSLink = ({ pcssUrl }: PCSSLinkProps) => {
    const intl = useIntl()
    const { trackEvent } = useMatomo()

    return (
        <PLink
            href={pcssUrl}
            icon="external"
            variant={'tertiary'}
            target={'_blank'}
            onClick={() => trackEvent({ category: MatomoCategory.VEHICLE_DETAILS, action: 'show-in-pcss' })}
        >
            {intl.formatMessage(messages.pcssLinkButtonText)}
        </PLink>
    )
}
