import { Middleware, RequestContext } from './lib/generated'

type Stage = 'local' | 'dev' | 'preview' | 'prod'
const backendUrls: Record<Stage, URL> = {
    prod: new URL('https://prs.prod.purchase.pdcp.porsche.digital'),
    preview: new URL('https://prs.preview.purchase-dev.pdcp.porsche-preview.digital'),
    dev: new URL('https://prs.dev.purchase-dev.pdcp.porsche-preview.digital'),
    local: new URL('http://localhost:8080'),
}

export class BasePathMiddleware implements Middleware {
    private env: Stage = 'local'

    // Invoked in App.tsx / stories with environment of current context
    public setEnv = (env: Stage) => (this.env = env)
    pre = async ({ url, init }: RequestContext) => {
        const backendUrl = backendUrls[this.env]
        const requestUrl = new URL(url)
        requestUrl.hostname = backendUrl.hostname
        requestUrl.protocol = backendUrl.protocol
        requestUrl.port = backendUrl.port
        return { init, url: requestUrl.toString() }
    }
}
