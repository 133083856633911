import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface HeadlineDataQueryProps {
    id: string
    dealerEntryDn: string
    enabled?: boolean
}

export function getHeadlineDataQueryKey(id: string, dealerEntryDn: string) {
    return ['HEADLINE_DATA', id, dealerEntryDn]
}

export const useHeadlineDataQuery = ({ id, dealerEntryDn, enabled = true }: HeadlineDataQueryProps) =>
    useQuery({
        queryKey: getHeadlineDataQueryKey(id, dealerEntryDn),
        enabled,
        queryFn: () => purchaseRequestDealerAppEndpointsApi.getHeadlineData({ id: id ?? '', dealerEntryDn }),
    })
