import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    heading: {
        id: 'page.details.makeOffer.equipment.heading',
        defaultMessage: 'Equipment',
    },
    noDataError: {
        id: 'page.details.makeOffer.equipment.noDataError',
        defaultMessage: 'Failed to load equipment data. Please try again later.',
    },
})
