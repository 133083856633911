/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealershipSettingsPurchaseRequestVisibilityResponsesDto
 */
export interface DealershipSettingsPurchaseRequestVisibilityResponsesDto {
    /**
     * Whether Purchase request is hidden
     * @type {boolean}
     * @memberof DealershipSettingsPurchaseRequestVisibilityResponsesDto
     */
    hidden: boolean;
}

/**
 * Check if a given object implements the DealershipSettingsPurchaseRequestVisibilityResponsesDto interface.
 */
export function instanceOfDealershipSettingsPurchaseRequestVisibilityResponsesDto(value: object): value is DealershipSettingsPurchaseRequestVisibilityResponsesDto {
    if (!('hidden' in value) || value['hidden'] === undefined) return false;
    return true;
}

export function DealershipSettingsPurchaseRequestVisibilityResponsesDtoFromJSON(json: any): DealershipSettingsPurchaseRequestVisibilityResponsesDto {
    return DealershipSettingsPurchaseRequestVisibilityResponsesDtoFromJSONTyped(json, false);
}

export function DealershipSettingsPurchaseRequestVisibilityResponsesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealershipSettingsPurchaseRequestVisibilityResponsesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'hidden': json['hidden'],
    };
}

export function DealershipSettingsPurchaseRequestVisibilityResponsesDtoToJSON(value?: DealershipSettingsPurchaseRequestVisibilityResponsesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hidden': value['hidden'],
    };
}

