import { PDivider } from '@porsche-design-system/components-react'
import { fontSizeHeadingLarge, getMediaQueryMax, spacing } from '@porsche-design-system/components-react/styles'
import React, { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { FullWidth } from '~/app/base-components/layout/full-width/FullWidth'
import { HeadingLarge } from '~/app/base-components/styled/Heading'
import { TextSmall, TextSmallSemiBold } from '~/app/base-components/styled/Text'

export interface DescriptionListElement {
    label: string
    value: string | ReactNode
}

interface DescriptionListProps {
    heading: string
    content: DescriptionListElement[]
}

export const DescriptionList = ({ heading, content }: PropsWithChildren<DescriptionListProps>) => {
    return (
        <DescriptionListLayout>
            <CardHeadline>{heading}</CardHeadline>
            {content.map(({ label, value }, index) => (
                <>
                    <MTopSBottomMargin>
                        <InnerGridTwoHalves>
                            <Half>
                                <TextSmallNoMargin>{label}</TextSmallNoMargin>
                            </Half>
                            <Half>
                                <TextSmallSemiBoldNoMargin>{value}</TextSmallSemiBoldNoMargin>
                            </Half>
                        </InnerGridTwoHalves>
                    </MTopSBottomMargin>
                    {index < content.length - 1 && <PDivider />}
                </>
            ))}
        </DescriptionListLayout>
    )
}

const CardHeadline = styled(HeadingLarge)`
    font-size: ${fontSizeHeadingLarge};
`

const TextSmallNoMargin = styled(TextSmall)`
    margin: 0;
`
const TextSmallSemiBoldNoMargin = styled(TextSmallSemiBold)`
    margin: 0;
`
const MTopSBottomMargin = styled.div`
    margin-top: ${spacing.fluid.medium};
    margin-bottom: ${spacing.fluid.small};
`

const InnerGridTwoHalves = styled.div`
    display: grid;
    gap: ${spacing.static.medium};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    ${getMediaQueryMax('s')} {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    width: 100%;
`

export const Half = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const DescriptionListLayout = styled(FullWidth)`
    margin: ${spacing.fluid.medium} 0;
`
