import {
    spacingFluidLarge,
    spacingFluidMedium,
    spacingFluidSmall,
    spacingFluidXLarge,
    spacingFluidXSmall,
    spacingFluidXXLarge,
    spacingStaticLarge,
    spacingStaticMedium,
    spacingStaticSmall,
    spacingStaticXLarge,
    spacingStaticXSmall,
    spacingStaticXXLarge,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

// Spacing Fluid
export const SpacingFluidXSmall = styled.div({
    width: spacingFluidXSmall,
    height: spacingFluidXSmall,
})

export const SpacingFluidSmall = styled.div({
    width: spacingFluidSmall,
    height: spacingFluidSmall,
})

export const SpacingFluidMedium = styled.div({
    width: spacingFluidMedium,
    height: spacingFluidMedium,
})

export const SpacingFluidLarge = styled.div({
    width: spacingFluidLarge,
    height: spacingFluidLarge,
})

export const SpacingFluidXLarge = styled.div({
    width: spacingFluidXLarge,
    height: spacingFluidXLarge,
})

export const SpacingFluidXXLarge = styled.div({
    width: spacingFluidXXLarge,
    height: spacingFluidXXLarge,
})

// Spacing Static
export const SpacingStaticXSmall = styled.div({
    width: spacingStaticXSmall,
    height: spacingStaticXSmall,
})

export const SpacingStaticSmall = styled.div({
    width: spacingStaticSmall,
    height: spacingStaticSmall,
})

export const SpacingStaticMedium = styled.div({
    width: spacingStaticMedium,
    height: spacingStaticMedium,
})

export const SpacingStaticLarge = styled.div({
    width: spacingStaticLarge,
    height: spacingStaticLarge,
})

export const SpacingStaticXLarge = styled.div({
    width: spacingStaticXLarge,
    height: spacingStaticXLarge,
})

export const SpacingStaticXXLarge = styled.div({
    width: spacingStaticXXLarge,
    height: spacingStaticXXLarge,
})

export const SpacingTopAndBottom = styled.div`
    margin-top: ${spacingFluidMedium};
    margin-bottom: ${spacingFluidLarge};
`
