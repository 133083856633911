/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatisticsDto
 */
export interface StatisticsDto {
    /**
     * Amount of offers for the purchase request
     * @type {number}
     * @memberof StatisticsDto
     */
    totalNumberOfOffers: number;
    /**
     * Amount of selected dealerships for the purchase request
     * @type {number}
     * @memberof StatisticsDto
     */
    totalNumberOfReceivingDealerships: number;
}

/**
 * Check if a given object implements the StatisticsDto interface.
 */
export function instanceOfStatisticsDto(value: object): value is StatisticsDto {
    if (!('totalNumberOfOffers' in value) || value['totalNumberOfOffers'] === undefined) return false;
    if (!('totalNumberOfReceivingDealerships' in value) || value['totalNumberOfReceivingDealerships'] === undefined) return false;
    return true;
}

export function StatisticsDtoFromJSON(json: any): StatisticsDto {
    return StatisticsDtoFromJSONTyped(json, false);
}

export function StatisticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'totalNumberOfOffers': json['totalNumberOfOffers'],
        'totalNumberOfReceivingDealerships': json['totalNumberOfReceivingDealerships'],
    };
}

export function StatisticsDtoToJSON(value?: StatisticsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalNumberOfOffers': value['totalNumberOfOffers'],
        'totalNumberOfReceivingDealerships': value['totalNumberOfReceivingDealerships'],
    };
}

