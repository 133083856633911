/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VehicleInputDto } from './VehicleInputDto';
import {
    VehicleInputDtoFromJSON,
    VehicleInputDtoFromJSONTyped,
    VehicleInputDtoToJSON,
} from './VehicleInputDto';
import type { CustomerPersonalDetailsInputDto } from './CustomerPersonalDetailsInputDto';
import {
    CustomerPersonalDetailsInputDtoFromJSON,
    CustomerPersonalDetailsInputDtoFromJSONTyped,
    CustomerPersonalDetailsInputDtoToJSON,
} from './CustomerPersonalDetailsInputDto';

/**
 * 
 * @export
 * @interface CreatePurchaseRequestInputDto
 */
export interface CreatePurchaseRequestInputDto {
    /**
     * Personal details of the customer who submitted the request
     * @type {CustomerPersonalDetailsInputDto}
     * @memberof CreatePurchaseRequestInputDto
     */
    customerPersonalDetails: CustomerPersonalDetailsInputDto;
    /**
     * Vehicle related information
     * @type {VehicleInputDto}
     * @memberof CreatePurchaseRequestInputDto
     */
    vehicle: VehicleInputDto;
    /**
     * marketplace (e.g. ch) where the purchase request is submitted
     * @type {string}
     * @memberof CreatePurchaseRequestInputDto
     */
    marketplace: string;
    /**
     * Optional list if purchase requests should only be received (i.e. view) to a subset of dealerships for the given marketplace. ⚠️ Ids must not contain dealerships of different marketplace(s)!
     * @type {Array<string>}
     * @memberof CreatePurchaseRequestInputDto
     */
    dealerEntryDns?: Array<string>;
    /**
     * language-tag (e.g. it-CH) in customer browser (not any browser locale, but one of the language-tags we support for this market, e.g. it can only be de-DE for market de)
     * @type {string}
     * @memberof CreatePurchaseRequestInputDto
     */
    customerLanguageTag: string;
    /**
     * Optional additional notes provided by the customer
     * @type {string}
     * @memberof CreatePurchaseRequestInputDto
     */
    additionalNotes?: string;
    /**
     * Indicates whether the customer is interested in more than just selling their vehicle
     * @type {boolean}
     * @memberof CreatePurchaseRequestInputDto
     */
    tradeInInterest: boolean;
}

/**
 * Check if a given object implements the CreatePurchaseRequestInputDto interface.
 */
export function instanceOfCreatePurchaseRequestInputDto(value: object): value is CreatePurchaseRequestInputDto {
    if (!('customerPersonalDetails' in value) || value['customerPersonalDetails'] === undefined) return false;
    if (!('vehicle' in value) || value['vehicle'] === undefined) return false;
    if (!('marketplace' in value) || value['marketplace'] === undefined) return false;
    if (!('customerLanguageTag' in value) || value['customerLanguageTag'] === undefined) return false;
    if (!('tradeInInterest' in value) || value['tradeInInterest'] === undefined) return false;
    return true;
}

export function CreatePurchaseRequestInputDtoFromJSON(json: any): CreatePurchaseRequestInputDto {
    return CreatePurchaseRequestInputDtoFromJSONTyped(json, false);
}

export function CreatePurchaseRequestInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePurchaseRequestInputDto {
    if (json == null) {
        return json;
    }
    return {
        
        'customerPersonalDetails': CustomerPersonalDetailsInputDtoFromJSON(json['customerPersonalDetails']),
        'vehicle': VehicleInputDtoFromJSON(json['vehicle']),
        'marketplace': json['marketplace'],
        'dealerEntryDns': json['dealerEntryDns'] == null ? undefined : json['dealerEntryDns'],
        'customerLanguageTag': json['customerLanguageTag'],
        'additionalNotes': json['additionalNotes'] == null ? undefined : json['additionalNotes'],
        'tradeInInterest': json['tradeInInterest'],
    };
}

export function CreatePurchaseRequestInputDtoToJSON(value?: CreatePurchaseRequestInputDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerPersonalDetails': CustomerPersonalDetailsInputDtoToJSON(value['customerPersonalDetails']),
        'vehicle': VehicleInputDtoToJSON(value['vehicle']),
        'marketplace': value['marketplace'],
        'dealerEntryDns': value['dealerEntryDns'],
        'customerLanguageTag': value['customerLanguageTag'],
        'additionalNotes': value['additionalNotes'],
        'tradeInInterest': value['tradeInInterest'],
    };
}

