import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    backToOverview: { id: 'page.details.backToOverview', defaultMessage: 'Back to overview' },
    makeOfferButtonLabel: { id: 'page.details.makeOfferButtonLabel', defaultMessage: 'Make offer' },
    noDataError: {
        id: 'page.details.noDataError',
        defaultMessage: 'Failed to load data. Please try again later.',
    },
    location: {
        id: 'page.details.locationTag.location',
        defaultMessage: 'Location: { postalCode }',
    },
    requestedOn: {
        id: 'page.details.requestedOn',
        defaultMessage: 'Requested on { requestDate }',
    },
    makeOfferModalHeadline: {
        id: 'page.details.makeOfferModalHeadline',
        defaultMessage: 'Make Offer for { modelName }',
    },
    howDoesItWorkButtonLabel: { id: 'page.details.howDoesItWorkButtonLabel', defaultMessage: 'How it works' },
    howDoesItWorkHeadline: {
        id: 'page.details.howDoesItWorkHeadline',
        defaultMessage: 'How do I deal with vehicle offers?',
    },
    hidden: {
        id: 'page.details.hiddenTag.label',
        defaultMessage: 'Hidden in list',
    },
})
