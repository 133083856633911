/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EquipmentOptionDto } from './EquipmentOptionDto';
import {
    EquipmentOptionDtoFromJSON,
    EquipmentOptionDtoFromJSONTyped,
    EquipmentOptionDtoToJSON,
} from './EquipmentOptionDto';

/**
 * 
 * @export
 * @interface EquipmentCategoryDto
 */
export interface EquipmentCategoryDto {
    /**
     * Name of equipment category
     * @type {string}
     * @memberof EquipmentCategoryDto
     */
    name?: string;
    /**
     * Equipment options on the vehicle
     * @type {Array<EquipmentOptionDto>}
     * @memberof EquipmentCategoryDto
     */
    options: Array<EquipmentOptionDto>;
}

/**
 * Check if a given object implements the EquipmentCategoryDto interface.
 */
export function instanceOfEquipmentCategoryDto(value: object): value is EquipmentCategoryDto {
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function EquipmentCategoryDtoFromJSON(json: any): EquipmentCategoryDto {
    return EquipmentCategoryDtoFromJSONTyped(json, false);
}

export function EquipmentCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCategoryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'options': ((json['options'] as Array<any>).map(EquipmentOptionDtoFromJSON)),
    };
}

export function EquipmentCategoryDtoToJSON(value?: EquipmentCategoryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'options': ((value['options'] as Array<any>).map(EquipmentOptionDtoToJSON)),
    };
}

