import { getMediaQueryMin, spacing } from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

import { TextSmallSemiBold, TextXSmall } from '~/app/base-components/styled/Text'
import { CopyToClipboardButton } from '~/app/components/copy-to-clipboard-button'

export const VehicleDetailGrid = styled.div`
    display: grid;
    gap: ${spacing.fluid.medium};
    grid-template-columns: 1fr;

    ${getMediaQueryMin('xs')} {
        grid-template-columns: 1fr 1fr;
    }

    ${getMediaQueryMin('m')} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`

export type VehicleDetailsItemProps = {
    label: string
    value: string | number
    copyToClipboardButton?: boolean
}

export const VehicleDetailsItem = ({ label, value, copyToClipboardButton }: VehicleDetailsItemProps) => {
    return (
        <div>
            <LabelText>{label}</LabelText>
            <ValueText>
                {value}&nbsp;{copyToClipboardButton && <CopyToClipboardButton text={`${value}`} />}
            </ValueText>
        </div>
    )
}

const LabelText = styled(TextXSmall)`
    margin: 0;
`

const ValueText = styled(TextSmallSemiBold)`
    margin: 0;
`
