/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealershipsDto
 */
export interface DealershipsDto {
    /**
     * Unique dealership EntryDn in PPN
     * @type {string}
     * @memberof DealershipsDto
     */
    dealerEntryDn: string;
    /**
     * Name of the Porsche Centre
     * @type {string}
     * @memberof DealershipsDto
     */
    name: string;
}

/**
 * Check if a given object implements the DealershipsDto interface.
 */
export function instanceOfDealershipsDto(value: object): value is DealershipsDto {
    if (!('dealerEntryDn' in value) || value['dealerEntryDn'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function DealershipsDtoFromJSON(json: any): DealershipsDto {
    return DealershipsDtoFromJSONTyped(json, false);
}

export function DealershipsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealershipsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dealerEntryDn': json['dealerEntryDn'],
        'name': json['name'],
    };
}

export function DealershipsDtoToJSON(value?: DealershipsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dealerEntryDn': value['dealerEntryDn'],
        'name': value['name'],
    };
}

