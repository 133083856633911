/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerPersonalDetailsInputDto
 */
export interface CustomerPersonalDetailsInputDto {
    /**
     * Name of the customer who submitted the request.
     * @type {string}
     * @memberof CustomerPersonalDetailsInputDto
     */
    name: string;
    /**
     * E-Mail of the customer who submitted the request. Note: Testing should only work with whitelisted mails.
     * @type {string}
     * @memberof CustomerPersonalDetailsInputDto
     */
    email: string;
    /**
     * Postal code of customer
     * @type {string}
     * @memberof CustomerPersonalDetailsInputDto
     */
    postalCode: string;
    /**
     * Phone number of customer
     * @type {string}
     * @memberof CustomerPersonalDetailsInputDto
     */
    phoneNumber: string;
}

/**
 * Check if a given object implements the CustomerPersonalDetailsInputDto interface.
 */
export function instanceOfCustomerPersonalDetailsInputDto(value: object): value is CustomerPersonalDetailsInputDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    return true;
}

export function CustomerPersonalDetailsInputDtoFromJSON(json: any): CustomerPersonalDetailsInputDto {
    return CustomerPersonalDetailsInputDtoFromJSONTyped(json, false);
}

export function CustomerPersonalDetailsInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPersonalDetailsInputDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'postalCode': json['postalCode'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function CustomerPersonalDetailsInputDtoToJSON(value?: CustomerPersonalDetailsInputDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'email': value['email'],
        'postalCode': value['postalCode'],
        'phoneNumber': value['phoneNumber'],
    };
}

