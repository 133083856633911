import {
    PPagination,
    PPopover,
    PTable,
    PTableBody,
    PTableCell,
    PTableHead,
    PTableHeadRow,
    PTableRow,
    PText,
} from '@porsche-design-system/components-react'
import {
    spacing,
    themeLightNotificationWarning,
    themeLightNotificationWarningSoft,
} from '@porsche-design-system/components-react/styles'
import { PurchaseRequestDto } from 'dealer-prs-api-client'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router'
import styled from 'styled-components'

import { InlinePText } from '~/app/base-components/styled/Text'
import { HideButton } from '~/app/components/hide-button/HideButton'
import { OfferCount } from '~/app/components/offer-count/OfferCount'
import { OverallStatusTag } from '~/app/components/overall-status/OverallStatusTag'
import { OverallStatus } from '~/app/components/overall-status/overallStatusTag.shared'
import { formatDateWithTime } from '~/app/utils/FormatDateWithTime'
import { formatDistance } from '~/app/utils/FormatDistance'

import { messages } from './messages'
import { PurchaseRequestTableProps } from './PurchaseRequestTable.shared'
import { createPurchaseRequestTableHeaderCells } from './PurchaseRequestTableHeader'
import { ITEMS_PER_PAGE } from './StaticConfig'

const PurchaseRequestTable = ({
    items,
    activePage,
    onPageChange,
    totalItemsCount,
    onHide,
    hideIsPending = false,
    id = undefined,
}: PurchaseRequestTableProps) => {
    const intl = useIntl()
    const hasPromoted = items.find((item) => item.promoted)?.promoted ?? false

    const cells = createPurchaseRequestTableHeaderCells({ intl }).map((c) => c.cell)

    function getTradeInInterestText(tradeInInterest?: boolean) {
        if (tradeInInterest) return messages.tradeInInterestYes
        if (tradeInInterest === false) return messages.tradeInInterestNo
        return messages.tradeInInterestUnknown
    }

    return (
        <>
            <PTable id={id}>
                <PTableHead>
                    <PTableHeadRow>{cells}</PTableHeadRow>
                </PTableHead>
                <PTableBody key={`page-${0}`}>
                    {hasPromoted ? <TableInlineLabel /> : null}

                    {items.map((item) => {
                        const Row = item.promoted ? HighlightedPTableRow : PTableRow
                        const overallStatus = item.overallStatus as OverallStatus
                        const opacityStyle = isRowDisabled(overallStatus) ? { opacity: '50%' } : {}

                        return (
                            <Row key={item.id} id={item.id}>
                                <Cell maxWidth={maxWidthStyle.requestedOn} itemId={item.id} cellStyle={opacityStyle}>
                                    {formatDateWithTime(item.requestDate, intl.locale)}
                                </Cell>
                                <Cell maxWidth={maxWidthStyle.modelName} itemId={item.id} cellStyle={opacityStyle}>
                                    <div>
                                        <PText weight={'semi-bold'}>
                                            <LinkToDetailPage itemId={item.id}>
                                                {item.modelDetails.derivative}
                                            </LinkToDetailPage>
                                        </PText>
                                        <PText>{item.modelDetails.modelGeneration}</PText>
                                    </div>
                                </Cell>
                                <Cell
                                    maxWidth={maxWidthStyle.overallStatusTag}
                                    itemId={item.id}
                                    ellipsis={false}
                                    cellStyle={opacityStyle}
                                >
                                    <OverallStatusTag status={overallStatus} />
                                </Cell>
                                <Cell
                                    maxWidth={maxWidthStyle.offerCount}
                                    itemId={item.id}
                                    ellipsis={false}
                                    cellStyle={opacityStyle}
                                >
                                    <OfferCount {...item.statistics} />
                                </Cell>
                                <Cell maxWidth={maxWidthStyle.mileage} itemId={item.id} cellStyle={opacityStyle}>
                                    <div>
                                        <PText weight={'semi-bold'}>
                                            {formatDistance(item.mileage.value, item.mileage.unit, intl)}
                                        </PText>
                                        <InlinePText size={'x-small'}>
                                            {/* Intentionally ignoring browser timezone offset to avoid shifting into previous/next day compared to what the seller entered (see ARD 030-prevent-date-timezone-problems.md) */}
                                            {intl.formatDate(item.firstRegistration, { timeZone: 'utc' })}
                                        </InlinePText>
                                        &nbsp;
                                        <InlinePText size={'x-small'}>{`(${item.previousOwners})`}</InlinePText>
                                    </div>
                                </Cell>
                                <Cell maxWidth={maxWidthStyle.location} itemId={item.id} cellStyle={opacityStyle}>
                                    <div>
                                        <PText>{formatDistance(item.distance.value, item.distance.unit, intl)}</PText>
                                        <PText size="xx-small">{`${item.postalCode}`}</PText>
                                    </div>
                                </Cell>
                                <Cell itemId={item.id} cellStyle={opacityStyle}>
                                    {intl.formatMessage(
                                        item.isAccidentFree ? messages.isAccidentFree : messages.isNotAccidentFree,
                                    )}
                                </Cell>
                                <Cell itemId={item.id} cellStyle={opacityStyle}>
                                    {intl.formatMessage(getTradeInInterestText(item.tradeInInterest))}
                                </Cell>
                                <Cell itemId={item.id}>
                                    <HideButton
                                        onHide={() => onHide(item)}
                                        disabled={isHideButtonDisabled(item, hideIsPending)}
                                        hidden={item.hidden}
                                        location={'overview'}
                                    />
                                </Cell>
                            </Row>
                        )
                    })}
                </PTableBody>
            </PTable>
            <PurchaseRequestTablePagination
                activePage={activePage}
                onUpdate={onPageChange}
                totalItemsCount={totalItemsCount}
                itemsPerPage={ITEMS_PER_PAGE}
            />
        </>
    )
}

const LinkToDetailPage = ({ children, itemId }: PropsWithChildren<{ itemId: string }>) => {
    const location = useLocation()

    return (
        <Link to={`/details/${itemId}`} state={{ prevPath: `${location.pathname}${location.search}` }}>
            {children}
        </Link>
    )
}

const disabledStatusSet: Set<OverallStatus> = new Set(['CLOSED', 'PURCHASED', 'PURCHASED_BY_OTHER', 'WITHDRAWN'])

function isRowDisabled(overallStatus: string | undefined) {
    return (overallStatus && (disabledStatusSet as Set<string>).has(overallStatus)) || false
}

const HideButtonDisabledSet: Set<OverallStatus> = new Set([
    'OFFER_SENT',
    'OFFER_ACCEPTED',
    'OFFER_ACCEPTED_BY_OTHER',
    'PURCHASED',
    'PURCHASED_BY_OTHER',
])

function isHideButtonDisabled(item: PurchaseRequestDto, hideIsPending: boolean): boolean {
    if (hideIsPending) {
        return true
    }
    return (HideButtonDisabledSet as Set<string>).has(item.overallStatus ?? '')
}

const Cell = ({
    children,
    maxWidth,
    ellipsis = true,
    cellStyle = undefined,
}: PropsWithChildren<{
    maxWidth?: string
    itemId: string
    ellipsis?: boolean
    cellStyle?: HTMLAttributes<unknown>['style']
}>) => {
    return (
        <PTableCell style={cellStyle}>
            <WidthLimitedCells $maxWidth={maxWidth}>
                {ellipsis ? <PText ellipsis>{children}</PText> : children}
            </WidthLimitedCells>
        </PTableCell>
    )
}

const TableInlineLabel = () => {
    const intl = useIntl()

    return (
        <>
            <PTableRowLabelContent>
                <PTableRowLabelContentCell>
                    <PText weight={'semi-bold'}>
                        {intl.formatMessage(messages.promotedRequestsHeader)}{' '}
                        <PPopover
                            data-testid="popover"
                            direction={'right'}
                            aria={{ 'aria-label': intl.formatMessage(messages.promotedRequestsHeaderInfo) }}
                        >
                            <span role={'popover'}>{intl.formatMessage(messages.promotedRequestsHeaderInfo)}</span>
                        </PPopover>
                    </PText>
                </PTableRowLabelContentCell>
            </PTableRowLabelContent>
            {/* Add an empty row to create space for the absolute table caption */}
            <PTableRow>
                <PTableCell>
                    <PText weight={'semi-bold'}>&nbsp;</PText>
                </PTableCell>
            </PTableRow>
        </>
    )
}

const maxWidthStyle = {
    modelName: '14rem',
    requestedOn: '12rem',
    status: '12rem',
    offerStatus: '12rem',
    firstRegistration: '6em',
    offerCount: '3em',
    overallStatusTag: '9rem',
    mileage: '8em',
    location: '6em',
}

const PurchaseRequestTablePagination = styled(PPagination)`
    margin-top: ${spacing.fluid.medium};
`

const WidthLimitedCells = styled.div<{ $maxWidth?: string }>`
    ${({ $maxWidth }) => `
    max-width: ${$maxWidth};
  `}
`

const PTableRowLabelContent = styled(PTableRow)`
    /* position absolute in order to extend cell background beyond cell boundary to make it look like a merged cell */
    position: absolute;
    width: 100%;
    background-color: ${themeLightNotificationWarning};

    /* Additional span to support text wrapping. Otherwise, within the table, the popover will be one-line only and text will extend outside its bounds. */

    & span[role='popover'] {
        text-wrap: balance;
    }
`

const PTableRowLabelContentCell = styled(PTableCell)`
    // Make cell larger than screen to avoid the hover-colour showing when scrolling to the right.
    width: 1000vw;
    background-color: ${themeLightNotificationWarning};
`

const HighlightedPTableRow = styled(PTableRow)`
    background-color: ${themeLightNotificationWarningSoft};
`

export default PurchaseRequestTable
