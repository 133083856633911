import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface PriceEstimationQueryProps {
    purchaseRequestId: string
    dealerEntryDn: string
}

export function getPriceEstimationQueryKey(id: string, dealerEntryDn: string) {
    return ['PRICE_ESTIMATION', id, dealerEntryDn]
}

export const usePriceEstimationQuery = ({ purchaseRequestId, dealerEntryDn }: PriceEstimationQueryProps) =>
    useQuery({
        queryKey: getPriceEstimationQueryKey(purchaseRequestId, dealerEntryDn),
        queryFn: () =>
            purchaseRequestDealerAppEndpointsApi.getVehicleMarketPrice({
                purchaseRequestId,
                dealerEntryDn,
            }),
    })
