import { PropsWithChildren } from 'react'

import { SkeletonText } from '~/app/base-components/skeleton'
import { Translations } from '~/app/hooks/useTranslations'

/**
 * To avoid flashing from the fallback translations to the actual translations,
 * we show a skeleton instead of the application, while loading the translations.
 */
export const NoTranslationsSkeleton = ({
    children,
    messages,
}: PropsWithChildren<{ messages: Translations | undefined }>) => {
    return messages ? children : <SkeletonText />
}
