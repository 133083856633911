import { AuthorizationHeader } from '@finder/cognito-react'

import { Middleware, RequestContext } from './lib/generated'

export class TokenMiddleware implements Middleware {
    private tokenFunction?: () => Promise<AuthorizationHeader | undefined>

    public setTokenFunction(tokenFunction: () => Promise<AuthorizationHeader | undefined>) {
        this.tokenFunction = tokenFunction
    }

    pre = async ({ url, init }: RequestContext) => {
        if (this.tokenFunction) {
            const existingHeaders = init.headers || {}
            const headersWithAuth = {
                ...existingHeaders,
                ...(await this.tokenFunction()),
            }
            const initWithAuth = { ...init, headers: headersWithAuth }
            return { init: initWithAuth, url }
        }
        return
    }
}
