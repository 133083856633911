import { gridWideColumnEnd, gridWideColumnStart } from '@porsche-design-system/components-react/styles'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { TopLevelGrid } from './TopLevelGrid'

/**
 * Top Level Porsche-Grid that implements the Hero (= wide) breakpoints
 * Warning: only use on top level!
 * @see TopLevelGrid
 */
export const WideTopLevelContent = ({ children }: PropsWithChildren) => (
    <TopLevelGrid>
        <WideContentItem>{children}</WideContentItem>
    </TopLevelGrid>
)

export const WideContentItem = styled.div`
    grid-column: ${gridWideColumnStart} / ${gridWideColumnEnd};
    grid-row: 1;
`
