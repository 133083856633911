import { PText } from '@porsche-design-system/components-react'
import { spacing } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { messages } from './messages'

const NoDataText = () => {
    const intl = useIntl()
    return (
        <PTextWithMargin weight="regular" size="small" align="center">
            {intl.formatMessage(messages.noData)}
        </PTextWithMargin>
    )
}

const PTextWithMargin = styled(PText)`
    margin: ${spacing.fluid.medium};
`

export default NoDataText
