import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    headerModel: { id: 'table.header.model', defaultMessage: 'Model Generation' },
    headerRequestDate: { id: 'table.header.requestDate', defaultMessage: 'Request date' },
    headerStatus: { id: 'table.header.status', defaultMessage: 'Request Status' },
    headerPurchaseRequestStatus: { id: 'table.header.purchaseRequestStatus', defaultMessage: 'Status' },
    headerOfferStatus: { id: 'table.header.offerStatus', defaultMessage: 'Offer status' },
    headerOfferCount: { id: 'table.header.offerCount', defaultMessage: 'Offer count' },
    headerRecipients: { id: 'table.header.recipients', defaultMessage: 'Recipients' },
    headerFirstRegistration: { id: 'table.header.firstRegistration', defaultMessage: 'First registration' },
    headerMileage: { id: 'table.header.mileage', defaultMessage: 'Mileage' },
    headerDistance: { id: 'table.header.distance', defaultMessage: 'Distance' },
    headerLocation: { id: 'table.header.location', defaultMessage: 'ZIP' },
    headerAccidentFree: { id: 'table.header.accidentFree', defaultMessage: 'Accident free' },
    headerTradeInInterest: { id: 'table.header.tradeInInterest', defaultMessage: 'Trade-in interest' },
    headerPreviousOwners: { id: 'table.header.previousOwners', defaultMessage: 'Previous owners' },
    headerAction: { id: 'table.header.action', defaultMessage: 'Action' },
    errorHeader: { id: 'table.error.header', defaultMessage: 'Error' },
    errorDescription: {
        id: 'table.error.description',
        defaultMessage: 'Failed to retrieve data. Please try again later.',
    },
    isAccidentFree: { id: 'table.isAccidentFree.yes', defaultMessage: 'Yes' },
    isNotAccidentFree: { id: 'table.isAccidentFree.no', defaultMessage: 'No' },
    tradeInInterestYes: { id: 'table.tradeInInterest.yes', defaultMessage: 'Yes' },
    tradeInInterestNo: { id: 'table.tradeInInterest.no', defaultMessage: 'No' },
    tradeInInterestUnknown: { id: 'table.tradeInInterest.unknown', defaultMessage: '-' },
    previousOwner: {
        id: 'table.previousOwner',
        defaultMessage: '{previousOwner} {previousOwner, plural, one {owner} other {owners}}',
    },
    noData: { id: 'table.noData', defaultMessage: 'No purchase requests found.' },
    promotedRequestsHeader: {
        id: 'table.header.promoted.label',
        defaultMessage: 'Recent requests without any offers',
    },
    promotedRequestsHeaderInfo: {
        id: 'table.header.promoted.info',
        defaultMessage:
            'Purchase requests that were submitted 48 hours to 7 days ago and have not yet received any offer in the entire dealer network.',
    },
})
