import { PTag } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { messages } from './messages'
import type { OverallStatus } from './overallStatusTag.shared'
import { PTagWithPopover } from './PTagWithPopover'

interface RequestStatusProps {
    status?: OverallStatus
}

export const OverallStatusTag = ({ status }: RequestStatusProps) => {
    const intl = useIntl()

    switch (status) {
        case 'OPEN':
            return <PTag color={'background-surface'}>{intl.formatMessage(messages.open)}</PTag>
        case 'OFFER_SENT':
            return (
                <PTag color={'notification-info-soft'} icon={'arrow-right'}>
                    {intl.formatMessage(messages.offerSent)}
                </PTag>
            )
        case 'OFFER_ACCEPTED':
            return (
                <PTag color={'notification-success-soft'} icon={'arrow-left'}>
                    {intl.formatMessage(messages.offerAccepted)}
                </PTag>
            )
        case 'OFFER_ACCEPTED_BY_OTHER':
            return (
                <PTagWithPopover
                    color={'notification-warning-soft'}
                    icon={'arrow-right'}
                    infoText={intl.formatMessage(messages.offerAcceptedByOtherInfoText)}
                >
                    {intl.formatMessage(messages.offerSent)}
                </PTagWithPopover>
            )
        case 'OFFER_EXPIRED':
            return (
                <PTagWithPopover
                    color={'notification-error-soft'}
                    icon={'disable'}
                    infoText={intl.formatMessage(messages.offerExpiredInfoText)}
                >
                    {intl.formatMessage(messages.offerExpired)}
                </PTagWithPopover>
            )
        case 'CLOSED':
            return <PTag color={'background-base'}>{intl.formatMessage(messages.closed)}</PTag>
        case 'PURCHASED':
            return <PTag color={'background-base'}>{intl.formatMessage(messages.purchased)}</PTag>
        case 'PURCHASED_BY_OTHER':
            return <PTag color={'background-base'}>{intl.formatMessage(messages.purchasedByOther)}</PTag>
        case 'WITHDRAWN':
            return <PTag color={'background-base'}>{intl.formatMessage(messages.withdrawn)}</PTag>
        case 'WITHDRAWN_AFTER_OFFER':
            return (
                <PTagWithPopover
                    color={'notification-warning-soft'}
                    icon={'arrow-right'}
                    infoText={intl.formatMessage(messages.withdrawnAfterOfferInfoText)}
                >
                    {intl.formatMessage(messages.offerSent)}
                </PTagWithPopover>
            )
    }
}
