/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipmentOptionDto
 */
export interface EquipmentOptionDto {
    /**
     * Name of equipment option
     * @type {string}
     * @memberof EquipmentOptionDto
     */
    name?: string;
    /**
     * Option code of equipment option
     * @type {string}
     * @memberof EquipmentOptionDto
     */
    optionCode: string;
}

/**
 * Check if a given object implements the EquipmentOptionDto interface.
 */
export function instanceOfEquipmentOptionDto(value: object): value is EquipmentOptionDto {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    return true;
}

export function EquipmentOptionDtoFromJSON(json: any): EquipmentOptionDto {
    return EquipmentOptionDtoFromJSONTyped(json, false);
}

export function EquipmentOptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentOptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'optionCode': json['optionCode'],
    };
}

export function EquipmentOptionDtoToJSON(value?: EquipmentOptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'optionCode': value['optionCode'],
    };
}

