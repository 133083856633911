import { PPopover } from '@porsche-design-system/components-react'
import { themeLightStateDisabled } from '@porsche-design-system/components-react/styles'
import { OfferResponseDtoReasonEnum } from 'dealer-prs-api-client'
import { IntlShape, useIntl } from 'react-intl'
import styled from 'styled-components'

import { TextSmall, TextXSmall } from '~/app/base-components/styled/Text'

import { messages } from './messages'

type HiddenPersonalDataProps = {
    label: string
    reason: OfferResponseDtoReasonEnum
}

export const HiddenPersonalDataVehicleDetails = ({ label, reason }: HiddenPersonalDataProps) => {
    return (
        <div>
            <LabelText>{label}</LabelText>
            <HiddenPersonalDataInfo reason={reason} />
        </div>
    )
}

export function hiddenPersonalDataInfoInOfferStatusText(intl: IntlShape, reason: OfferResponseDtoReasonEnum) {
    return reason === OfferResponseDtoReasonEnum.OFFER_NOT_ACCEPTED
        ? intl.formatMessage(messages.offerNotAccepted)
        : intl.formatMessage(messages.roleNotAllowed)
}

export const HiddenPersonalDataInfo = ({ reason }: Pick<HiddenPersonalDataProps, 'reason'>) => {
    const intl = useIntl()
    return (
        <ValueText>
            {intl.formatMessage(messages.label)}&nbsp;
            <PPopover direction={'top'}>{hiddenPersonalDataInfoInOfferStatusText(intl, reason)}</PPopover>
        </ValueText>
    )
}

const LabelText = styled(TextXSmall)`
    margin: 0;
`

const ValueText = styled(TextSmall)`
    color: ${themeLightStateDisabled};
    margin: 0;
`
