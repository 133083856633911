import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    label: {
        id: 'page.details.personalDataHidden.label',
        defaultMessage: 'Personal Data is hidden',
    },
    offerNotAccepted: {
        id: 'page.details.personalDataHidden.reason.notAccepted',
        defaultMessage: 'The offer has not been accepted yet',
    },
    roleNotAllowed: {
        id: 'page.details.personalDataHidden.reason.roleNotAllowed',
        defaultMessage: 'Your role is not allowed to view personal data',
    },
})
