/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FeedbackDto } from './FeedbackDto';
import {
    FeedbackDtoFromJSON,
    FeedbackDtoFromJSONTyped,
    FeedbackDtoToJSON,
} from './FeedbackDto';

/**
 * 
 * @export
 * @interface FeedbackInputDto
 */
export interface FeedbackInputDto {
    /**
     * Feedback to be submitted
     * @type {FeedbackDto}
     * @memberof FeedbackInputDto
     */
    feedback: FeedbackDto;
}

/**
 * Check if a given object implements the FeedbackInputDto interface.
 */
export function instanceOfFeedbackInputDto(value: object): value is FeedbackInputDto {
    if (!('feedback' in value) || value['feedback'] === undefined) return false;
    return true;
}

export function FeedbackInputDtoFromJSON(json: any): FeedbackInputDto {
    return FeedbackInputDtoFromJSONTyped(json, false);
}

export function FeedbackInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackInputDto {
    if (json == null) {
        return json;
    }
    return {
        
        'feedback': FeedbackDtoFromJSON(json['feedback']),
    };
}

export function FeedbackInputDtoToJSON(value?: FeedbackInputDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'feedback': FeedbackDtoToJSON(value['feedback']),
    };
}

