import { PButtonPure, PTag } from '@porsche-design-system/components-react'
import { useQuery } from '@tanstack/react-query'
import { DealershipSettingsDealerResponseDto } from 'dealer-prs-api-client'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { SkeletonText } from '~/app/base-components/skeleton'
import { useDealerFrontendContext } from '~/dealerFrontendProvider'

const purchaseRequestSettingsPath = 'settings/account/dashboard/purchase-request-contact'

interface NotificationSettingsButtonProps {
    getDealershipSettings: () => Promise<DealershipSettingsDealerResponseDto>
}

export const NotificationSettingsButton = ({ getDealershipSettings }: NotificationSettingsButtonProps) => {
    const {
        dealerContext: { dealershipId, locale },
    } = useDealerFrontendContext()

    const gotoNotificationSettings = () => {
        window.location.href = `/${dealershipId}/${locale}/${purchaseRequestSettingsPath}`
    }

    const { data, isLoading, isError } = useQuery({
        queryKey: ['DEALERSHIP_SETTINGS'],
        queryFn: getDealershipSettings,
    })

    if (isError) {
        // Technically, this error should never happen and the notification button is not that important
        // FIXME: Ask Fabian
        return null
    }

    return (
        <NotificationSettingsButtonView
            onGotoNotificationSettings={gotoNotificationSettings}
            notificationsEnabled={data?.notificationsEnabled ?? false}
            isLoading={isLoading}
        />
    )
}

interface NotificationSettingsButtonViewProps {
    onGotoNotificationSettings: () => void
    notificationsEnabled: boolean
    isLoading: boolean
}

export const NotificationSettingsButtonView = ({
    onGotoNotificationSettings,
    notificationsEnabled,
    isLoading,
}: NotificationSettingsButtonViewProps) => {
    const intl = useIntl()

    const notificationsEnabledMessage = notificationsEnabled
        ? intl.formatMessage(messages.notificationsOnLabel)
        : intl.formatMessage(messages.notificationsOffLabel)

    const Content = () => (
        <StyledPTag color={notificationsEnabled ? 'notification-success-soft' : 'notification-error-soft'}>
            <StyledPButton
                icon="bell"
                aria={{ 'aria-label': 'Bell icon' }}
                size="small"
                onClick={onGotoNotificationSettings}
            >
                {notificationsEnabledMessage}
            </StyledPButton>
        </StyledPTag>
    )

    return isLoading ? (
        <StyledSkeletonText data-testid="notification-settings-button-skeleton">
            <HiddenContent>
                <Content />
            </HiddenContent>
        </StyledSkeletonText>
    ) : (
        <Content />
    )
}

const StyledPTag = styled(PTag)`
    height: 100%;
`

const StyledSkeletonText = styled(SkeletonText)`
    width: 9rem;
    height: 100%;
`

const StyledPButton = styled(PButtonPure)`
    text-decoration: underline;
`

const messages = defineMessages({
    notificationsOnLabel: {
        id: 'page.overview.notificationsOnLabel',
        defaultMessage: 'Notifications on',
    },
    notificationsOffLabel: {
        id: 'page.overview.notificationsOffLabel',
        defaultMessage: 'Notifications off',
    },
})

const HiddenContent = styled.div({
    visibility: 'hidden',
})
