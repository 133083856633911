import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    offerStatusHeading: {
        id: 'page.details.offerStatus.heading',
        defaultMessage: 'Offer Status',
    },
    overallStatusHeading: {
        id: 'page.details.overallStatus.heading',
        defaultMessage: 'Status',
    },
    noOffer: {
        id: 'page.details.offerStatus.noOffer',
        defaultMessage: 'No offer made',
    },
    offerMade: {
        id: 'page.details.offerStatus.offerMade',
        defaultMessage: 'Offer made',
    },
    offerGotChosen: {
        id: 'page.details.offerStatus.offerGotChosen',
        defaultMessage: 'Offer got chosen',
    },
    offerStatusError: {
        id: 'page.details.offerStatus.error',
        defaultMessage: 'An unexpected error occurred while trying to load the offer.',
    },
    offerStatusStatisticsCardLabelTotalNumberOfOffers: {
        id: 'page.details.offerStatus.statisticsCardLabel.totalNumberOfOffers',
        defaultMessage: 'Total number of offers',
    },
    offerStatusStatisticsCardLabelTotalNumberOfReceivingDealerships: {
        id: 'page.details.offerStatus.statisticsCardLabel.totalNumberOfReceivingDealerships',
        defaultMessage: 'Total number of recipients',
    },
    date: {
        id: 'page.details.offerStatus.label.date',
        defaultMessage: 'Date of offer',
    },
    amount: {
        id: 'page.details.offerStatus.label.amount',
        defaultMessage: 'Offer amount',
    },
    personalDetailsOfSellerHeading: {
        id: 'page.details.offerStatus.personalDetailsOfSeller.heading',
        defaultMessage: 'Personal details of the seller',
    },
    emailAddress: {
        id: 'page.details.offerStatus.personalDetailsOfSeller.emailAddress',
        defaultMessage: 'Email address',
    },
    dealerFullName: {
        id: 'page.details.offerStatus.personalDetailsOfDealer.fullName',
        defaultMessage: 'Offer creator',
    },
    fullName: {
        id: 'page.details.offerStatus.personalDetailsOfSeller.fullName',
        defaultMessage: 'First & last name',
    },
    phoneNumber: {
        id: 'page.details.offerStatus.personalDetailsOfSeller.phoneNumber',
        defaultMessage: 'Phone number',
    },
})
