import { PText } from '@porsche-design-system/components-react'
import type { ComponentProps } from 'react'
import { useIntl } from 'react-intl'

import { InlinePText } from '~/app/base-components/styled/Text'

import { messages } from './messages'

interface OfferCountProps {
    totalNumberOfOffers: number
    totalNumberOfReceivingDealerships: number
}

export const OfferCount = ({ totalNumberOfOffers, totalNumberOfReceivingDealerships }: OfferCountProps) => {
    const intl = useIntl()

    const color: ComponentProps<typeof PText>['color'] = totalNumberOfOffers === 0 ? 'notification-error' : undefined

    return (
        <div>
            <InlinePText weight={'semi-bold'} color={color} ellipsis={false}>
                {totalNumberOfOffers}
            </InlinePText>
            &nbsp;
            <InlinePText size={'x-small'} color={color} ellipsis={false}>
                {`(${totalNumberOfReceivingDealerships === 1 ? intl.formatMessage(messages.you) : totalNumberOfReceivingDealerships})`}
            </InlinePText>
        </div>
    )
}
