import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface MSRPQueryProps {
    purchaseRequestId: string
    dealerEntryDn: string
    languageTag: string
}

export function getMSRPQueryKey(id: string, dealerEntryDn: string, languageTag: string) {
    return ['MSRP', id, dealerEntryDn, languageTag]
}

export const useMSRPQuery = ({ purchaseRequestId, dealerEntryDn, languageTag }: MSRPQueryProps) =>
    useQuery({
        queryKey: getMSRPQueryKey(purchaseRequestId, dealerEntryDn, languageTag),
        queryFn: () =>
            purchaseRequestDealerAppEndpointsApi.getVehicleMSRP({
                purchaseRequestId,
                dealerEntryDn,
                languageTag,
            }),
    })
