import { PSpinner } from '@porsche-design-system/components-react'
import { fontWeightSemiBold } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { TextSmall } from '~/app/base-components/styled/Text'
import { ContentBox, HeadingTextLarge } from '~/app/components/market-price/price-estimation/PriceEstimation.styled'
import { PriceTile, PriceTileGrid } from '~/app/components/market-price/price-estimation/PriceTile'

import { messages } from './messages'

export interface MSRPPriceViewProps {
    error: Error | null
    formattedTotalPrice: string | undefined
    formattedOptionSum: string | undefined
    isLoading: boolean
}

export function MSRPPriceView({ error, formattedTotalPrice, formattedOptionSum, isLoading }: MSRPPriceViewProps) {
    const intl = useIntl()

    if (error) {
        return null
    }

    if (isLoading) {
        return <MSRPLoading />
    }

    const elements = [
        {
            label: intl.formatMessage(messages.labelVehicleMSRPPrice),
            element: <HeadingTextLarge>{formattedTotalPrice}</HeadingTextLarge>,
        },
        {
            label: intl.formatMessage(messages.labelVehicleMSRPOptionSum),
            element: <TextWithoutWrap>{formattedOptionSum}</TextWithoutWrap>,
        },
    ]

    return <PriceTile heading={intl.formatMessage(messages.tileHeading)} elements={elements} />
}

function MSRPLoading() {
    const intl = useIntl()

    return (
        <PriceTileGrid>
            <ContentBox>
                <TextSmall>{intl.formatMessage(messages.labelMSRPIsLoading)}</TextSmall>
                <PSpinner size="medium" aria={{ 'aria-label': 'Loading page content' }} />
            </ContentBox>
        </PriceTileGrid>
    )
}

export const TextWithoutWrap = styled(TextSmall)`
    margin-top: auto;
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: ${fontWeightSemiBold};
`
