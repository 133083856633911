import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    makeOfferShortExplainer: {
        id: 'page.details.makeOffer.makeOfferShortExplainer',
        defaultMessage:
            'The current owner of the vehicle will receive your offer by eMail. If the owner accepts the offer, you will be notified.',
    },
    priceInputLabel: {
        id: 'page.details.makeOffer.priceInputLabel',
        defaultMessage: 'Your Offer',
    },
    priceInputError: {
        id: 'page.details.makeOffer.priceInputError',
        defaultMessage: 'Must be a non-negative number without decimal places.',
    },
    sendOfferButtonLabel: {
        id: 'page.details.makeOffer.sendOfferButtonLabel',
        defaultMessage: 'Send Offer',
    },
    cancelButtonLabel: {
        id: 'page.details.makeOffer.cancelButtonLabel',
        defaultMessage: 'Cancel',
    },
})
