/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomerPersonalDetailsDto } from './CustomerPersonalDetailsDto';
import {
    CustomerPersonalDetailsDtoFromJSON,
    CustomerPersonalDetailsDtoFromJSONTyped,
    CustomerPersonalDetailsDtoToJSON,
} from './CustomerPersonalDetailsDto';
import type { OfferPriceDto } from './OfferPriceDto';
import {
    OfferPriceDtoFromJSON,
    OfferPriceDtoFromJSONTyped,
    OfferPriceDtoToJSON,
} from './OfferPriceDto';

/**
 * 
 * @export
 * @interface OfferResponseDto
 */
export interface OfferResponseDto {
    /**
     * ID of offer
     * @type {string}
     * @memberof OfferResponseDto
     */
    offerId: string;
    /**
     * Price of offer
     * @type {OfferPriceDto}
     * @memberof OfferResponseDto
     */
    price: OfferPriceDto;
    /**
     * Firstname of the person who created the offer
     * @type {string}
     * @memberof OfferResponseDto
     */
    dealerFirstname: string;
    /**
     * Lastname of the person who created the offer
     * @type {string}
     * @memberof OfferResponseDto
     */
    dealerLastname: string;
    /**
     * Date when offer was created, e.g. 2023-07-10T10:26:17.741Z
     * @type {Date}
     * @memberof OfferResponseDto
     */
    offerDate: Date;
    /**
     * Status of offer
     * @type {string}
     * @memberof OfferResponseDto
     */
    status: OfferResponseDtoStatusEnum;
    /**
     * Personal details of customer. Will be undefined in the following cases:
     *              a) the Offer has not been accepted yet
     *              b) the Dealer User does not have appropriate rights to view them (e.g. market manager)
     *              c) the Purchase Request has been archived and anonymized
     * @type {CustomerPersonalDetailsDto}
     * @memberof OfferResponseDto
     */
    customerPersonalDetails?: CustomerPersonalDetailsDto;
    /**
     * Reason why personal details are shown or hidden
     * @type {string}
     * @memberof OfferResponseDto
     */
    reason: OfferResponseDtoReasonEnum;
}


/**
 * @export
 */
export const OfferResponseDtoStatusEnum = {
    OPEN: 'OPEN',
    ACCEPTED: 'ACCEPTED',
    EXPIRED: 'EXPIRED'
} as const;
export type OfferResponseDtoStatusEnum = typeof OfferResponseDtoStatusEnum[keyof typeof OfferResponseDtoStatusEnum];

/**
 * @export
 */
export const OfferResponseDtoReasonEnum = {
    NOT_HIDDEN: 'NOT_HIDDEN',
    OFFER_NOT_ACCEPTED: 'OFFER_NOT_ACCEPTED',
    NOT_DEALER_USER: 'NOT_DEALER_USER'
} as const;
export type OfferResponseDtoReasonEnum = typeof OfferResponseDtoReasonEnum[keyof typeof OfferResponseDtoReasonEnum];


/**
 * Check if a given object implements the OfferResponseDto interface.
 */
export function instanceOfOfferResponseDto(value: object): value is OfferResponseDto {
    if (!('offerId' in value) || value['offerId'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('dealerFirstname' in value) || value['dealerFirstname'] === undefined) return false;
    if (!('dealerLastname' in value) || value['dealerLastname'] === undefined) return false;
    if (!('offerDate' in value) || value['offerDate'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('reason' in value) || value['reason'] === undefined) return false;
    return true;
}

export function OfferResponseDtoFromJSON(json: any): OfferResponseDto {
    return OfferResponseDtoFromJSONTyped(json, false);
}

export function OfferResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'offerId': json['offerId'],
        'price': OfferPriceDtoFromJSON(json['price']),
        'dealerFirstname': json['dealerFirstname'],
        'dealerLastname': json['dealerLastname'],
        'offerDate': (new Date(json['offerDate'])),
        'status': json['status'],
        'customerPersonalDetails': json['customerPersonalDetails'] == null ? undefined : CustomerPersonalDetailsDtoFromJSON(json['customerPersonalDetails']),
        'reason': json['reason'],
    };
}

export function OfferResponseDtoToJSON(value?: OfferResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'offerId': value['offerId'],
        'price': OfferPriceDtoToJSON(value['price']),
        'dealerFirstname': value['dealerFirstname'],
        'dealerLastname': value['dealerLastname'],
        'offerDate': ((value['offerDate']).toISOString()),
        'status': value['status'],
        'customerPersonalDetails': CustomerPersonalDetailsDtoToJSON(value['customerPersonalDetails']),
        'reason': value['reason'],
    };
}

