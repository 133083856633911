import { PButtonPure, PIcon, PPopover, PText, PTextareaWrapper } from '@porsche-design-system/components-react'
import {
    fontWeightSemiBold,
    headingLargeStyle,
    spacingFluidMedium,
    spacingFluidSmall,
    spacingStaticXSmall,
    textSmallStyle,
    textXSmallStyle,
} from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

export const TextSmall = styled.p`
    ${textSmallStyle};
    margin: 0;
`

export const TextXSmall = styled.p`
    ${textXSmallStyle};
    margin: 0;
`

export const HeadingLarge = styled.h2`
    ${headingLargeStyle};
    margin: 0;
`

export const HeadingTextLarge = styled.div`
    ${headingLargeStyle};
    margin: 0;
`

export const FeedbackTextarea = styled(PTextareaWrapper)`
    padding: ${spacingFluidSmall} ${spacingFluidMedium} ${spacingFluidSmall} 0;
    height: 100%;
`

export const Textarea = styled.textarea`
    min-height: 72px;
`

export const SendFeedbackButton = styled(PButtonPure)`
    margin-bottom: ${spacingFluidSmall};
    margin-left: ${spacingStaticXSmall};
`

export const FeedbackChoiceLink = styled(PText)<{ textColor: string }>`
    ${textSmallStyle};
    display: inline;
    text-decoration: underline;
    padding-left: ${spacingFluidMedium};
    padding-right: 0;
    cursor: pointer;
    color: ${(props) => props.textColor};
`

export const PriceWithWarnings = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const PriceEstimationWarningIcon = styled(PIcon)`
    cursor: pointer;
`

export const DataProviderExplanation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
`

export const TextWithoutWrap = styled(TextSmall)`
    margin-top: auto;
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: ${fontWeightSemiBold};
`

export const PopoverWithWrapText = styled(PPopover)`
    white-space: normal;
`

export const FeedbackBox = styled.div`
    padding: ${spacingFluidSmall} ${spacingFluidMedium};
    display: flex;
`

export const NegativeFeedbackForm = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${spacingFluidMedium};
`

export const SpacingFluidSmall = styled.div`
    display: inline;
    margin-left: ${spacingFluidSmall};
`

export const FeedbackBoxText = styled.div`
    flex-grow: 1;
`

export const FeedbackBoxAnswer = styled.div`
    display: flex;
    flex-direction: row;
`

export const ContentBox = styled.div`
    padding: ${spacingFluidMedium};
`
