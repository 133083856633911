/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DistanceDto
 */
export interface DistanceDto {
    /**
     * Distance of dealership to customer of purchase request in given unit
     * @type {number}
     * @memberof DistanceDto
     */
    value: number;
    /**
     * Unit of distance value
     * @type {string}
     * @memberof DistanceDto
     */
    unit: DistanceDtoUnitEnum;
}


/**
 * @export
 */
export const DistanceDtoUnitEnum = {
    KM: 'KM',
    SMI: 'SMI'
} as const;
export type DistanceDtoUnitEnum = typeof DistanceDtoUnitEnum[keyof typeof DistanceDtoUnitEnum];


/**
 * Check if a given object implements the DistanceDto interface.
 */
export function instanceOfDistanceDto(value: object): value is DistanceDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('unit' in value) || value['unit'] === undefined) return false;
    return true;
}

export function DistanceDtoFromJSON(json: any): DistanceDto {
    return DistanceDtoFromJSONTyped(json, false);
}

export function DistanceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistanceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'unit': json['unit'],
    };
}

export function DistanceDtoToJSON(value?: DistanceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'unit': value['unit'],
    };
}

