import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    tileHeading: {
        id: 'page.details.marketPriceResearch.tileHeadingMSRP',
        defaultMessage: 'MSRP',
    },
    labelVehicleMSRPPrice: {
        id: 'page.details.marketPriceResearch.labelVehicleMSRPPrice',
        defaultMessage: 'Original MSRP',
    },
    labelVehicleMSRPOptionSum: {
        id: 'page.details.marketPriceResearch.labelVehicleMSRPOptionSum',
        defaultMessage: 'Included options',
    },
    labelMSRPIsLoading: {
        id: 'page.details.marketPriceResearch.labelMSRPIsLoading',
        defaultMessage: 'Loading',
    },
})
