/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MileageDto } from './MileageDto';
import {
    MileageDtoFromJSON,
    MileageDtoFromJSONTyped,
    MileageDtoToJSON,
} from './MileageDto';

/**
 * 
 * @export
 * @interface VehicleSearchParametersDto
 */
export interface VehicleSearchParametersDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchParametersDto
     */
    derivativeKey?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchParametersDto
     */
    modelGenerationKey?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSearchParametersDto
     */
    modelSeriesKey?: string;
    /**
     * Mileage of the vehicle to be sold
     * @type {MileageDto}
     * @memberof VehicleSearchParametersDto
     */
    mileage: MileageDto;
    /**
     * Date of first registration
     * @type {Date}
     * @memberof VehicleSearchParametersDto
     */
    firstRegistration: Date;
}

/**
 * Check if a given object implements the VehicleSearchParametersDto interface.
 */
export function instanceOfVehicleSearchParametersDto(value: object): value is VehicleSearchParametersDto {
    if (!('mileage' in value) || value['mileage'] === undefined) return false;
    if (!('firstRegistration' in value) || value['firstRegistration'] === undefined) return false;
    return true;
}

export function VehicleSearchParametersDtoFromJSON(json: any): VehicleSearchParametersDto {
    return VehicleSearchParametersDtoFromJSONTyped(json, false);
}

export function VehicleSearchParametersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleSearchParametersDto {
    if (json == null) {
        return json;
    }
    return {
        
        'derivativeKey': json['derivativeKey'] == null ? undefined : json['derivativeKey'],
        'modelGenerationKey': json['modelGenerationKey'] == null ? undefined : json['modelGenerationKey'],
        'modelSeriesKey': json['modelSeriesKey'] == null ? undefined : json['modelSeriesKey'],
        'mileage': MileageDtoFromJSON(json['mileage']),
        'firstRegistration': (new Date(json['firstRegistration'])),
    };
}

export function VehicleSearchParametersDtoToJSON(value?: VehicleSearchParametersDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'derivativeKey': value['derivativeKey'],
        'modelGenerationKey': value['modelGenerationKey'],
        'modelSeriesKey': value['modelSeriesKey'],
        'mileage': MileageDtoToJSON(value['mileage']),
        'firstRegistration': ((value['firstRegistration']).toISOString().substring(0,10)),
    };
}

