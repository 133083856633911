import { gridStyle } from '@porsche-design-system/components-react/styles'
import styled, { CSSObject } from 'styled-components'

// this workaround is required because the typing of pds grid is not compatible (yet) with styled-components v6
const { '@media(min-width:1920px)': large, ...rest } = gridStyle
const largeExplicitlyTyped: CSSObject = large
const gridStyleRetyped = { ...rest, '@media(min-width:1920px)': largeExplicitlyTyped }

/**
 * Warning: only use this Grid at the very top level of the component tree,
 * if you have 100% of the Browser width.
 * It's not made to be used nested! (see https://designsystem.porsche.com/v3/styles/grid)
 */
export const TopLevelGrid = styled.div({
    ...gridStyleRetyped,
})
