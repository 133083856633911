/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EquipmentCategoryDto } from './EquipmentCategoryDto';
import {
    EquipmentCategoryDtoFromJSON,
    EquipmentCategoryDtoFromJSONTyped,
    EquipmentCategoryDtoToJSON,
} from './EquipmentCategoryDto';

/**
 * 
 * @export
 * @interface EquipmentResponseDto
 */
export interface EquipmentResponseDto {
    /**
     * Equipment of the vehicle to be sold
     * @type {Array<EquipmentCategoryDto>}
     * @memberof EquipmentResponseDto
     */
    categories: Array<EquipmentCategoryDto>;
}

/**
 * Check if a given object implements the EquipmentResponseDto interface.
 */
export function instanceOfEquipmentResponseDto(value: object): value is EquipmentResponseDto {
    if (!('categories' in value) || value['categories'] === undefined) return false;
    return true;
}

export function EquipmentResponseDtoFromJSON(json: any): EquipmentResponseDto {
    return EquipmentResponseDtoFromJSONTyped(json, false);
}

export function EquipmentResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(EquipmentCategoryDtoFromJSON)),
    };
}

export function EquipmentResponseDtoToJSON(value?: EquipmentResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categories': ((value['categories'] as Array<any>).map(EquipmentCategoryDtoToJSON)),
    };
}

