import {
    PTable,
    PTableBody,
    PTableCell,
    PTableHead,
    PTableHeadRow,
    PTableRow,
} from '@porsche-design-system/components-react'
import { spacing, spacingStaticSmall } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { createPurchaseRequestTableHeaderCells, PurchaseRequestTableHeaderCellId } from './PurchaseRequestTableHeader'
import { SkeletonDiv, SkeletonText } from './SkeletonStyle'
import { ITEMS_PER_PAGE } from './StaticConfig'

// Define width of Skeleton in cell per type (received from createPurchaseRequestTableHeaderCells)
const cellWidths: Record<PurchaseRequestTableHeaderCellId, string> = {
    purchaseRequestStatus: '55px',
    requestDate: '73px',
    offerCount: '177px',
    model: '96px',
    mileage: '96px',
    distance: '96px',
    accidentFree: '27px',
    tradeInInterest: '27px',
    action: '27px',
}

const PurchaseRequestTableSkeleton = () => {
    const intl = useIntl()

    const cells = createPurchaseRequestTableHeaderCells({ intl })

    // place #ITEMS_PER_PAGE many items into the list of rows that will be rendered as skeleton
    const skeletonTableRows = Array.from({ length: ITEMS_PER_PAGE }, (_, i) => i).map((_, index) => {
        return (
            <PTableRow key={index} id={`${index}`}>
                {cells.map((cell) => (
                    <PTableCell key={cell.id}>
                        {cell.id === 'purchaseRequestStatus' ? (
                            <SkeletonPTag style={{ width: cellWidths[cell.id] }} />
                        ) : (
                            <SkeletonText style={{ width: cellWidths[cell.id] }} />
                        )}
                    </PTableCell>
                ))}
            </PTableRow>
        )
    })

    return (
        <>
            <PTable>
                <PTableHead>
                    <PTableHeadRow>{cells.map((c) => c.cell)}</PTableHeadRow>
                </PTableHead>
                <PTableBody key={`page-${0}`}>{skeletonTableRows}</PTableBody>
            </PTable>
            <SkeletonPagination />
        </>
    )
}
/**
 * Approximating the PTag height here since this will determine the height of
 * the resulting, loaded and rendered table row to avoid layout shifting as much as
 * possible.
 */
const SkeletonPTag = styled(SkeletonText)({ padding: `calc(${spacingStaticSmall}/2.715) 0` })

/**
 * Just approximating the pagination dimensions here to avoid wasting time on perfection
 */
const SkeletonPagination = styled(SkeletonDiv)`
    margin: ${spacing.fluid.medium} auto 0;
    height: 40px;
    width: 200px;
`

export default PurchaseRequestTableSkeleton
