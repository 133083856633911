/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferPriceDto } from './OfferPriceDto';
import {
    OfferPriceDtoFromJSON,
    OfferPriceDtoFromJSONTyped,
    OfferPriceDtoToJSON,
} from './OfferPriceDto';

/**
 * 
 * @export
 * @interface MakeOfferRequestBodyDto
 */
export interface MakeOfferRequestBodyDto {
    /**
     * Price of the offer
     * @type {OfferPriceDto}
     * @memberof MakeOfferRequestBodyDto
     */
    price: OfferPriceDto;
    /**
     * Unique dealership EntryDn in PPN
     * @type {string}
     * @memberof MakeOfferRequestBodyDto
     */
    dealerEntryDn: string;
    /**
     * language-tag (e.g. it-CH) in dealer browser (not any browser locale, but one of the language-tags we support for this market, e.g. it can only be de-DE for market de)
     * @type {string}
     * @memberof MakeOfferRequestBodyDto
     */
    dealerLanguageTag: string;
}

/**
 * Check if a given object implements the MakeOfferRequestBodyDto interface.
 */
export function instanceOfMakeOfferRequestBodyDto(value: object): value is MakeOfferRequestBodyDto {
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('dealerEntryDn' in value) || value['dealerEntryDn'] === undefined) return false;
    if (!('dealerLanguageTag' in value) || value['dealerLanguageTag'] === undefined) return false;
    return true;
}

export function MakeOfferRequestBodyDtoFromJSON(json: any): MakeOfferRequestBodyDto {
    return MakeOfferRequestBodyDtoFromJSONTyped(json, false);
}

export function MakeOfferRequestBodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MakeOfferRequestBodyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'price': OfferPriceDtoFromJSON(json['price']),
        'dealerEntryDn': json['dealerEntryDn'],
        'dealerLanguageTag': json['dealerLanguageTag'],
    };
}

export function MakeOfferRequestBodyDtoToJSON(value?: MakeOfferRequestBodyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'price': OfferPriceDtoToJSON(value['price']),
        'dealerEntryDn': value['dealerEntryDn'],
        'dealerLanguageTag': value['dealerLanguageTag'],
    };
}

