import { defineMessages } from 'react-intl'

export const priceEstimationMessages = defineMessages({
    tileHeading: {
        id: 'page.details.marketPriceResearch.tileHeadingMarketPrice',
        defaultMessage: 'Market price',
    },
    labelMarketPriceEstimation: {
        id: 'page.details.marketPriceResearch.labelMarketPriceEstimation',
        defaultMessage: 'Market Price Estimation',
    },
    labelSource: {
        id: 'page.details.marketPriceResearch.priceEstimation.labelSource',
        defaultMessage: 'Source',
    },
    labelPriceEstimationNotPossible: {
        id: 'page.details.marketPriceResearch.priceEstimation.labelNoPriceEstimation',
        defaultMessage: 'Price estimation not possible due to a lack of market data.',
    },
    labelPriceEstimationIsLoading: {
        id: 'page.details.marketPriceResearch.priceEstimation.labelPriceEstimationIsLoading',
        defaultMessage: 'Please be patient while a price is being estimated.',
    },
    infoPopupAboutAnalyticCompany: {
        id: 'page.details.marketPriceResearch.priceEstimation.infoPopupAboutAnalyticCompany',
        defaultMessage:
            'Analytic Company is the most technology-driven and innovative company on the topic of proving car market prices. The underlying data used by Analytic Company is driven by the extensive automotive market.',
    },
    labelPriceEstimationWarningsPopup: {
        id: 'page.details.marketPriceResearch.priceEstimation.warningPopupAboutPriceEstimation',
        defaultMessage:
            'The estimated price is based on uncertainties. The following warnings are issued by Analytic Company:',
    },
    textFeedbackAppreciated: {
        id: 'page.details.marketPriceResearch.priceEstimation.textFeedBackAppreciated',
        defaultMessage: 'Does this automatically determined market price replace your market price research?',
    },
    feedbackYes: {
        id: 'page.details.marketPriceResearch.priceEstimation.feedbackYes',
        defaultMessage: 'Yes',
    },
    feedbackNo: {
        id: 'page.details.marketPriceResearch.priceEstimation.feedbackNo',
        defaultMessage: 'No',
    },
    feedbackRequestText: {
        id: 'page.details.marketPriceResearch.priceEstimation.feedbackRequestText',
        defaultMessage: 'Why doesnt it replace your market price research?',
    },
    sendFeedbackButton: {
        id: 'page.details.marketPriceResearch.priceEstimation.sendFeedbackButton',
        defaultMessage: 'Send Feedback',
    },
    feedbackSubmittedSuccessfully: {
        id: 'page.details.marketPriceResearch.priceEstimation.feedbackSubmittedSuccessfully',
        defaultMessage: 'Feedback submitted successfully. Thank you!',
    },
    feedbackSubmissionError: {
        id: 'page.details.marketPriceResearch.priceEstimation.feedbackSubmissionError',
        defaultMessage: 'Something went wrong sending feedback. Please try again in a minute.',
    },
})
