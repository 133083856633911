/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceEstimationPriceDto
 */
export interface PriceEstimationPriceDto {
    /**
     * 
     * @type {number}
     * @memberof PriceEstimationPriceDto
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof PriceEstimationPriceDto
     */
    currency: PriceEstimationPriceDtoCurrencyEnum;
}


/**
 * @export
 */
export const PriceEstimationPriceDtoCurrencyEnum = {
    EUR: 'EUR',
    GBP: 'GBP'
} as const;
export type PriceEstimationPriceDtoCurrencyEnum = typeof PriceEstimationPriceDtoCurrencyEnum[keyof typeof PriceEstimationPriceDtoCurrencyEnum];


/**
 * Check if a given object implements the PriceEstimationPriceDto interface.
 */
export function instanceOfPriceEstimationPriceDto(value: object): value is PriceEstimationPriceDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function PriceEstimationPriceDtoFromJSON(json: any): PriceEstimationPriceDto {
    return PriceEstimationPriceDtoFromJSONTyped(json, false);
}

export function PriceEstimationPriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceEstimationPriceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'currency': json['currency'],
    };
}

export function PriceEstimationPriceDtoToJSON(value?: PriceEstimationPriceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'currency': value['currency'],
    };
}

