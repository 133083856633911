import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

import { DescriptionList } from '~/app/components/shared/DescriptionList'

import { messages } from './messages'

export interface AccidentsAndDamagesProps {
    isAccidentFree: boolean
    accidentDescription?: string
    isDamageFree: boolean
    damageDescription?: string
}

export const AccidentsAndDamages = ({
    isAccidentFree,
    accidentDescription,
    isDamageFree,
    damageDescription,
}: PropsWithChildren<AccidentsAndDamagesProps>) => {
    const intl = useIntl()

    return (
        <DescriptionList
            heading={intl.formatMessage(messages.heading)}
            content={[
                {
                    label: intl.formatMessage(messages.accidents),
                    value: (isAccidentFree ? intl.formatMessage(messages.no) : accidentDescription) ?? '',
                },
                {
                    label: intl.formatMessage(messages.damages),
                    value: (isDamageFree ? intl.formatMessage(messages.no) : damageDescription) ?? '',
                },
            ]}
        />
    )
}
