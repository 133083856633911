import { createContext } from 'react'

export enum MatomoPageId {
    OVERVIEW_PAGE = 'syp_overview_page',
    DETAIL_PAGE = 'syp_detail_page',
}

export enum MatomoCategory {
    OVERVIEW = 'syp-purchase-request-overview',
    VEHICLE_DETAILS = 'syp-vehicle-details',
}

type FeedbackAction<S extends string = string> = `syp-submit-feedback-${S}` | `syp-selected-feedback-${S}`
type TrackEvent =
    | {
          category: MatomoCategory
          action: FeedbackAction | 'hide-purchase-request' | 'show-purchase-request'
          name?: MatomoFeedbackEventName
      }
    | {
          category: MatomoCategory.OVERVIEW
          action: 'set-area-search-filter' | 'dismiss-area-search-filter'
          name: MatomoAreaSearchEventName
      }
    | {
          category: MatomoCategory.VEHICLE_DETAILS
          action: 'syp-make-offer-open' | 'syp-make-offer-submit' | 'how-it-works' | 'show-in-pcss'
      }

export type MatomoContextProps = {
    trackEvent: (trackEvent: TrackEvent) => void
    trackPageView: (pageId: MatomoPageId) => void
}

export type MatomoFeedbackEventName<T extends string = string, N extends number = number> =
    | `syp-feedback-${T}`
    | `${N}-syp-feedback-${T}`

export type MatomoAreaSearchEventName<T extends string = string> = `syp-area-search-filter-${T}`

const matomoNotInitialized = () => {
    throw Error('MatomoContext not initialized.')
}
export const MatomoContext = createContext<MatomoContextProps>({
    trackEvent: matomoNotInitialized,
    trackPageView: matomoNotInitialized,
})
