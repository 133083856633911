import { PButton, PButtonPure, PLinkPure, PModal, PTag, PToast } from '@porsche-design-system/components-react'
import { spacing, theme } from '@porsche-design-system/components-react/styles'
import { HeadlineDataResponseDto, PurchaseRequestDto } from 'dealer-prs-api-client'
import { PropsWithChildren, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { HeadingXXLarge } from '~/app/base-components/styled/Heading'
import { SpacingFluidMedium } from '~/app/base-components/styled/Spacer'
import { AccidentsAndDamages } from '~/app/components/accidents-and-damages/AccidentsAndDamages'
import { AdditionalInformation } from '~/app/components/additional-information/AdditionalInformation'
import {
    ConditionAndHistoryView,
    ConditionAndHistoryViewProps,
} from '~/app/components/condition-and-history/ConditionAndHistory'
import { EquipmentView, EquipmentViewProps } from '~/app/components/equipment/EquipmentView'
import { DealerFeedbackForm } from '~/app/components/feedback/DealerFeedbackForm'
import { HideButton } from '~/app/components/hide-button/HideButton'
import { HowDoesItWork } from '~/app/components/how-does-it-work/HowDoesItWork'
import { Card } from '~/app/components/layout/Card'
import { WideTopLevelContent } from '~/app/components/layout/WideTopLevelContent'
import { useSendOffer } from '~/app/components/make-offer/hooks/useSendOffer.hook'
import { MakeOfferView, MakeOfferViewProps } from '~/app/components/make-offer/MakeOfferView'
import {
    MarketPriceResearchView,
    MarketPriceResearchViewProps,
} from '~/app/components/market-price/MarketPriceResearchView'
import {
    ModelInformationView,
    ModelInformationViewProps,
} from '~/app/components/model-information/ModelInformationView'
import { OfferStatusView, OfferStatusViewProps } from '~/app/components/offer-status/OfferStatusView'
import { MatomoCategory } from '~/app/context/matomoContext'
import { useMatomo } from '~/app/hooks/useMatomo'
import { formatDateWithTime } from '~/app/utils/FormatDateWithTime'
import { formatDistance } from '~/app/utils/FormatDistance'

import { messages } from './messages'

type PurchaseRequestDetailPageProps = {
    // Url to show to user for the backlink
    backLinkUrl: string
    conditionAndHistoryProps: ConditionAndHistoryViewProps
    equipmentProps: EquipmentViewProps
    feedbackProps?: {
        alwaysShowFeedbackQuestion: boolean
    }
    headlineProps: HeadlineDataResponseDto
    isHidePending: boolean
    makeOfferProps: Omit<MakeOfferViewProps, 'cancel' | 'sendOffer'>
    marketPriceResearchProps: MarketPriceResearchViewProps
    modelInformationProps: ModelInformationViewProps
    offerStatusProps: OfferStatusViewProps
    // Event to trigger, when backlink is pressed
    onBackLink: () => void
    onHide: (currentVisibilityStatus: boolean) => Promise<void>
    purchaseRequestData: PurchaseRequestDto
    purchaseRequestId: string
}

export const PurchaseRequestDetailsPageView = ({
    backLinkUrl,
    conditionAndHistoryProps,
    equipmentProps,
    feedbackProps = {
        alwaysShowFeedbackQuestion: false,
    },
    headlineProps,
    isHidePending,
    makeOfferProps,
    marketPriceResearchProps,
    modelInformationProps,
    offerStatusProps,
    onBackLink,
    onHide,
    purchaseRequestData,
    purchaseRequestId,
}: PurchaseRequestDetailPageProps) => {
    const intl = useIntl()
    const { trackEvent } = useMatomo()

    const [isMakeOfferModalOpen, setIsMakeOfferModalOpen] = useState<boolean>(false)
    const [isHowDoesItWorkModalOpen, setIsHowDoesItWorkModalOpen] = useState<boolean>(false)
    const onMakeOfferFinished = useCallback(() => {
        setIsMakeOfferModalOpen(false)
    }, [])

    const sendOffer = useSendOffer({ onFinished: onMakeOfferFinished, purchaseRequestId, hidden: headlineProps.hidden })

    const modelName = `${headlineProps.modelDetails.derivative} (${headlineProps.modelDetails.modelGeneration})`

    const openMakeOfferModal = () => {
        trackEvent({ category: MatomoCategory.VEHICLE_DETAILS, action: 'syp-make-offer-open' })
        setIsMakeOfferModalOpen(true)
    }

    return (
        <>
            <WideTopLevelContent>
                <BackLinkWithHowToButton>
                    <PLinkPure
                        icon={'arrow-head-left'}
                        href={backLinkUrl}
                        onClick={(e) => {
                            e.preventDefault()
                            // Navigate back, when we have a non-default location key (i.e. navigated here)
                            onBackLink()
                        }}
                    >
                        {intl.formatMessage(messages.backToOverview)}
                    </PLinkPure>
                    <PButtonPure
                        icon={'question'}
                        onClick={() => {
                            trackEvent({ category: MatomoCategory.VEHICLE_DETAILS, action: 'how-it-works' })
                            setIsHowDoesItWorkModalOpen(true)
                        }}
                    >
                        {intl.formatMessage(messages.howDoesItWorkButtonLabel)}
                    </PButtonPure>
                </BackLinkWithHowToButton>
                <HeadlineFlex>
                    <HeadingXXLarge>Porsche {modelName}</HeadingXXLarge>
                    <MakeOfferButtonWithHideButton>
                        <HideButton
                            hidden={headlineProps.hidden}
                            onHide={onHide}
                            disabled={headlineProps.offerMadeByDealer || isHidePending}
                            location={'details-page'}
                        />
                        <PButton
                            icon={'purchase'}
                            onClick={openMakeOfferModal}
                            disabled={headlineProps.status === 'CLOSED' || headlineProps.offerMadeByDealer}
                        >
                            {intl.formatMessage(messages.makeOfferButtonLabel)}
                        </PButton>
                    </MakeOfferButtonWithHideButton>
                </HeadlineFlex>
                <HeadlineTags>
                    <PTag icon="pin">{`${formatDistance(headlineProps.distance.value, headlineProps.distance.unit, intl)} (${headlineProps.postalCode})`}</PTag>
                    <PTag>
                        {intl.formatMessage(messages.requestedOn, {
                            requestDate: formatDateWithTime(headlineProps.requestDate, intl.locale),
                        })}
                    </PTag>
                    {headlineProps.hidden && <PTag icon={'view-off'}>{intl.formatMessage(messages.hidden)}</PTag>}
                </HeadlineTags>
                {/* using "isModalOpen &&" so the modal is completely destroyed when hidden and reset when shown again */}
                {isMakeOfferModalOpen && (
                    <PModal open={isMakeOfferModalOpen} onDismiss={() => setIsMakeOfferModalOpen(false)}>
                        <h2 slot={'header'}>{intl.formatMessage(messages.makeOfferModalHeadline, { modelName })}</h2>
                        <MakeOfferView {...makeOfferProps} cancel={onMakeOfferFinished} sendOffer={sendOffer} />
                    </PModal>
                )}
                <PModal open={isHowDoesItWorkModalOpen} onDismiss={() => setIsHowDoesItWorkModalOpen(false)}>
                    <h2 slot={'header'}>{intl.formatMessage(messages.howDoesItWorkHeadline, { modelName })}</h2>
                    <HowDoesItWork />
                </PModal>
            </WideTopLevelContent>
            <GreyBackground>
                <WideTopLevelContent>
                    <CardWithMarginTop>
                        <OfferStatusView {...offerStatusProps} />
                    </CardWithMarginTop>
                    <CardWithMarginTop>
                        <AdditionalInformation
                            tradeInInterest={purchaseRequestData.tradeInInterest}
                            customerComment={purchaseRequestData.additionalNotes}
                        />
                    </CardWithMarginTop>
                    <CardWithMarginTop>
                        <ModelInformationView {...modelInformationProps} />
                    </CardWithMarginTop>
                    <CardWithMarginTop>
                        <MarketPriceResearchView {...marketPriceResearchProps} />
                    </CardWithMarginTop>
                    <CardWithMarginTop>
                        <ConditionAndHistoryView {...conditionAndHistoryProps} />
                    </CardWithMarginTop>
                    <CardWithMarginTop>
                        <AccidentsAndDamages {...purchaseRequestData} />
                    </CardWithMarginTop>
                    <CardWithMarginTop>
                        <EquipmentView {...equipmentProps} />
                    </CardWithMarginTop>
                    <SpacingFluidMedium />
                    <DealerFeedbackForm page="vehicle-details" {...feedbackProps} />
                </WideTopLevelContent>
            </GreyBackground>
            {/* XXX: SOC-2889 component test this once we hit that mark */}
            <PToast />
        </>
    )
}

const BackLinkWithHowToButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${spacing.fluid.medium};
`

const HeadlineFlex = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${spacing.fluid.medium};
`

const MakeOfferButtonWithHideButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing.fluid.medium};
`

const GreyBackground = styled.div`
    background-color: ${theme.light.background.surface};
`

const CardWithMarginTop = ({ children }: PropsWithChildren) => (
    <>
        <SpacingFluidMedium />
        <Card>{children}</Card>
    </>
)

export const HeadlineTags = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${spacing.fluid.small};
    margin-top: ${spacing.fluid.small};
    margin-bottom: ${spacing.fluid.medium};
`
