/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealershipSettingsMerchantRequestDto
 */
export interface DealershipSettingsMerchantRequestDto {
    /**
     * Enables purchase request email notifications
     * @type {boolean}
     * @memberof DealershipSettingsMerchantRequestDto
     */
    notificationsEnabled: boolean;
    /**
     * List of email addresses which should receive notifications about new purchase requests!
     * @type {Array<string>}
     * @memberof DealershipSettingsMerchantRequestDto
     */
    contactEmailAddresses: Array<string>;
}

/**
 * Check if a given object implements the DealershipSettingsMerchantRequestDto interface.
 */
export function instanceOfDealershipSettingsMerchantRequestDto(value: object): value is DealershipSettingsMerchantRequestDto {
    if (!('notificationsEnabled' in value) || value['notificationsEnabled'] === undefined) return false;
    if (!('contactEmailAddresses' in value) || value['contactEmailAddresses'] === undefined) return false;
    return true;
}

export function DealershipSettingsMerchantRequestDtoFromJSON(json: any): DealershipSettingsMerchantRequestDto {
    return DealershipSettingsMerchantRequestDtoFromJSONTyped(json, false);
}

export function DealershipSettingsMerchantRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealershipSettingsMerchantRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationsEnabled': json['notificationsEnabled'],
        'contactEmailAddresses': json['contactEmailAddresses'],
    };
}

export function DealershipSettingsMerchantRequestDtoToJSON(value?: DealershipSettingsMerchantRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notificationsEnabled': value['notificationsEnabled'],
        'contactEmailAddresses': value['contactEmailAddresses'],
    };
}

