import { PInlineNotification, PSpinner } from '@porsche-design-system/components-react'
import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHref, useLocation, useNavigate, useParams } from 'react-router'

import { showPersonalData, showPersonalDataBox } from '~/app/components/offer-status/OfferStatus.logic'
import { OverallStatus } from '~/app/components/overall-status/overallStatusTag.shared'
import { GenericInlineError } from '~/app/components/table/GenericInlineError'
import { MatomoPageId } from '~/app/context/matomoContext'
import { useEquipmentQuery } from '~/app/hooks/queries/useEquipmentQuery'
import { useHeadlineDataQuery } from '~/app/hooks/queries/useHeadlineDataQuery'
import { useModelInformationQuery } from '~/app/hooks/queries/useModelInformationQuery'
import { useMSRPQuery } from '~/app/hooks/queries/useMSRPQuery'
import { useOfferStatusDataQuery } from '~/app/hooks/queries/useOfferStatusDataQuery'
import { usePriceEstimationQuery } from '~/app/hooks/queries/usePriceEstimationQuery'
import { usePurchaseRequestQuery } from '~/app/hooks/queries/usePurchaseRequestQuery'
import { useSearchLinkParametersQuery } from '~/app/hooks/queries/useSearchLinkParametersQuery'
import { useMatomo } from '~/app/hooks/useMatomo'
import { usePurchaseRequestVisibility } from '~/app/hooks/usePurchaseRequestVisibility'
import { useDealerFrontendContext } from '~/dealerFrontendProvider'

import { messages } from './messages'
import { PurchaseRequestDetailsPageView } from './PurchaseRequestDetailsPageView'

export const PurchaseRequestDetailsPageContainer = () => {
    const {
        dealerContext: { currency, locale: languageTag, dealershipId: dealerEntryDn },
    } = useDealerFrontendContext()
    const { id } = useParams()
    const intl = useIntl()
    const { trackPageView } = useMatomo()
    const { mutatePurchaseRequestVisibility, mutatePurchaseRequestVisibilityIsPending } = usePurchaseRequestVisibility()
    const navigate = useNavigate()

    const location = useLocation()

    const prevPathState = (location.state as { prevPath?: string })?.prevPath
    const prevPath = useHref(prevPathState ?? '/')

    useEffect(() => {
        trackPageView(MatomoPageId.DETAIL_PAGE)
    }, [trackPageView])

    const { data: headlineProps, isLoading: headlineIsLoading } = useHeadlineDataQuery({
        id: id ?? '',
        dealerEntryDn,
        enabled: !!id,
    })

    const { data: purchaseRequestData, isLoading: purchaseRequestIsLoading } = usePurchaseRequestQuery({
        id: id!,
        dealerEntryDn,
    })

    const isLoading = headlineIsLoading || purchaseRequestIsLoading

    const onBackLink = useCallback(() => {
        // Navigate back, when we have a non-default location key (i.e. navigated here)
        if (prevPathState) {
            void navigate(-1)
        } else {
            // Otherwise, go to the default tableLink
            void navigate(prevPath)
        }
    }, [navigate, prevPath, prevPathState])

    const { data: searchLinkData, isLoading: searchLinkIsLoading } = useSearchLinkParametersQuery({
        purchaseRequestId: id!,
        dealerEntryDn,
        languageTag,
    })

    const {
        data: priceEstimationData,
        isLoading: priceEstimationIsLoading,
        error: priceEstimationError,
    } = usePriceEstimationQuery({ purchaseRequestId: id!, dealerEntryDn })

    const {
        data: msrpData,
        isLoading: msrpIsLoading,
        error: msrpError,
    } = useMSRPQuery({ purchaseRequestId: id!, dealerEntryDn, languageTag })

    const { data: equipmentData, isLoading: equipmentIsLoading } = useEquipmentQuery({
        purchaseRequestId: id!,
        dealerEntryDn,
        languageTag,
    })

    const { data: modelInformationData, isLoading: modelInformationIsLoading } = useModelInformationQuery({
        purchaseRequestId: id!,
        languageTag,
        dealerEntryDn,
    })

    const { data: offerStatusData } = useOfferStatusDataQuery({ purchaseRequestId: id!, dealerEntryDn })

    const isPersonalDataBoxVisible = showPersonalDataBox(offerStatusData)
    const isPersonalDataVisible = showPersonalData(offerStatusData)

    if (!id) {
        return <GenericInlineError />
    }

    const onHide = async (currentVisibilityStatus: boolean) => {
        await mutatePurchaseRequestVisibility({
            purchaseRequestId: id,
            hidden: !currentVisibilityStatus,
        })
    }

    if (isLoading) {
        return <PSpinner />
    }

    if (!headlineProps || !purchaseRequestData) {
        return (
            <PInlineNotification state="error" dismissButton={false}>
                {intl.formatMessage(messages.noDataError)}
            </PInlineNotification>
        )
    }

    // Providing nested container components in context, so they can be easily replaced in storybook
    return (
        <PurchaseRequestDetailsPageView
            purchaseRequestId={id}
            headlineProps={headlineProps}
            purchaseRequestData={purchaseRequestData}
            onHide={onHide}
            onBackLink={onBackLink}
            backLinkUrl={prevPath}
            isHidePending={mutatePurchaseRequestVisibilityIsPending}
            conditionAndHistoryProps={{
                mileage: purchaseRequestData.mileage,
                firstRegistration: purchaseRequestData.firstRegistration,
                previousOwners: purchaseRequestData.previousOwners,
                fullServiceHistory: purchaseRequestData.fullServiceHistory,
                pcssUrl: purchaseRequestData.pcssUrl ?? undefined,
                vatDeductible: purchaseRequestData.vatDeductible ?? undefined,
            }}
            marketPriceResearchProps={{
                marketPriceLinksProps: {
                    isLoading: searchLinkIsLoading,
                    searchFilterData: searchLinkData,
                },
                msrpPriceProps: {
                    error: msrpError,
                    isLoading: msrpIsLoading,
                    formattedTotalPrice: msrpData?.totalPrice,
                    formattedOptionSum: msrpData?.optionSum,
                },
                priceEstimationProps: {
                    purchaseRequestId: id,
                    error: priceEstimationError,
                    isLoading: priceEstimationIsLoading,
                    currency: priceEstimationData?.trend30days.currency,
                    marketPrice: priceEstimationData?.trend30days.value,
                    marketPriceWarnings: priceEstimationData?.warnings,
                },
            }}
            equipmentProps={{
                isLoading: equipmentIsLoading,
                data: equipmentData,
            }}
            modelInformationProps={{
                isLoading: modelInformationIsLoading,
                data: modelInformationData,
            }}
            offerStatusProps={{
                overallStatus: purchaseRequestData?.overallStatus as OverallStatus,
                offer: offerStatusData,
                showPersonalDataBox: isPersonalDataBoxVisible,
                showPersonalData: isPersonalDataVisible,
                statistics: purchaseRequestData?.statistics,
            }}
            makeOfferProps={{
                currency,
            }}
        />
    )
}
