import { defineMessages, useIntl } from 'react-intl'

import { FeedbackPage } from './Feedback'
import { FeedbackContainer } from './FeedbackContainer'

export const DealerFeedbackForm = ({
    page,
    alwaysShowFeedbackQuestion,
}: {
    page: FeedbackPage
    alwaysShowFeedbackQuestion?: boolean
}) => {
    const intl = useIntl()

    return (
        <FeedbackContainer
            theme={'light'}
            page={page}
            likert={{
                id: `${page}-feedback`,
                question: {
                    localized: intl.formatMessage(messages.likertQuestion),
                    canonical: messages.likertQuestion.defaultMessage,
                },
                answers: [
                    {
                        label: intl.formatMessage(messages.ratherNotGood),
                        value: 'syp-feedback-rather-not-good',
                    },
                    {
                        label: intl.formatMessage(messages.neutral),
                        value: 'syp-feedback-neutral',
                    },
                    {
                        label: intl.formatMessage(messages.ratherGood),
                        value: 'syp-feedback-rather-good',
                    },
                ],
            }}
            feedbackQuestion={{
                localized: intl.formatMessage(messages.feedbackQuestion),
                canonical: messages.feedbackQuestion.defaultMessage,
            }}
            alwaysShowFeedbackQuestion={alwaysShowFeedbackQuestion}
        />
    )
}

const messages = defineMessages({
    likertQuestion: {
        id: 'feedback.vehicle-details.likert.question',
        description: 'Question displayed above the Likert',
        defaultMessage: 'How well are you informed on this page?',
    },
    ratherNotGood: {
        id: 'feedback.vehicle-details.likert.answer.ratherNotGood',
        description: 'First answer for the likert',
        defaultMessage: 'Rather not good',
    },
    neutral: {
        id: 'feedback.vehicle-details.likert.answer.neutral',
        description: 'Second answer for the likert',
        defaultMessage: 'Neutral',
    },
    ratherGood: {
        id: 'feedback.vehicle-details.likert.answer.ratherGood',
        description: 'Third answer for the likert',
        defaultMessage: 'Rather good',
    },
    feedbackQuestion: {
        id: 'feedback.vehicle-details.text.question',
        description: 'Question displayed above the free text field',
        defaultMessage: 'What additional information would you like?',
    },
})
