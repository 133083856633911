import { DealerContextProps } from '@slfinrtl/context-types'
import { basePathMiddleware } from 'dealer-prs-api-client'
import { isMarketplaceKey } from 'marketconfig'
import { useEffect, useMemo } from 'react'
import { createBrowserRouter, Navigate } from 'react-router'
import { RouterProvider } from 'react-router/dom'

import { DealerFrontendProvider } from '~/dealerFrontendProvider'

import { NoTranslationsSkeleton } from './components/NoTranslationsSkeleton'
import PurchaseRequestTableContainer from './components/table/PurchaseRequestTableContainer'
import { FeatureFlagProvider } from './config/featureFlagContext'
import { MatomoContextProps } from './context/matomoContext'
import { MarketConfigProvider } from './hooks/useMarketConfig'
import { usePPNToken } from './hooks/usePPNToken'
import { useTranslations } from './hooks/useTranslations'
import { PurchaseRequestDetailsPageContainer } from './pages/details/PurchaseRequestDetailsPageContainer'
import { PurchaseRequestOverviewPageContainer } from './pages/PurchaseRequestOverviewPage'

export type AppProps = {
    basePath?: string
    context: DealerContextProps
    trackAnalyticsEvent: MatomoContextProps['trackEvent']
    trackAnalyticsPageView: MatomoContextProps['trackPageView']
}

const routes = [
    {
        path: '/',
        element: <PurchaseRequestOverviewPageContainer key={'navigationOverview'} />,
        children: [
            { index: true, element: <PurchaseRequestTableContainer key={'navigationDefaultOverview'} /> },
            {
                path: '/with-own-offers',
                element: <PurchaseRequestTableContainer key={'navigationWithOwnOffers'} />,
            },
            {
                path: '/hidden',
                element: <PurchaseRequestTableContainer key={'navigationHidden'} />,
            },
        ],
    },
    { id: 'detailView', path: '/details/:id', element: <PurchaseRequestDetailsPageContainer /> },
    { id: 'default', path: '*', element: <Navigate to="/" /> },
]

export function App({
    context,
    basePath = `/${context.dealershipId}/${context.locale}/purchase-requests`,
    trackAnalyticsEvent,
    trackAnalyticsPageView,
}: AppProps) {
    // FIXME: Validate context values, because they could be unset and cannot be type-checked properly, i.e. we cannot expect DealerContextProps here
    const { environment, getAuthorizationHeader } = context

    usePPNToken(getAuthorizationHeader)

    // Pass current env to middleware that overrides the API-baseURL w/ stage specific baseURL
    useEffect(() => {
        basePathMiddleware.setEnv(environment ?? 'local')
    }, [environment])

    const { messages } = useTranslations(context.locale)
    const router = useMemo(
        () =>
            createBrowserRouter(routes, {
                basename: basePath,
            }),
        [basePath],
    )

    if (!isMarketplaceKey(context.marketplaceKey)) {
        throw new Error('Marketplace not supported!')
    }

    return (
        <DealerFrontendProvider
            context={context}
            messages={messages}
            trackAnalyticsEvent={trackAnalyticsEvent}
            trackAnalyticsPageView={trackAnalyticsPageView}
        >
            <MarketConfigProvider marketplace={context.marketplaceKey}>
                <FeatureFlagProvider environment={environment}>
                    <NoTranslationsSkeleton messages={messages}>
                        <RouterProvider router={router} />
                    </NoTranslationsSkeleton>
                </FeatureFlagProvider>
            </MarketConfigProvider>
        </DealerFrontendProvider>
    )
}
