import { MarketplaceKey } from './marketplaceKey'

export interface MarketConfig {
    locales: string[]
    // In case multiple locales are supported in a market, this one is preferred if necessary
    defaultLocale: string
    distanceUnit: 'KM' | 'SMI'
    // indicates if the market is enabled on prod or preview (dev is always enabled)
    enabled: {
        preview: boolean
        prod: boolean
    }
    // Indicates whether a privacy policy consent (checkbox) is required for the market
    privacyPolicyConsent: boolean
    // Indicates whether vatDeductible field (radio buttons) are shown in contact form and as information in dealer app
    showVatDeductible: boolean
    // Indicates that market price estimation is enabled
    showMarketPriceEstimation: boolean
    // Indicates that third party market provider is enabled (e.g. link to mobile.de in Germany)
    showThirdPartyMarketProvider: boolean
}

export const marketConfig: { [marketplaceKey in MarketplaceKey]: MarketConfig } = {
    de: {
        locales: ['de-DE'],
        defaultLocale: 'de-DE',
        distanceUnit: 'KM',
        enabled: {
            preview: true,
            prod: true,
        },
        privacyPolicyConsent: false,
        showVatDeductible: true,
        showMarketPriceEstimation: true,
        showThirdPartyMarketProvider: true,
    },
    fr: {
        locales: ['fr-FR'],
        defaultLocale: 'fr-FR',
        distanceUnit: 'KM',
        enabled: {
            preview: true,
            prod: true,
        },
        privacyPolicyConsent: false,
        showVatDeductible: false,
        showMarketPriceEstimation: false,
        showThirdPartyMarketProvider: false,
    },
    gb: {
        locales: ['en-GB'],
        defaultLocale: 'en-GB',
        distanceUnit: 'SMI',
        enabled: {
            preview: true,
            prod: true,
        },
        privacyPolicyConsent: true,
        showVatDeductible: false,
        showMarketPriceEstimation: false,
        showThirdPartyMarketProvider: false,
    },
    it: {
        locales: ['it-IT'],
        defaultLocale: 'it-IT',
        distanceUnit: 'KM',
        enabled: {
            preview: true,
            prod: false,
        },
        privacyPolicyConsent: false,
        showVatDeductible: false,
        showMarketPriceEstimation: false,
        showThirdPartyMarketProvider: false,
    },
}
