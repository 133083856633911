/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealerDetailsDto
 */
export interface DealerDetailsDto {
    /**
     * Name of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    name: string;
    /**
     * Email of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    email?: string;
    /**
     * Street of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    street?: string;
    /**
     * City of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    city?: string;
    /**
     * Postal code of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    postalCode?: string;
    /**
     * Phone number of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    phoneNumber?: string;
    /**
     * Website URL of the Porsche Centre
     * @type {string}
     * @memberof DealerDetailsDto
     */
    websiteUrl?: string;
}

/**
 * Check if a given object implements the DealerDetailsDto interface.
 */
export function instanceOfDealerDetailsDto(value: object): value is DealerDetailsDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function DealerDetailsDtoFromJSON(json: any): DealerDetailsDto {
    return DealerDetailsDtoFromJSONTyped(json, false);
}

export function DealerDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealerDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'street': json['street'] == null ? undefined : json['street'],
        'city': json['city'] == null ? undefined : json['city'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'websiteUrl': json['websiteUrl'] == null ? undefined : json['websiteUrl'],
    };
}

export function DealerDetailsDtoToJSON(value?: DealerDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'email': value['email'],
        'street': value['street'],
        'city': value['city'],
        'postalCode': value['postalCode'],
        'phoneNumber': value['phoneNumber'],
        'websiteUrl': value['websiteUrl'],
    };
}

