import { useToastManager } from '@porsche-design-system/components-react'
import { themeLightContrastLow, themeLightContrastMedium } from '@porsche-design-system/components-react/styles'
import { useMutation } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { useDealerFrontendContext } from '~/dealerFrontendProvider'

import { priceEstimationMessages } from './messages'
import {
    FeedbackBox,
    FeedbackBoxAnswer,
    FeedbackBoxText,
    FeedbackChoiceLink,
    FeedbackTextarea,
    NegativeFeedbackForm,
    SendFeedbackButton,
    Textarea,
    TextXSmall,
} from './PriceEstimation.styled'

export interface PriceEstimationFeedbackProps {
    purchaseRequestId: string
}

/**
 * This component offers the ability to provide feedback on the price estimation, either `helpful` or `not helpful`
 * with an optional free text field for the latter option.
 */
export const PriceEstimationFeedback = ({ purchaseRequestId }: PriceEstimationFeedbackProps) => {
    const intl = useIntl()
    const {
        dealerContext: { locale, dealershipId: dealerEntryDn },
    } = useDealerFrontendContext()
    const { addMessage } = useToastManager()

    const [isHelpful, setIsHelpful] = useState<boolean | undefined>(undefined)
    const [feedbackText, setFeedbackText] = useState<string>()
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    const { mutateAsync, isPending } = useMutation({
        mutationFn: (data: { purchaseRequestId: string; priceEstimationIsHelpful: boolean }) => {
            return purchaseRequestDealerAppEndpointsApi.postVehicleMarketPriceFeedback({
                purchaseRequestId: data.purchaseRequestId,
                dealerEntryDn,
                priceEstimationFeedbackDto: {
                    priceEstimationIsHelpful: data.priceEstimationIsHelpful,
                    locale: locale,
                    freeText: feedbackText,
                },
            })
        },
        onSuccess: () => {
            setIsSubmitted(true)
            addMessage({
                text: intl.formatMessage(priceEstimationMessages.feedbackSubmittedSuccessfully),
                state: 'success',
            })
        },
        onError: () => {
            addMessage({ text: intl.formatMessage(priceEstimationMessages.feedbackSubmissionError), state: 'info' })
        },
    })

    const sendFeedback = async (priceEstimationIsHelpful: boolean) => {
        await mutateAsync({
            purchaseRequestId: purchaseRequestId,
            priceEstimationIsHelpful,
        })
    }

    if (isSubmitted) {
        return null
    }

    return (
        <FooterBox>
            <FeedbackBox>
                <FeedbackBoxText>
                    <TextXSmall>{intl.formatMessage(priceEstimationMessages.textFeedbackAppreciated)}</TextXSmall>
                </FeedbackBoxText>
                <FeedbackBoxAnswer>
                    <FeedbackChoiceLink
                        weight={'regular'}
                        size={'x-small'}
                        color={'primary'}
                        data-test-id={'feedback-choice-is-helpful'}
                        onClick={() => {
                            setIsHelpful(true)
                            void sendFeedback(true)
                        }}
                        textColor={themeLightContrastMedium}
                    >
                        {intl.formatMessage(priceEstimationMessages.feedbackYes)}
                    </FeedbackChoiceLink>
                    <FeedbackChoiceLink
                        weight={'regular'}
                        size={'x-small'}
                        data-test-id={'feedback-choice-not-helpful'}
                        onClick={() => {
                            setIsHelpful(false)
                        }}
                        color={isHelpful === false ? 'notification-error' : 'primary'}
                        textColor={themeLightContrastMedium}
                    >
                        {intl.formatMessage(priceEstimationMessages.feedbackNo)}
                    </FeedbackChoiceLink>
                </FeedbackBoxAnswer>
            </FeedbackBox>
            {isHelpful === false && (
                <NegativeFeedbackForm>
                    <TextXSmall>{intl.formatMessage(priceEstimationMessages.feedbackRequestText)}</TextXSmall>
                    <FeedbackTextarea>
                        <Textarea
                            data-test-id={'feedback-not-helpful-text'}
                            onChange={(e) => {
                                setFeedbackText(e.target.value)
                            }}
                        />
                    </FeedbackTextarea>
                    <div>
                        <SendFeedbackButton
                            loading={isPending}
                            size={'x-small'}
                            data-test-id={'feedback-submit-button'}
                            onClick={() => {
                                void sendFeedback(false)
                            }}
                        >
                            {intl.formatMessage(priceEstimationMessages.sendFeedbackButton)}
                        </SendFeedbackButton>
                    </div>
                </NegativeFeedbackForm>
            )}
        </FooterBox>
    )
}

export const FooterBox = styled.div`
    border-top: 1px solid ${themeLightContrastLow};
`
