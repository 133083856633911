/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerPersonalDetailsDto
 */
export interface CustomerPersonalDetailsDto {
    /**
     * Email address of the person who created the purchase request
     * @type {string}
     * @memberof CustomerPersonalDetailsDto
     */
    email: string;
    /**
     * Name of the person who created the purchase request
     * @type {string}
     * @memberof CustomerPersonalDetailsDto
     */
    name: string;
    /**
     * Phone number of the person who created the purchase request
     * @type {string}
     * @memberof CustomerPersonalDetailsDto
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the CustomerPersonalDetailsDto interface.
 */
export function instanceOfCustomerPersonalDetailsDto(value: object): value is CustomerPersonalDetailsDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CustomerPersonalDetailsDtoFromJSON(json: any): CustomerPersonalDetailsDto {
    return CustomerPersonalDetailsDtoFromJSONTyped(json, false);
}

export function CustomerPersonalDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPersonalDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    };
}

export function CustomerPersonalDetailsDtoToJSON(value?: CustomerPersonalDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'name': value['name'],
        'phoneNumber': value['phoneNumber'],
    };
}

