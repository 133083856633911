/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MileageInputDto
 */
export interface MileageInputDto {
    /**
     * Mileage of vehicle in given unit
     * @type {number}
     * @memberof MileageInputDto
     */
    value: number;
    /**
     * Unit of mileage value
     * @type {string}
     * @memberof MileageInputDto
     */
    unit: MileageInputDtoUnitEnum;
}


/**
 * @export
 */
export const MileageInputDtoUnitEnum = {
    KM: 'KM',
    SMI: 'SMI'
} as const;
export type MileageInputDtoUnitEnum = typeof MileageInputDtoUnitEnum[keyof typeof MileageInputDtoUnitEnum];


/**
 * Check if a given object implements the MileageInputDto interface.
 */
export function instanceOfMileageInputDto(value: object): value is MileageInputDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('unit' in value) || value['unit'] === undefined) return false;
    return true;
}

export function MileageInputDtoFromJSON(json: any): MileageInputDto {
    return MileageInputDtoFromJSONTyped(json, false);
}

export function MileageInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MileageInputDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'unit': json['unit'],
    };
}

export function MileageInputDtoToJSON(value?: MileageInputDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'unit': value['unit'],
    };
}

