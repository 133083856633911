import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface ModelInformationQueryProps {
    purchaseRequestId: string
    dealerEntryDn: string
    languageTag: string
}

export function getModelInformationQueryKey(id: string, dealerEntryDn: string, languageTag: string) {
    return ['MODEL_INFORMATION', id, dealerEntryDn, languageTag]
}

export const useModelInformationQuery = ({
    purchaseRequestId,
    dealerEntryDn,
    languageTag,
}: ModelInformationQueryProps) =>
    useQuery({
        queryKey: getModelInformationQueryKey(purchaseRequestId, dealerEntryDn, languageTag),
        queryFn: () =>
            purchaseRequestDealerAppEndpointsApi.getModelInformation({
                id: purchaseRequestId,
                languageTag,
                dealerEntryDn,
            }),
    })
