import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router'

const tabPaths = {
    0: { path: '/', dataView: undefined },
    1: { path: '/with-own-offers', dataView: 'WITH_OWN_OFFERS' as const },
    2: { path: '/hidden', dataView: 'HIDDEN_ONLY' as const },
}

function getTabIndexByPathname(pathname: string) {
    return Number.parseInt(
        Object.entries(tabPaths).find(([, value]) => pathname === value.path)?.[0] ?? '0',
        10,
    ) as keyof typeof tabPaths
}

/**
 * Manages the url state and extracts data from the url for the application.
 */
export function useRouterState() {
    const navigate = useNavigate()
    const location = useLocation()
    const [urlSearchParams] = useSearchParams()

    const areaSearchParam = urlSearchParams.get('distance')
        ? Number.parseInt(urlSearchParams.get('distance') ?? '', 10)
        : undefined
    const areaSearchDistance =
        areaSearchParam && Number.isFinite(areaSearchParam) && areaSearchParam > 0 ? areaSearchParam.toString() : null

    const activePageParam = useMemo(() => Number.parseInt(urlSearchParams.get('page') ?? '1', 10), [urlSearchParams])
    const activePage = useMemo(
        () => Math.max(1, Number.isFinite(activePageParam) ? activePageParam : 1),
        [activePageParam],
    )

    const tabIndex = useMemo(() => getTabIndexByPathname(location.pathname), [location.pathname])
    const dataView = tabPaths[tabIndex].dataView

    const setAreaSearchDistance = useCallback(
        (newAreaSearchDistance: string | null) => {
            if (newAreaSearchDistance === areaSearchDistance) {
                return
            }

            urlSearchParams.delete('page')
            if (newAreaSearchDistance) {
                urlSearchParams.set('distance', newAreaSearchDistance)
            } else {
                urlSearchParams.delete('distance')
            }

            void navigate(`${location.pathname}?${urlSearchParams}`)
        },
        [areaSearchDistance, location.pathname, navigate, urlSearchParams],
    )

    const setActivePage = useCallback(
        (page: number) => {
            if (page > 1) {
                urlSearchParams.set('page', page.toString())
            } else {
                urlSearchParams.delete('page')
            }

            void navigate(`${location.pathname}?${urlSearchParams.toString()}`)
        },
        [location.pathname, navigate, urlSearchParams],
    )

    const setTabIndex = useCallback(
        (tabIndex: keyof typeof tabPaths) => {
            urlSearchParams.delete('page')

            void navigate(`${tabPaths[tabIndex].path}?${urlSearchParams.toString()}`)
        },
        [navigate, urlSearchParams],
    )

    return {
        areaSearchDistance: areaSearchDistance ?? undefined,
        setAreaSearchDistance,
        activePage,
        setActivePage,
        tabIndex,
        setTabIndex,
        dataView,
    }
}
