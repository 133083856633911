/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LikertQuestion } from './LikertQuestion';
import {
    LikertQuestionFromJSON,
    LikertQuestionFromJSONTyped,
    LikertQuestionToJSON,
} from './LikertQuestion';

/**
 * 
 * @export
 * @interface FeedbackDto
 */
export interface FeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    application: FeedbackDtoApplicationEnum;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    page?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    marketplaceId: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    languageTag: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    feedbackQuestion?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    feedback: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    url: string;
    /**
     * 
     * @type {LikertQuestion}
     * @memberof FeedbackDto
     */
    likertQuestion?: LikertQuestion;
    /**
     * 
     * @type {Date}
     * @memberof FeedbackDto
     */
    timestamp: Date;
}


/**
 * @export
 */
export const FeedbackDtoApplicationEnum = {
    Sell_your_Porsche: 'Sell your Porsche'
} as const;
export type FeedbackDtoApplicationEnum = typeof FeedbackDtoApplicationEnum[keyof typeof FeedbackDtoApplicationEnum];


/**
 * Check if a given object implements the FeedbackDto interface.
 */
export function instanceOfFeedbackDto(value: object): value is FeedbackDto {
    if (!('application' in value) || value['application'] === undefined) return false;
    if (!('marketplaceId' in value) || value['marketplaceId'] === undefined) return false;
    if (!('languageTag' in value) || value['languageTag'] === undefined) return false;
    if (!('feedback' in value) || value['feedback'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function FeedbackDtoFromJSON(json: any): FeedbackDto {
    return FeedbackDtoFromJSONTyped(json, false);
}

export function FeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackDto {
    if (json == null) {
        return json;
    }
    return {
        
        'application': json['application'],
        'page': json['page'] == null ? undefined : json['page'],
        'marketplaceId': json['marketplaceId'],
        'languageTag': json['languageTag'],
        'feedbackQuestion': json['feedbackQuestion'] == null ? undefined : json['feedbackQuestion'],
        'feedback': json['feedback'],
        'url': json['url'],
        'likertQuestion': json['likertQuestion'] == null ? undefined : LikertQuestionFromJSON(json['likertQuestion']),
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function FeedbackDtoToJSON(value?: FeedbackDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'application': value['application'],
        'page': value['page'],
        'marketplaceId': value['marketplaceId'],
        'languageTag': value['languageTag'],
        'feedbackQuestion': value['feedbackQuestion'],
        'feedback': value['feedback'],
        'url': value['url'],
        'likertQuestion': LikertQuestionToJSON(value['likertQuestion']),
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

