/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptOfferResponseDto,
  CreatePurchaseRequestInputDto,
  EquipmentResponseDto,
  FeedbackInputDto,
  GetDealershipsResponseDto,
  HeadlineDataResponseDto,
  MSRPResponseDto,
  MakeOfferRequestBodyDto,
  MakeOfferRequestLegacyDto,
  ModelInformationResponseDto,
  OfferResponseDto,
  PriceEstimationFeedbackDto,
  PriceEstimationResponseDto,
  PurchaseRequestDto,
  PurchaseRequestListPage,
  VehicleSearchParametersDto,
} from '../models/index';
import {
    AcceptOfferResponseDtoFromJSON,
    AcceptOfferResponseDtoToJSON,
    CreatePurchaseRequestInputDtoFromJSON,
    CreatePurchaseRequestInputDtoToJSON,
    EquipmentResponseDtoFromJSON,
    EquipmentResponseDtoToJSON,
    FeedbackInputDtoFromJSON,
    FeedbackInputDtoToJSON,
    GetDealershipsResponseDtoFromJSON,
    GetDealershipsResponseDtoToJSON,
    HeadlineDataResponseDtoFromJSON,
    HeadlineDataResponseDtoToJSON,
    MSRPResponseDtoFromJSON,
    MSRPResponseDtoToJSON,
    MakeOfferRequestBodyDtoFromJSON,
    MakeOfferRequestBodyDtoToJSON,
    MakeOfferRequestLegacyDtoFromJSON,
    MakeOfferRequestLegacyDtoToJSON,
    ModelInformationResponseDtoFromJSON,
    ModelInformationResponseDtoToJSON,
    OfferResponseDtoFromJSON,
    OfferResponseDtoToJSON,
    PriceEstimationFeedbackDtoFromJSON,
    PriceEstimationFeedbackDtoToJSON,
    PriceEstimationResponseDtoFromJSON,
    PriceEstimationResponseDtoToJSON,
    PurchaseRequestDtoFromJSON,
    PurchaseRequestDtoToJSON,
    PurchaseRequestListPageFromJSON,
    PurchaseRequestListPageToJSON,
    VehicleSearchParametersDtoFromJSON,
    VehicleSearchParametersDtoToJSON,
} from '../models/index';

export interface AcceptRequest {
    offerId: string;
    purchaseRequestId: string;
}

export interface CreateRequest {
    createPurchaseRequestInputDto: CreatePurchaseRequestInputDto;
}

export interface GetDealershipsRequest {
    marketplace: string;
}

export interface GetEquipmentRequest {
    id: string;
    dealerEntryDn: string;
    languageTag: string;
}

export interface GetHeadlineDataRequest {
    id: string;
    dealerEntryDn: string;
}

export interface GetModelInformationRequest {
    id: string;
    languageTag: string;
    dealerEntryDn: string;
}

export interface GetOfferRequest {
    purchaseRequestId: string;
    dealerEntryDn: string;
}

export interface GetPurchaseRequestRequest {
    id: string;
    dealerEntryDn: string;
}

export interface GetVehicleMSRPRequest {
    dealerEntryDn: string;
    purchaseRequestId: string;
    languageTag: string;
}

export interface GetVehicleMarketPriceRequest {
    dealerEntryDn: string;
    purchaseRequestId: string;
}

export interface GetVehicleSearchParametersRequest {
    id: string;
    languageTag: string;
    dealerEntryDn: string;
}

export interface LegacyAcceptRequest {
    offerId: string;
}

export interface LegacyGetOfferRequest {
    dealerEntryDn: string;
    purchaseRequestId: string;
}

export interface LegacyMakeOfferRequest {
    makeOfferRequestLegacyDto: MakeOfferRequestLegacyDto;
}

export interface MakeOfferRequest {
    purchaseRequestId: string;
    makeOfferRequestBodyDto: MakeOfferRequestBodyDto;
}

export interface PostVehicleMarketPriceFeedbackRequest {
    dealerEntryDn: string;
    purchaseRequestId: string;
    priceEstimationFeedbackDto: PriceEstimationFeedbackDto;
}

export interface PurchaseRequestListPageRequest {
    distanceUnit: PurchaseRequestListPageDistanceUnitEnum;
    distance: number;
    itemsPerPage: number;
    activePage: number;
    marketplace: string;
    dealerEntryDn: string;
    filters?: Array<PurchaseRequestListPageFiltersEnum>;
}

export interface SubmitFeedbackRequest {
    feedbackInputDto: FeedbackInputDto;
}

export interface WithdrawRequest {
    id: string;
}

/**
 * 
 */
export class PurchaseRequestEndpointsApi extends runtime.BaseAPI {

    /**
     * Accepts offer of a purchase request
     */
    async acceptRaw(requestParameters: AcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcceptOfferResponseDto>> {
        if (requestParameters['offerId'] == null) {
            throw new runtime.RequiredError(
                'offerId',
                'Required parameter "offerId" was null or undefined when calling accept().'
            );
        }

        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling accept().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/purchase-request/{purchaseRequestId}/offer/{offerId}/accept`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters['offerId']))).replace(`{${"purchaseRequestId"}}`, encodeURIComponent(String(requestParameters['purchaseRequestId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcceptOfferResponseDtoFromJSON(jsonValue));
    }

    /**
     * Accepts offer of a purchase request
     */
    async accept(requestParameters: AcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcceptOfferResponseDto> {
        const response = await this.acceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new Purchase Request
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createPurchaseRequestInputDto'] == null) {
            throw new runtime.RequiredError(
                'createPurchaseRequestInputDto',
                'Required parameter "createPurchaseRequestInputDto" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/customer-app/purchase-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePurchaseRequestInputDtoToJSON(requestParameters['createPurchaseRequestInputDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a new Purchase Request
     */
    async create(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRaw(requestParameters, initOverrides);
    }

    /**
     * Get Porsche Centres of a market
     */
    async getDealershipsRaw(requestParameters: GetDealershipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDealershipsResponseDto>> {
        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling getDealerships().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/customer-app/dealerships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDealershipsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get Porsche Centres of a market
     */
    async getDealerships(requestParameters: GetDealershipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDealershipsResponseDto> {
        const response = await this.getDealershipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get equipment of purchase request vehicle
     */
    async getEquipmentRaw(requestParameters: GetEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentResponseDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEquipment().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getEquipment().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getEquipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/purchase-request/{id}/equipment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get equipment of purchase request vehicle
     */
    async getEquipment(requestParameters: GetEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentResponseDto> {
        const response = await this.getEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data to be shown in headline of purchase request detail page
     */
    async getHeadlineDataRaw(requestParameters: GetHeadlineDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeadlineDataResponseDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getHeadlineData().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getHeadlineData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/purchase-request/{id}/headline-data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeadlineDataResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get data to be shown in headline of purchase request detail page
     */
    async getHeadlineData(requestParameters: GetHeadlineDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeadlineDataResponseDto> {
        const response = await this.getHeadlineDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Model Information Details by purchase-request id
     */
    async getModelInformationRaw(requestParameters: GetModelInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelInformationResponseDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getModelInformation().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getModelInformation().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getModelInformation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/purchase-request/{id}/model-information`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelInformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get Model Information Details by purchase-request id
     */
    async getModelInformation(requestParameters: GetModelInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelInformationResponseDto> {
        const response = await this.getModelInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get offer for purchase request by dealership entryDn
     */
    async getOfferRaw(requestParameters: GetOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferResponseDto>> {
        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling getOffer().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getOffer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/purchase-request/{purchaseRequestId}/offer`.replace(`{${"purchaseRequestId"}}`, encodeURIComponent(String(requestParameters['purchaseRequestId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get offer for purchase request by dealership entryDn
     */
    async getOffer(requestParameters: GetOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferResponseDto> {
        const response = await this.getOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get purchase request by purchase-request id
     */
    async getPurchaseRequestRaw(requestParameters: GetPurchaseRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchaseRequestDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPurchaseRequest().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getPurchaseRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/purchase-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchaseRequestDtoFromJSON(jsonValue));
    }

    /**
     * Get purchase request by purchase-request id
     */
    async getPurchaseRequest(requestParameters: GetPurchaseRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchaseRequestDto> {
        const response = await this.getPurchaseRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Manufacturer\'s Suggested Retail Price (MSRP) from vehicle data service for the given purchase request\'s vehicle
     */
    async getVehicleMSRPRaw(requestParameters: GetVehicleMSRPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MSRPResponseDto>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getVehicleMSRP().'
            );
        }

        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling getVehicleMSRP().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getVehicleMSRP().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        if (requestParameters['purchaseRequestId'] != null) {
            queryParameters['purchaseRequestId'] = requestParameters['purchaseRequestId'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/price-estimation/msrp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MSRPResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get Manufacturer\'s Suggested Retail Price (MSRP) from vehicle data service for the given purchase request\'s vehicle
     */
    async getVehicleMSRP(requestParameters: GetVehicleMSRPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MSRPResponseDto> {
        const response = await this.getVehicleMSRPRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get price estimate for a vehicle in a purchase request
     */
    async getVehicleMarketPriceRaw(requestParameters: GetVehicleMarketPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PriceEstimationResponseDto>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getVehicleMarketPrice().'
            );
        }

        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling getVehicleMarketPrice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        if (requestParameters['purchaseRequestId'] != null) {
            queryParameters['purchaseRequestId'] = requestParameters['purchaseRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/price-estimation/market-price`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceEstimationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get price estimate for a vehicle in a purchase request
     */
    async getVehicleMarketPrice(requestParameters: GetVehicleMarketPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PriceEstimationResponseDto> {
        const response = await this.getVehicleMarketPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vehicle parameters to build a link to similar vehicles on other platforms
     */
    async getVehicleSearchParametersRaw(requestParameters: GetVehicleSearchParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleSearchParametersDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getVehicleSearchParameters().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getVehicleSearchParameters().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getVehicleSearchParameters().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/purchase-request/{id}/market-price-research/vehicle-search-parameters`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleSearchParametersDtoFromJSON(jsonValue));
    }

    /**
     * Get vehicle parameters to build a link to similar vehicles on other platforms
     */
    async getVehicleSearchParameters(requestParameters: GetVehicleSearchParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleSearchParametersDto> {
        const response = await this.getVehicleSearchParametersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Accepts offer of a purchase request
     */
    async legacyAcceptRaw(requestParameters: LegacyAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcceptOfferResponseDto>> {
        if (requestParameters['offerId'] == null) {
            throw new runtime.RequiredError(
                'offerId',
                'Required parameter "offerId" was null or undefined when calling legacyAccept().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/customer-app/offer/{offerId}/accept`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters['offerId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcceptOfferResponseDtoFromJSON(jsonValue));
    }

    /**
     * Accepts offer of a purchase request
     */
    async legacyAccept(requestParameters: LegacyAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcceptOfferResponseDto> {
        const response = await this.legacyAcceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get offer for purchase request by dealership entryDn
     */
    async legacyGetOfferRaw(requestParameters: LegacyGetOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfferResponseDto>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling legacyGetOffer().'
            );
        }

        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling legacyGetOffer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        if (requestParameters['purchaseRequestId'] != null) {
            queryParameters['purchaseRequestId'] = requestParameters['purchaseRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/offer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfferResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get offer for purchase request by dealership entryDn
     */
    async legacyGetOffer(requestParameters: LegacyGetOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfferResponseDto> {
        const response = await this.legacyGetOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Makes an offer for a certain purchase request
     */
    async legacyMakeOfferRaw(requestParameters: LegacyMakeOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['makeOfferRequestLegacyDto'] == null) {
            throw new runtime.RequiredError(
                'makeOfferRequestLegacyDto',
                'Required parameter "makeOfferRequestLegacyDto" was null or undefined when calling legacyMakeOffer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dealer-app/offer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakeOfferRequestLegacyDtoToJSON(requestParameters['makeOfferRequestLegacyDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Makes an offer for a certain purchase request
     */
    async legacyMakeOffer(requestParameters: LegacyMakeOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.legacyMakeOfferRaw(requestParameters, initOverrides);
    }

    /**
     * Makes an offer for a certain purchase request
     */
    async makeOfferRaw(requestParameters: MakeOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling makeOffer().'
            );
        }

        if (requestParameters['makeOfferRequestBodyDto'] == null) {
            throw new runtime.RequiredError(
                'makeOfferRequestBodyDto',
                'Required parameter "makeOfferRequestBodyDto" was null or undefined when calling makeOffer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/purchase-request/{purchaseRequestId}/offer`.replace(`{${"purchaseRequestId"}}`, encodeURIComponent(String(requestParameters['purchaseRequestId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakeOfferRequestBodyDtoToJSON(requestParameters['makeOfferRequestBodyDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Makes an offer for a certain purchase request
     */
    async makeOffer(requestParameters: MakeOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.makeOfferRaw(requestParameters, initOverrides);
    }

    /**
     * Post price estimation feedback
     */
    async postVehicleMarketPriceFeedbackRaw(requestParameters: PostVehicleMarketPriceFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling postVehicleMarketPriceFeedback().'
            );
        }

        if (requestParameters['purchaseRequestId'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequestId',
                'Required parameter "purchaseRequestId" was null or undefined when calling postVehicleMarketPriceFeedback().'
            );
        }

        if (requestParameters['priceEstimationFeedbackDto'] == null) {
            throw new runtime.RequiredError(
                'priceEstimationFeedbackDto',
                'Required parameter "priceEstimationFeedbackDto" was null or undefined when calling postVehicleMarketPriceFeedback().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        if (requestParameters['purchaseRequestId'] != null) {
            queryParameters['purchaseRequestId'] = requestParameters['purchaseRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/price-estimation/market-price/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceEstimationFeedbackDtoToJSON(requestParameters['priceEstimationFeedbackDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post price estimation feedback
     */
    async postVehicleMarketPriceFeedback(requestParameters: PostVehicleMarketPriceFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postVehicleMarketPriceFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * Get purchase request list entries
     */
    async purchaseRequestListPageRaw(requestParameters: PurchaseRequestListPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchaseRequestListPage>> {
        if (requestParameters['distanceUnit'] == null) {
            throw new runtime.RequiredError(
                'distanceUnit',
                'Required parameter "distanceUnit" was null or undefined when calling purchaseRequestListPage().'
            );
        }

        if (requestParameters['distance'] == null) {
            throw new runtime.RequiredError(
                'distance',
                'Required parameter "distance" was null or undefined when calling purchaseRequestListPage().'
            );
        }

        if (requestParameters['itemsPerPage'] == null) {
            throw new runtime.RequiredError(
                'itemsPerPage',
                'Required parameter "itemsPerPage" was null or undefined when calling purchaseRequestListPage().'
            );
        }

        if (requestParameters['activePage'] == null) {
            throw new runtime.RequiredError(
                'activePage',
                'Required parameter "activePage" was null or undefined when calling purchaseRequestListPage().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling purchaseRequestListPage().'
            );
        }

        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling purchaseRequestListPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['distanceUnit'] != null) {
            queryParameters['distanceUnit'] = requestParameters['distanceUnit'];
        }

        if (requestParameters['distance'] != null) {
            queryParameters['distance'] = requestParameters['distance'];
        }

        if (requestParameters['filters'] != null) {
            queryParameters['filters'] = requestParameters['filters'];
        }

        if (requestParameters['itemsPerPage'] != null) {
            queryParameters['itemsPerPage'] = requestParameters['itemsPerPage'];
        }

        if (requestParameters['activePage'] != null) {
            queryParameters['activePage'] = requestParameters['activePage'];
        }

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        if (requestParameters['dealerEntryDn'] != null) {
            queryParameters['dealerEntryDn'] = requestParameters['dealerEntryDn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/dealer-app/purchase-request-list-page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchaseRequestListPageFromJSON(jsonValue));
    }

    /**
     * Get purchase request list entries
     */
    async purchaseRequestListPage(requestParameters: PurchaseRequestListPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchaseRequestListPage> {
        const response = await this.purchaseRequestListPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit Feedback via Slack to dealer-feedback-channel
     */
    async submitFeedbackRaw(requestParameters: SubmitFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['feedbackInputDto'] == null) {
            throw new runtime.RequiredError(
                'feedbackInputDto',
                'Required parameter "feedbackInputDto" was null or undefined when calling submitFeedback().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/dealer-app/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackInputDtoToJSON(requestParameters['feedbackInputDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit Feedback via Slack to dealer-feedback-channel
     */
    async submitFeedback(requestParameters: SubmitFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * Withdraw an existing purchase request
     */
    async withdrawRaw(requestParameters: WithdrawRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling withdraw().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/customer-app/purchase-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Withdraw an existing purchase request
     */
    async withdraw(requestParameters: WithdrawRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.withdrawRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const PurchaseRequestListPageDistanceUnitEnum = {
    KM: 'KM',
    SMI: 'SMI'
} as const;
export type PurchaseRequestListPageDistanceUnitEnum = typeof PurchaseRequestListPageDistanceUnitEnum[keyof typeof PurchaseRequestListPageDistanceUnitEnum];
/**
 * @export
 */
export const PurchaseRequestListPageFiltersEnum = {
    HIDDEN_ONLY: 'HIDDEN_ONLY',
    WITH_OWN_OFFERS: 'WITH_OWN_OFFERS'
} as const;
export type PurchaseRequestListPageFiltersEnum = typeof PurchaseRequestListPageFiltersEnum[keyof typeof PurchaseRequestListPageFiltersEnum];
