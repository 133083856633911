/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PriceEstimationPriceDto } from './PriceEstimationPriceDto';
import {
    PriceEstimationPriceDtoFromJSON,
    PriceEstimationPriceDtoFromJSONTyped,
    PriceEstimationPriceDtoToJSON,
} from './PriceEstimationPriceDto';

/**
 * 
 * @export
 * @interface PriceEstimationResponseDto
 */
export interface PriceEstimationResponseDto {
    /**
     * price trend of the last 30 days
     * @type {PriceEstimationPriceDto}
     * @memberof PriceEstimationResponseDto
     */
    trend30days: PriceEstimationPriceDto;
    /**
     * Warnings about the price estimation from our external service provider, e.g. 'bad car age, should be between 8 and 44'
     * @type {Array<string>}
     * @memberof PriceEstimationResponseDto
     */
    warnings: Array<string>;
}

/**
 * Check if a given object implements the PriceEstimationResponseDto interface.
 */
export function instanceOfPriceEstimationResponseDto(value: object): value is PriceEstimationResponseDto {
    if (!('trend30days' in value) || value['trend30days'] === undefined) return false;
    if (!('warnings' in value) || value['warnings'] === undefined) return false;
    return true;
}

export function PriceEstimationResponseDtoFromJSON(json: any): PriceEstimationResponseDto {
    return PriceEstimationResponseDtoFromJSONTyped(json, false);
}

export function PriceEstimationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceEstimationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'trend30days': PriceEstimationPriceDtoFromJSON(json['trend30days']),
        'warnings': json['warnings'],
    };
}

export function PriceEstimationResponseDtoToJSON(value?: PriceEstimationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'trend30days': PriceEstimationPriceDtoToJSON(value['trend30days']),
        'warnings': value['warnings'],
    };
}

