import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'

import type { AppProps } from './app/app'
import { MatomoContext } from './app/context/matomoContext'
import { Translations } from './app/hooks/useTranslations'
import { defaultUiQueryClientConfig } from './default-ui-query-client-config'
import { defaultMockContext } from './mockContext'

const defaultQueryClient = new QueryClient(defaultUiQueryClientConfig)

type DealerFrontendProviderProps = AppProps & {
    messages?: Translations
    queryClient?: QueryClient
}
type ErrorParam = undefined | { message?: string; descriptor?: { id?: string } }
export const DealerFrontendProvider = ({
    children,
    context,
    messages,
    queryClient = defaultQueryClient,
    trackAnalyticsEvent,
    trackAnalyticsPageView,
}: PropsWithChildren<DealerFrontendProviderProps>) => {
    const onError = useCallback((error: ErrorParam) => {
        console.debug(`Missing message/s: ${error?.descriptor?.id ?? error?.message ?? 'undef'}`)
    }, [])

    const [pageState, setPageState] = useState<Record<number, number>>({})

    const setTabPageState = useCallback((tabId: number, pageNumber: number) => {
        setPageState((current) => ({ ...current, [tabId]: pageNumber }))
    }, [])

    return (
        <MatomoContext.Provider value={{ trackEvent: trackAnalyticsEvent, trackPageView: trackAnalyticsPageView }}>
            <QueryClientProvider client={queryClient}>
                <IntlProvider locale={context.locale} messages={messages} onError={onError}>
                    <PorscheDesignSystemProvider prefix={'syv'}>
                        <DealerFrontendContext.Provider
                            value={{
                                pageState: pageState,
                                setTabPageState,
                                dealerContext: context,
                            }}
                        >
                            {children}
                        </DealerFrontendContext.Provider>
                    </PorscheDesignSystemProvider>
                </IntlProvider>
                {/* Add React Query Devtools below to debug requests */}
                {/*<ReactQueryDevtools initialIsOpen={true} />*/}
            </QueryClientProvider>
        </MatomoContext.Provider>
    )
}

export type DealerFrontendContextType = {
    pageState: Record<number, number>
    setTabPageState: (tabId: number, pageNumber: number) => void
    dealerContext: AppProps['context']
}

const DealerFrontendContext = createContext<DealerFrontendContextType>({
    pageState: {},
    setTabPageState: () => {},
    dealerContext: defaultMockContext,
})

export const useDealerFrontendContext = () => {
    return useContext(DealerFrontendContext)
}
