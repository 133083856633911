/**
 * Helper function to scroll an element into view
 */
export const scrollToElement = (id: string) => {
    const element = document.getElementById(id)
    if (!element) return
    window.scrollTo({
        top: element?.offsetTop - 100,
        behavior: 'smooth',
    })
}
