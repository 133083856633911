/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelDetailsDto
 */
export interface ModelDetailsDto {
    /**
     * Model series
     * @type {string}
     * @memberof ModelDetailsDto
     */
    modelSeries: string;
    /**
     * Model generation
     * @type {string}
     * @memberof ModelDetailsDto
     */
    modelGeneration: string;
    /**
     * Model derivative
     * @type {string}
     * @memberof ModelDetailsDto
     */
    derivative: string;
}

/**
 * Check if a given object implements the ModelDetailsDto interface.
 */
export function instanceOfModelDetailsDto(value: object): value is ModelDetailsDto {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    if (!('modelGeneration' in value) || value['modelGeneration'] === undefined) return false;
    if (!('derivative' in value) || value['derivative'] === undefined) return false;
    return true;
}

export function ModelDetailsDtoFromJSON(json: any): ModelDetailsDto {
    return ModelDetailsDtoFromJSONTyped(json, false);
}

export function ModelDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': json['modelSeries'],
        'modelGeneration': json['modelGeneration'],
        'derivative': json['derivative'],
    };
}

export function ModelDetailsDtoToJSON(value?: ModelDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modelSeries': value['modelSeries'],
        'modelGeneration': value['modelGeneration'],
        'derivative': value['derivative'],
    };
}

