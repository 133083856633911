import { PButton, PButtonGroup, PTextFieldWrapper } from '@porsche-design-system/components-react'
import { KeyboardEvent, SyntheticEvent, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import type { AppProps } from '~/app/app'
import { SpacingStaticLarge, SpacingStaticMedium } from '~/app/base-components/styled/Spacer'
import { TextSmall } from '~/app/base-components/styled/Text'

import { messages } from './messages'

export interface MakeOfferViewProps {
    sendOffer: (offerPrice: string) => Promise<void>
    cancel: () => void
    currency: AppProps['context']['currency']
}

const isNotANumber = (userInput: string) => !/^[1-9][0-9]*$/.test(userInput)
export const MakeOfferView = ({ sendOffer, cancel, currency }: MakeOfferViewProps) => {
    const intl = useIntl()

    const currencySymbol = useMemo(
        () =>
            intl
                .formatNumberToParts(1, {
                    style: 'currency',
                    currency,
                })
                .find((x) => x.type === 'currency')?.value,
        [currency, intl],
    )

    const [offerPrice, setOfferPrice] = useState<string>('')
    const [offerPriceError, setOfferPriceError] = useState<boolean>(false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async () => {
        setIsSubmitting(true)
        // Since submit button is only active if valid offer price has been entered, it's safe to assume the offer price is set by now
        if (offerPrice) {
            await sendOffer(offerPrice)
        }
        setIsSubmitting(false)
    }

    const updateOfferPrice = (e: SyntheticEvent<HTMLInputElement>) => {
        const priceInput = e.currentTarget.value
        setOfferPriceError(isNotANumber(priceInput))
        setOfferPrice(priceInput)
    }

    const onlyAllowNaturalNumbers = (e: KeyboardEvent<HTMLInputElement>) => {
        if (['.', ',', '-', 'e'].includes(e.key)) {
            e.preventDefault()
        }
    }

    return (
        <>
            <TextSmall>{intl.formatMessage(messages.makeOfferShortExplainer)}</TextSmall>
            <SpacingStaticMedium />
            <PTextFieldWrapper
                label={intl.formatMessage(messages.priceInputLabel)}
                unit={currencySymbol}
                unitPosition={'suffix'}
                state={offerPriceError ? 'error' : 'none'}
                message={intl.formatMessage(messages.priceInputError)}
            >
                <input
                    value={offerPrice}
                    min={'0'}
                    onChange={updateOfferPrice}
                    onPaste={updateOfferPrice}
                    onKeyDown={onlyAllowNaturalNumbers}
                    required={true}
                    type="number"
                    inputMode="numeric"
                    step={1000}
                />
            </PTextFieldWrapper>
            <SpacingStaticLarge />
            <PButtonGroup>
                <PButton
                    onClick={() => {
                        void onSubmit()
                    }}
                    disabled={!offerPrice || offerPriceError}
                    loading={isSubmitting}
                >
                    {intl.formatMessage(messages.sendOfferButtonLabel)}
                </PButton>
                <PButton onClick={cancel} variant={'secondary'} disabled={isSubmitting}>
                    {intl.formatMessage(messages.cancelButtonLabel)}
                </PButton>
            </PButtonGroup>
        </>
    )
}
