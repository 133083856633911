import { useQuery } from '@tanstack/react-query'
import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

export interface OfferStatusDataProps {
    purchaseRequestId: string
    dealerEntryDn: string
}

export function getOfferStatusDataKey(id: string, dealerEntryDn: string) {
    return ['offerStatusData', id, dealerEntryDn]
}

export const useOfferStatusDataQuery = ({ purchaseRequestId, dealerEntryDn }: OfferStatusDataProps) =>
    useQuery({
        queryKey: getOfferStatusDataKey(purchaseRequestId, dealerEntryDn),
        queryFn: () =>
            purchaseRequestDealerAppEndpointsApi.getOffer({
                purchaseRequestId,
                dealerEntryDn,
            }),
    })
