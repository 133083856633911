import { PPopover, PTag, TagColor, TagIcon } from '@porsche-design-system/components-react'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface PTagWithPopoverProps extends PropsWithChildren {
    color: TagColor
    icon: TagIcon
    infoText: string
}

export const PTagWithPopover = (props: PTagWithPopoverProps) => {
    return (
        <CenterVertically>
            <PTag color={props.color} icon={props.icon}>
                {props.children}
            </PTag>
            <StyledPopover direction={'right'} aria-label={props.infoText}>
                <span role={'popover'}>{props.infoText}</span>
            </StyledPopover>
        </CenterVertically>
    )
}

const StyledPopover = styled(PPopover)`
    /* Additional span to support text wrapping. Otherwise, within the table, the popover will be one-line only and text will extend outside its bounds. */

    & span[role='popover'] {
        text-wrap: balance;
    }
`

const CenterVertically = styled.div`
    align-items: center;
    display: flex;
`
