import { useContext } from 'react'

import { MatomoContext } from '~/app/context/matomoContext'

export const useMatomo = () => {
    const { trackEvent, trackPageView } = useContext(MatomoContext)
    return {
        trackEvent: trackEvent ?? (() => console.error('trackEvent not available')),
        trackPageView: trackPageView ?? (() => console.error('trackPageView not available')),
    }
}
