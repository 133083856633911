import { useToastManager } from '@porsche-design-system/components-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dealershipSettingsDealerAppEndpointsApi } from 'dealer-prs-api-client'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { useDealerFrontendContext } from '~/dealerFrontendProvider'

import { getHeadlineDataQueryKey } from './queries/useHeadlineDataQuery'
import { PURCHASE_REQUEST_LIST_QUERY_KEY } from './queries/usePurchaseRequestListQuery'

interface MutationData {
    purchaseRequestId: string
    hidden: boolean
}

export function usePurchaseRequestVisibility() {
    const queryClient = useQueryClient()

    // For messages to be visible, <PToast /> must be somewhere on page
    const { addMessage } = useToastManager()
    const intl = useIntl()

    const {
        dealerContext: { dealershipId: dealerEntryDn, marketplaceKey: marketplace },
    } = useDealerFrontendContext()

    const { mutateAsync, isPending } = useMutation({
        mutationFn: (data: MutationData) => {
            return dealershipSettingsDealerAppEndpointsApi.patchPurchaseRequestVisibilitySettings({
                dealerEntryDn,
                marketplace,
                purchaseRequestId: data.purchaseRequestId,
                dealershipSettingsPurchaseRequestVisibilityRequestDto: {
                    hidden: data.hidden,
                },
            })
        },
        onSuccess: (_, variables: MutationData) => {
            void queryClient.invalidateQueries({
                queryKey: getHeadlineDataQueryKey(variables.purchaseRequestId, dealerEntryDn),
            })
            // The following call invalidates queries for all purchase request list pages, since their query key starts with PURCHASE_REQUEST_LIST_QUERY_KEY
            void queryClient.invalidateQueries({ queryKey: [PURCHASE_REQUEST_LIST_QUERY_KEY] })

            if (variables.hidden) {
                addMessage({ text: intl.formatMessage(messages.hideSuccess), state: 'success' })
            } else {
                addMessage({ text: intl.formatMessage(messages.showSuccess), state: 'success' })
            }
        },
        onError: () => {
            addMessage({ text: intl.formatMessage(messages.visibilityUpdateError), state: 'info' })
        },
    })

    return useMemo(
        () => ({
            mutatePurchaseRequestVisibility: mutateAsync,
            mutatePurchaseRequestVisibilityIsPending: isPending,
        }),
        [isPending, mutateAsync],
    )
}

export const messages = defineMessages({
    hideSuccess: {
        id: 'hideButton.update.hide.success',

        defaultMessage: "The request is now hidden. You can find it in the 'Hidden requests' list.",
    },
    showSuccess: {
        id: 'hideButton.update.show.success',

        defaultMessage: "The request is now visible. You can find it in the 'All requests' list.",
    },
    visibilityUpdateError: {
        id: 'hideButton.update.errorMessage',
        defaultMessage: 'Failed to update visibility status.',
    },
})
