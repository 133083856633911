import { PSelect, PSelectOption, PTagDismissible } from '@porsche-design-system/components-react'
import { spacingFluidSmall } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { SpacingFluidSmall } from '~/app/base-components/styled/Spacer'
import { useMarketConfig } from '~/app/hooks/useMarketConfig'
import { formatDistance } from '~/app/utils/FormatDistance'

import { messages } from './messages'

interface TableFiltersViewProps {
    areaSearchDistance: string | undefined
    onUpdateAreaSearchSelectOption: (distance: string) => void
    onDismissAreaSearch: () => void
    distanceUnit: 'KM' | 'SMI'
}

export const TableFiltersView = ({
    areaSearchDistance,
    onUpdateAreaSearchSelectOption,
    onDismissAreaSearch,
    distanceUnit,
}: TableFiltersViewProps) => {
    const intl = useIntl()

    return (
        <>
            <StyledAreaSelectWrapper>
                <PSelect
                    name="distance"
                    label={intl.formatMessage(messages.areaSearchFilterLabel)}
                    value={areaSearchDistance?.toString()}
                    onUpdate={(event) => onUpdateAreaSearchSelectOption(event.detail.value)}
                    // Using data-testid because react-testing-library can't select by role and label from shadow dom
                    data-testid="area-search-filter-select"
                >
                    <AreaSearchSelectOptions />
                </PSelect>
            </StyledAreaSelectWrapper>
            <SpacingFluidSmall />
            <FilterTags>
                {areaSearchDistance && (
                    <PTagDismissible
                        label={intl.formatMessage(messages.areaSearchFilterLabel)}
                        onClick={onDismissAreaSearch}
                        // Using data-testid because react-testing-library can't select by role and label from shadow dom
                        data-testid="dismiss-area-search-filter-tag"
                    >
                        {formatDistance(areaSearchDistance, distanceUnit, intl)}
                    </PTagDismissible>
                )}
            </FilterTags>
        </>
    )
}

const StyledAreaSelectWrapper = styled.div`
    width: 11rem;
`

const FilterTags = styled.div`
    display: flex;
    gap: ${spacingFluidSmall};
`

const AreaSearchOptions = [50, 100, 200, 500]

const AreaSearchSelectOptions = () => {
    const intl = useIntl()
    const { distanceUnit } = useMarketConfig()

    return (
        <>
            {AreaSearchOptions.map((areaSearchOption) => (
                <PSelectOption key={`area-search-filter-select-${areaSearchOption}`} value={`${areaSearchOption}`}>
                    {formatDistance(areaSearchOption, distanceUnit, intl)}
                </PSelectOption>
            ))}

            <PSelectOption key={'area-search-filter-select-not-set'} value={undefined}>
                {intl.formatMessage(messages.areaSearchFilterNationwideOption)}
            </PSelectOption>
        </>
    )
}
