import { StatusCodes } from 'http-status-codes'
import { useEffect, useState } from 'react'

import type { AppProps } from '~/app/app'

import { VITE_TRANSLATIONS_BASEURL } from './viteMetaEnv'

export type Translations = Record<string, string>

/**
 * undefined -> ['en'], 'it-CH' -> ['it-CH', 'it'], 'it' -> ['it']
 * @param locale locale like 'it-CH'
 */
const buildFallbackSequence = (locale: AppProps['context']['locale']): string[] => {
    // "it-CH" -> ['it-CH', 'it']
    // "it" -> ['it']
    return locale.includes('-') ? [locale, locale.split('-')[0]] : [locale]
}

const isFlatJsonObject = (obj: unknown): boolean => {
    if (typeof obj !== 'object' || obj === null) {
        return false
    }

    return (
        Object.keys(obj).filter((key) => {
            const o = (obj as Record<string, unknown>)[key]
            return typeof o !== 'string' && o !== null
        }).length === 0
    )
}

/**
 * Adapted from contactForm. Skipping additional cache to KISS.
 * FIXME: We don't handle partial translations yet https://jira.porsche.codes/browse/SOC-2283
 */
const resolveTranslations = async (locale: string): Promise<Translations | undefined> => {
    // This "variable" will be string-replaced at build-time
    const translationPathPrefix = VITE_TRANSLATIONS_BASEURL
    if (!translationPathPrefix || translationPathPrefix.startsWith('import')) {
        throw Error('Missing vite-env variable VITE_TRANSLATIONS_BASEURL')
    }
    const url: string = `${translationPathPrefix}/i18n/${locale}.json`

    const response = await fetch(url)

    if (
        response.status >= StatusCodes.OK.valueOf() &&
        response.status < StatusCodes.MULTIPLE_CHOICES.valueOf() &&
        response.headers.get('content-type') === 'application/json'
    ) {
        const untypedJSON: unknown = await response.json()
        if (isFlatJsonObject(untypedJSON)) {
            return untypedJSON as Translations
        } else {
            console.error(`Failed to parse locale "${locale}"`)
        }
    }
    return undefined
}

// exported to be used in a storybook
export const loadTranslations = async (locale: AppProps['context']['locale']): Promise<Translations> => {
    // like ['it-CH', 'it']
    const sequence = buildFallbackSequence(locale)

    // ['it-CH', 'it'] -> using 'it-CH.json' if it exists or falling back to 'it'
    for (const item of sequence) {
        const translations = await resolveTranslations(item)
        if (translations !== undefined) {
            return translations
        }
    }

    const translations = await resolveTranslations('en')
    return (
        translations ?? {
            __error: `Failed to load 'en'-locale as fallback for locale ${locale}, using default strings.`,
        }
    )
}

export const useTranslations = (locale: AppProps['context']['locale']): { messages: Translations | undefined } => {
    const [messages, setMessages] = useState<Translations | undefined>(undefined)

    useEffect(() => {
        loadTranslations(locale)
            .then((translations) => {
                setMessages(translations)
            })
            .catch((e) => {
                console.error(e)
            })
    }, [locale])

    return { messages }
}
