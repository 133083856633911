import ReactDOM from 'react-dom'

import { App, AppProps } from './app/app'

declare const VERSION: string
declare const COMMITHASH: string
declare const BRANCH: string
declare const LAST_COMMIT_DATETIME: string

// FIXME: Currently casted to any because our consuming frontend does not support React 19 yet. Once it does, we can remove this cast.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
const ReactAnyDOM = ReactDOM as any

// eslint-disable-next-line @typescript-eslint/require-await
async function systemJsMount(container: HTMLElement) {
    console.log(`SYV Dealer Frontend '${VERSION}' '${COMMITHASH}' '${BRANCH}' '${LAST_COMMIT_DATETIME}'`)
    return {
        mount(props: AppProps) {
            // Note:
            // We are using the react-dom provided by the dealer-app (host application).Tt does not provide createRoot.
            // This is a workaround suggested b the Transformers team
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            ReactAnyDOM.render(
                <App
                    context={props.context}
                    trackAnalyticsEvent={props.trackAnalyticsEvent}
                    trackAnalyticsPageView={props.trackAnalyticsPageView}
                />,
                container,
            )
        },
        unmount() {
            // Note:
            // We are using the react-dom provided by the dealer-app (host application).Tt does not provide createRoot.
            // This is a workaround suggested b the Transformers team
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            ReactAnyDOM.unmountComponentAtNode(container)
        },
    }
}

export default systemJsMount
