import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    heading: {
        id: 'page.details.additionalInformation.heading',
        defaultMessage: 'Additional information',
    },
    tradeInInterestLabel: {
        id: 'page.details.additionalInformation.label.tradeInInterest',
        defaultMessage: 'Trade-in interest',
    },
    customerCommentLabel: {
        id: 'page.details.additionalInformation.label.customerComment',
        defaultMessage: 'Customer comment',
    },
    tradeInInterestYes: {
        id: 'page.details.additionalInformation.tradeInInterest.yes',
        defaultMessage: 'Yes',
    },
    tradeInInterestNo: {
        id: 'page.details.additionalInformation.tradeInInterest.no',
        defaultMessage: 'No',
    },
    tradeInInterestNotAvailable: {
        id: 'page.details.additionalInformation.tradeInInterest.notAvailable',
        defaultMessage: '-',
    },
})
