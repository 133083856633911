import { themeLightBackgroundSurface } from '@porsche-design-system/components-react/styles'
import {
    dealershipSettingsDealerAppEndpointsApi,
    purchaseRequestDealerAppEndpointsApi,
    PurchaseRequestListPageDistanceUnitEnum,
} from 'dealer-prs-api-client'
import { ReactElement, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Outlet, OutletProps } from 'react-router'
import styled from 'styled-components'

import { SpacingFluidMedium } from '~/app/base-components/styled/Spacer'
import { DealerFeedbackForm } from '~/app/components/feedback/DealerFeedbackForm'
import { WideTopLevelContent } from '~/app/components/layout/WideTopLevelContent'
import { NotificationSettingsButton } from '~/app/components/notification-settings-button/NotificationSettingsButton'
import {
    PurchaseRequestSearchFilters,
    PurchaseRequestTableContext,
    usePurchaseRequestTableContext,
} from '~/app/components/table/PurchaseRequestTableContext'
import { PurchaseRequestTableLayout } from '~/app/components/table/PurchaseRequestTableLayout'
import { TableFiltersView } from '~/app/components/table-filters/TableFiltersView'
import { PageTitle } from '~/app/components/text'
import { MatomoCategory, MatomoPageId } from '~/app/context/matomoContext'
import { useMarketConfig } from '~/app/hooks/useMarketConfig'
import { useMatomo } from '~/app/hooks/useMatomo'
import { useRouterState } from '~/app/hooks/useRouterState'
import { useDealerFrontendContext } from '~/dealerFrontendProvider'

import { messages } from './messages'

export const PurchaseRequestOverviewPage = ({
    OutletComponent = Outlet,
}: {
    OutletComponent?: (props: OutletProps) => ReactElement | null
}) => {
    const intl = useIntl()

    const { getDealershipSettings } = usePurchaseRequestTableContext()

    const { trackEvent } = useMatomo()

    const { distanceUnit } = useMarketConfig()

    const { areaSearchDistance, setAreaSearchDistance } = useRouterState()

    const onUpdateAreaSearchSelectOption = (distance: string) => {
        trackEvent({
            category: MatomoCategory.OVERVIEW,
            action: 'set-area-search-filter',
            name: `syp-area-search-filter-${distance ?? 'nationwide'}`,
        })
        setAreaSearchDistance(distance)
        storeAreaSearchDistance(distance)
    }
    const onDismissAreaSearch = () => {
        trackEvent({
            category: MatomoCategory.OVERVIEW,
            action: 'dismiss-area-search-filter',
            name: `syp-area-search-filter-${areaSearchDistance ?? 'nationwide'}`,
        })

        setAreaSearchDistance(null)
        storeAreaSearchDistance()
    }

    return (
        <>
            <WideTopLevelContent>
                <PageTitle headingComponent={intl.formatMessage(messages.purchaseRequests)}>
                    <NotificationSettingsButton getDealershipSettings={getDealershipSettings} />
                </PageTitle>
                <TableFiltersView
                    distanceUnit={distanceUnit}
                    onUpdateAreaSearchSelectOption={onUpdateAreaSearchSelectOption}
                    onDismissAreaSearch={onDismissAreaSearch}
                    areaSearchDistance={areaSearchDistance}
                />
                <SpacingFluidMedium />
                <PurchaseRequestTableLayout>
                    {/* Render PurchaseRequestTableContainer (defined via React router dom routes), see: https://reactrouter.com/en/main/components/outlet */}
                    <OutletComponent />
                </PurchaseRequestTableLayout>
                <SpacingFluidMedium />
            </WideTopLevelContent>
            <BackgroundSurface>
                <WideTopLevelContent>
                    <DealerFeedbackForm page={'overview'} />
                </WideTopLevelContent>
            </BackgroundSurface>
        </>
    )
}

const BackgroundSurface = styled.div`
    background: ${themeLightBackgroundSurface};
`

export const PurchaseRequestOverviewPageContainer = () => {
    const {
        dealerContext: { marketplaceKey: marketplace, dealershipId: dealerEntryDn },
    } = useDealerFrontendContext()
    const { trackPageView } = useMatomo()

    const { setAreaSearchDistance, areaSearchDistance } = useRouterState()

    useEffect(() => {
        if (areaSearchDistance) {
            return
        }

        const localAreaSearchDistance = localStorage.getItem(areaSearchDistanceLocalStorageKey)

        setAreaSearchDistance(localAreaSearchDistance)
    }, [areaSearchDistance, setAreaSearchDistance])

    useEffect(() => {
        trackPageView(MatomoPageId.OVERVIEW_PAGE)
    }, [trackPageView])

    const applyDistanceFilter = useCallback((searchFilters: PurchaseRequestSearchFilters) => {
        return searchFilters.areaSearchDistance
            ? Number(searchFilters.areaSearchDistance)
            : areaSearchDistanceNationwide
    }, [])

    const getPurchaseRequestsCall = useCallback(
        (activePage: number, itemsPerPage: number, searchFilters: PurchaseRequestSearchFilters) => () => {
            return purchaseRequestDealerAppEndpointsApi.purchaseRequestListPage({
                activePage,
                dealerEntryDn,

                distance: applyDistanceFilter(searchFilters),
                distanceUnit: PurchaseRequestListPageDistanceUnitEnum.KM,

                filters: searchFilters.dataView ? [searchFilters.dataView] : undefined,
                itemsPerPage,
                marketplace,
            })
        },
        [applyDistanceFilter, dealerEntryDn, marketplace],
    )

    const getDealershipSettings = useCallback(
        () =>
            dealershipSettingsDealerAppEndpointsApi.getDealershipSettings({
                dealerEntryDn,
                marketplace,
            }),
        [dealerEntryDn, marketplace],
    )

    return (
        <PurchaseRequestTableContext.Provider
            value={{
                getPurchaseRequestsCall,
                getDealershipSettings,
            }}
        >
            <PurchaseRequestOverviewPage />
        </PurchaseRequestTableContext.Provider>
    )
}

/**
 * Our default safeguard value in the BACKEND is Machu Picchu base in Northern Antarctica. I.e. by default, when the BACKEND
 * does not set a location for a purchase request, the location is set to roughly that point in Northern Antarctica.
 *
 * Therefore, we set the search distance sufficiently high to include our default location even from
 * our current markets (DE, FR & GB) from the Northern Hemisphere.
 */
export const areaSearchDistanceNationwide = 25000

/**
 * Remember area search filter so dealer doesn't have to set it again when re-opening the browser
 */
export const areaSearchDistanceLocalStorageKey = 'purchaseRequestAreaSearchDistance'
const storeAreaSearchDistance = (distance?: string) => {
    if (distance) {
        localStorage.setItem(areaSearchDistanceLocalStorageKey, distance)
    } else {
        localStorage.removeItem(areaSearchDistanceLocalStorageKey)
    }
}

export default PurchaseRequestOverviewPageContainer
