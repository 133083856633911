import { PTableHeadCell, TableHeadCellSort } from '@porsche-design-system/components-react'
import { IntlShape } from 'react-intl'

import { messages } from './messages'

type ExtractId<T> = T extends { id: string } & Record<string, unknown> ? T['id'] : never

export type PurchaseRequestTableHeaderCellId = ExtractId<
    ReturnType<typeof createPurchaseRequestTableHeaderCells>[number]
>

/**
 * Generate header cells for PurchaseRequestTable as list dynamically,
 * so we can map them in both PurchaseRequestTable and PurchaseRequestTableSkeleton
 */
export const createPurchaseRequestTableHeaderCells = ({ intl }: { intl: IntlShape }) => {
    return (
        [
            {
                id: 'requestDate',
                cell: (
                    <PTableHeadCell key={'requestDate'} sort={sortFirst}>
                        {intl.formatMessage(messages.headerRequestDate)}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'model',
                cell: <PTableHeadCell key={'model'}>{intl.formatMessage(messages.headerModel)}</PTableHeadCell>,
            },
            {
                id: 'purchaseRequestStatus',
                cell: (
                    <PTableHeadCell key={'purchaseRequestStatus'}>
                        {intl.formatMessage(messages.headerPurchaseRequestStatus)}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'offerCount',
                cell: (
                    <PTableHeadCell key={'offerCount'}>
                        {intl.formatMessage(messages.headerOfferCount)}
                        <br />
                        {`(${intl.formatMessage(messages.headerRecipients)})`}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'mileage',
                cell: (
                    <PTableHeadCell key={'mileage'}>
                        {intl.formatMessage(messages.headerMileage)}
                        <br />
                        {intl.formatMessage(messages.headerFirstRegistration)}&nbsp;
                        {`(${intl.formatMessage(messages.headerPreviousOwners)})`}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'distance',
                cell: (
                    <PTableHeadCell key={'distance'}>
                        {intl.formatMessage(messages.headerDistance)}
                        <br />
                        {intl.formatMessage(messages.headerLocation)}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'accidentFree',
                cell: (
                    <PTableHeadCell key={'accidentFree'}>
                        {intl.formatMessage(messages.headerAccidentFree)}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'tradeInInterest',
                cell: (
                    <PTableHeadCell key={'tradeInInterest'}>
                        {intl.formatMessage(messages.headerTradeInInterest)}
                    </PTableHeadCell>
                ),
            },
            {
                id: 'action',
                cell: <PTableHeadCell key={'action'}>{intl.formatMessage(messages.headerAction)}</PTableHeadCell>,
            },
        ] as const
    ).filter((row) => row !== null)
}

export const sortFirst: TableHeadCellSort = {
    id: '0',
    active: true,
    direction: 'desc',
}
