/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DealerDetailsDto } from './DealerDetailsDto';
import {
    DealerDetailsDtoFromJSON,
    DealerDetailsDtoFromJSONTyped,
    DealerDetailsDtoToJSON,
} from './DealerDetailsDto';
import type { OfferPriceDto } from './OfferPriceDto';
import {
    OfferPriceDtoFromJSON,
    OfferPriceDtoFromJSONTyped,
    OfferPriceDtoToJSON,
} from './OfferPriceDto';

/**
 * 
 * @export
 * @interface AcceptOfferResponseDto
 */
export interface AcceptOfferResponseDto {
    /**
     * Status of offer acceptance
     * @type {string}
     * @memberof AcceptOfferResponseDto
     */
    status: AcceptOfferResponseDtoStatusEnum;
    /**
     * Details of the dealership
     * @type {DealerDetailsDto}
     * @memberof AcceptOfferResponseDto
     */
    dealerDetails: DealerDetailsDto;
    /**
     * Price of the offer
     * @type {OfferPriceDto}
     * @memberof AcceptOfferResponseDto
     */
    price?: OfferPriceDto;
    /**
     * Date of offer
     * @type {Date}
     * @memberof AcceptOfferResponseDto
     */
    offerDate?: Date;
    /**
     * Vehicle description
     * @type {string}
     * @memberof AcceptOfferResponseDto
     */
    vehicle?: string;
}


/**
 * @export
 */
export const AcceptOfferResponseDtoStatusEnum = {
    ACCEPTED: 'ACCEPTED',
    ALREADY_ACCEPTED: 'ALREADY_ACCEPTED',
    ALREADY_EXPIRED: 'ALREADY_EXPIRED'
} as const;
export type AcceptOfferResponseDtoStatusEnum = typeof AcceptOfferResponseDtoStatusEnum[keyof typeof AcceptOfferResponseDtoStatusEnum];


/**
 * Check if a given object implements the AcceptOfferResponseDto interface.
 */
export function instanceOfAcceptOfferResponseDto(value: object): value is AcceptOfferResponseDto {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('dealerDetails' in value) || value['dealerDetails'] === undefined) return false;
    return true;
}

export function AcceptOfferResponseDtoFromJSON(json: any): AcceptOfferResponseDto {
    return AcceptOfferResponseDtoFromJSONTyped(json, false);
}

export function AcceptOfferResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptOfferResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'dealerDetails': DealerDetailsDtoFromJSON(json['dealerDetails']),
        'price': json['price'] == null ? undefined : OfferPriceDtoFromJSON(json['price']),
        'offerDate': json['offerDate'] == null ? undefined : (new Date(json['offerDate'])),
        'vehicle': json['vehicle'] == null ? undefined : json['vehicle'],
    };
}

export function AcceptOfferResponseDtoToJSON(value?: AcceptOfferResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'dealerDetails': DealerDetailsDtoToJSON(value['dealerDetails']),
        'price': OfferPriceDtoToJSON(value['price']),
        'offerDate': value['offerDate'] == null ? undefined : ((value['offerDate']).toISOString().substring(0,10)),
        'vehicle': value['vehicle'],
    };
}

