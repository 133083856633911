import { Theme } from '@porsche-design-system/components-react'
import {
    getMediaQueryMax,
    getMediaQueryMin,
    gridNarrowColumnEnd,
    gridNarrowColumnStart,
    gridStyle,
    spacingFluidLarge,
    spacingFluidMedium,
    spacingFluidSmall,
    spacingFluidXLarge,
    spacingFluidXXLarge,
    themeDarkBackgroundBase,
    themeDarkPrimary,
} from '@porsche-design-system/components-react/styles'
import styled, { CSSObject } from 'styled-components'

const backgroundByTheme: { [K in Theme]: CSSObject } = {
    dark: { backgroundColor: themeDarkBackgroundBase, color: themeDarkPrimary },
    light: {},
    auto: {},
}
export const LayoutWithBackground = styled.div<{ $theme: Theme }>(({ $theme }) => ({
    [getMediaQueryMax('m')]: {
        ...gridStyle,
    },
    ...backgroundByTheme[$theme],
}))

export const LikertQuestion = styled.div`
    padding-top: ${spacingFluidMedium};
`

export const FeedbackSubmitButtonSpacing = styled.div`
    padding-top: ${spacingFluidLarge};
    padding-bottom: ${spacingFluidXLarge};
    ${getMediaQueryMin('m')} {
        padding-top: ${spacingFluidMedium};
        padding-bottom: ${spacingFluidMedium};
    }
`

export const FeedbackTextarea = styled.textarea`
    ${getMediaQueryMin('m')} {
        // about four rows of text
        min-height: 144px;
    }
    // about four rows of text
    min-height: 120px;
`

export const SegmentControlSpacing = styled.div`
    padding-top: ${spacingFluidSmall};
    padding-bottom: ${spacingFluidMedium};
`

export const SuccessWrapper = styled.div`
    padding: ${spacingFluidLarge} 0;
`

export const GridContent = styled.div`
    padding-bottom: ${spacingFluidXXLarge};

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${getMediaQueryMax('m')} {
        grid-column: ${gridNarrowColumnStart} / ${gridNarrowColumnEnd};
    }
`

export const GridContentWrapper = styled.div`
    text-align: center;
    ${getMediaQueryMax('s')} {
        padding: 0 ${spacingFluidLarge};
    }
`

export const VerticalSpacingFluidMedium = styled.div`
    margin: ${spacingFluidMedium} 0;
`
