import { useQueryClient } from '@tanstack/react-query'
import { OfferPriceDtoCurrencyEnum, purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'

import { MatomoCategory } from '~/app/context/matomoContext'
import { getHeadlineDataQueryKey } from '~/app/hooks/queries/useHeadlineDataQuery'
import { getOfferStatusDataKey } from '~/app/hooks/queries/useOfferStatusDataQuery'
import { getPurchaseRequestQueryKey } from '~/app/hooks/queries/usePurchaseRequestQuery'
import { useMatomo } from '~/app/hooks/useMatomo'
import { usePurchaseRequestVisibility } from '~/app/hooks/usePurchaseRequestVisibility'
import { useDealerFrontendContext } from '~/dealerFrontendProvider'

export interface SendOfferProps {
    purchaseRequestId: string
    onFinished: () => void
    hidden: boolean
}

export const useSendOffer = ({ onFinished, purchaseRequestId, hidden }: SendOfferProps) => {
    const {
        dealerContext: { currency, dealershipId, locale },
    } = useDealerFrontendContext()
    const { trackEvent } = useMatomo()
    const queryClient = useQueryClient()

    const { mutatePurchaseRequestVisibility } = usePurchaseRequestVisibility()

    return async (offerPrice: string) => {
        trackEvent({ category: MatomoCategory.VEHICLE_DETAILS, action: 'syp-make-offer-submit' })
        await purchaseRequestDealerAppEndpointsApi.makeOffer({
            purchaseRequestId,
            makeOfferRequestBodyDto: {
                dealerEntryDn: dealershipId,
                dealerLanguageTag: locale,
                price: {
                    value: Number(offerPrice),
                    currency: currency as OfferPriceDtoCurrencyEnum,
                },
            },
        })

        // In case purchase request was set to "hidden" by the dealer, set it back to "visible" so it's shown in the 'My offers' list
        if (hidden) {
            await mutatePurchaseRequestVisibility({ purchaseRequestId, hidden: false })
        }

        // After the dealer has made an offer, the offer status should be re-fetched to change from 'No offer made'
        // this can be achieved by invalidating affected queries

        // awaiting the result of a query invalidation can be ignored to make the UI somewhat snappier, resulting in
        // the modal being closed while the query invalidation is still running, leading to the OfferStatusTag being in
        // a loading when the user returns from the Offer Modal
        void queryClient.invalidateQueries({ queryKey: getOfferStatusDataKey(purchaseRequestId, dealershipId) })
        void queryClient.invalidateQueries({ queryKey: getHeadlineDataQueryKey(purchaseRequestId, dealershipId) })
        void queryClient.invalidateQueries({
            queryKey: getPurchaseRequestQueryKey(purchaseRequestId, dealershipId),
        })

        onFinished()
    }
}
