import { getMediaQueryMin, spacing } from '@porsche-design-system/components-react/styles'
import type { MileageDto } from 'dealer-prs-api-client'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { FullWidth } from '~/app/base-components/layout/full-width/FullWidth'
import { SpacingFluidMedium } from '~/app/base-components/styled/Spacer'
import { VehicleDetailGrid, VehicleDetailsItem } from '~/app/components/layout/VehicleDetails'
import { HeadingLarge } from '~/app/components/market-price/price-estimation/PriceEstimation.styled'
import { PCSSLink } from '~/app/components/pcss-link'
import { formatDistance } from '~/app/utils/FormatDistance'

import { messages } from './messages'

export interface ConditionAndHistoryViewProps {
    mileage: MileageDto
    firstRegistration: Date
    previousOwners: number
    fullServiceHistory: boolean
    vatDeductible: boolean | undefined
    pcssUrl: string | undefined
}

const NewLine = styled.div`
    grid-column: 1 / span 1;

    ${getMediaQueryMin('xs')} {
        grid-column: 1 / span 2;
    }
`

export const ConditionAndHistoryView = ({
    mileage,
    firstRegistration,
    previousOwners,
    fullServiceHistory,
    vatDeductible,
    pcssUrl,
}: ConditionAndHistoryViewProps) => {
    const intl = useIntl()

    return (
        <ConditionAndHistoryLayout>
            <HeadingLarge>{intl.formatMessage(messages.heading)}</HeadingLarge>
            <SpacingFluidMedium />
            <VehicleDetailGrid>
                <VehicleDetailsItem
                    label={intl.formatMessage(messages.mileage)}
                    value={formatDistance(mileage.value, mileage.unit, intl)}
                />
                <VehicleDetailsItem
                    label={intl.formatMessage(messages.firstRegistration)}
                    /* Intentionally ignoring browser timezone offset to avoid shifting into previous/next day compared to what the seller entered (see ARD 030-prevent-date-timezone-problems.md) */
                    value={intl.formatDate(firstRegistration, { timeZone: 'utc' })}
                />
                <VehicleDetailsItem label={intl.formatMessage(messages.noOfPreviousOwners)} value={previousOwners} />
                <VehicleDetailsItem
                    label={intl.formatMessage(messages.fullServiceHistory)}
                    value={intl.formatMessage(
                        fullServiceHistory ? messages.fullServiceHistoryYes : messages.fullServiceHistoryNo,
                    )}
                />
                {vatDeductible !== undefined ? (
                    <VehicleDetailsItem
                        label={intl.formatMessage(messages.vatDeductible)}
                        value={intl.formatMessage(vatDeductible ? messages.vatDeductibleYes : messages.vatDeductibleNo)}
                    />
                ) : null}
                {pcssUrl && (
                    <NewLine>
                        <PCSSLink pcssUrl={pcssUrl} />
                    </NewLine>
                )}
            </VehicleDetailGrid>
        </ConditionAndHistoryLayout>
    )
}

const ConditionAndHistoryLayout = styled(FullWidth)`
    margin: ${spacing.fluid.medium} 0;
`
