/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferPriceDto } from './OfferPriceDto';
import {
    OfferPriceDtoFromJSON,
    OfferPriceDtoFromJSONTyped,
    OfferPriceDtoToJSON,
} from './OfferPriceDto';

/**
 * 
 * @export
 * @interface MakeOfferRequestLegacyDto
 */
export interface MakeOfferRequestLegacyDto {
    /**
     * Price of the offer
     * @type {OfferPriceDto}
     * @memberof MakeOfferRequestLegacyDto
     */
    price: OfferPriceDto;
    /**
     * Unique dealership EntryDn in PPN
     * @type {string}
     * @memberof MakeOfferRequestLegacyDto
     */
    dealerEntryDn: string;
    /**
     * The id of a purchase request
     * @type {string}
     * @memberof MakeOfferRequestLegacyDto
     */
    purchaseRequestId: string;
    /**
     * language-tag (e.g. it-CH) in dealer browser (not any browser locale, but one of the language-tags we support for this market, e.g. it can only be de-DE for market de)
     * @type {string}
     * @memberof MakeOfferRequestLegacyDto
     */
    dealerLanguageTag: string;
}

/**
 * Check if a given object implements the MakeOfferRequestLegacyDto interface.
 */
export function instanceOfMakeOfferRequestLegacyDto(value: object): value is MakeOfferRequestLegacyDto {
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('dealerEntryDn' in value) || value['dealerEntryDn'] === undefined) return false;
    if (!('purchaseRequestId' in value) || value['purchaseRequestId'] === undefined) return false;
    if (!('dealerLanguageTag' in value) || value['dealerLanguageTag'] === undefined) return false;
    return true;
}

export function MakeOfferRequestLegacyDtoFromJSON(json: any): MakeOfferRequestLegacyDto {
    return MakeOfferRequestLegacyDtoFromJSONTyped(json, false);
}

export function MakeOfferRequestLegacyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MakeOfferRequestLegacyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'price': OfferPriceDtoFromJSON(json['price']),
        'dealerEntryDn': json['dealerEntryDn'],
        'purchaseRequestId': json['purchaseRequestId'],
        'dealerLanguageTag': json['dealerLanguageTag'],
    };
}

export function MakeOfferRequestLegacyDtoToJSON(value?: MakeOfferRequestLegacyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'price': OfferPriceDtoToJSON(value['price']),
        'dealerEntryDn': value['dealerEntryDn'],
        'purchaseRequestId': value['purchaseRequestId'],
        'dealerLanguageTag': value['dealerLanguageTag'],
    };
}

