import { PInlineNotification, PSpinner } from '@porsche-design-system/components-react'
import { getMediaQueryMax, spacing, themeLightContrastMedium } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { FullWidth } from '~/app/base-components/layout/full-width/FullWidth'
import { HeadingMedium } from '~/app/base-components/styled/Heading'
import { HeadingLarge, TextSmall } from '~/app/components/market-price/price-estimation/PriceEstimation.styled'

import { messages } from './messages'

export interface EquipmentData {
    categories: {
        name?: string
        options: {
            name?: string
            optionCode: string
        }[]
    }[]
}

export interface EquipmentViewProps {
    isLoading: boolean
    data?: EquipmentData
}

export const EquipmentView = ({ data, isLoading }: EquipmentViewProps) => {
    const intl = useIntl()

    if (isLoading) {
        return <PSpinner />
    }

    if (!data) {
        return (
            <PInlineNotification state="error" dismissButton={false}>
                {intl.formatMessage(messages.noDataError)}
            </PInlineNotification>
        )
    }
    const categoriesWithEquipmentItems = data.categories.map((e, i) => {
        return e.options.length === 0 ? null : (
            <Category key={i}>
                <CategoryHeadline>{e.name}</CategoryHeadline>
                {e.options.map((f, j) => {
                    return <EquipmentItem key={j} name={f.name ?? '-'} optionCode={f.optionCode} />
                })}
            </Category>
        )
    })

    const total = categoriesWithEquipmentItems.length
    const thirdRoundedUp = Math.round(total / 3)

    const firstBlock = 0
    const secondBlock = thirdRoundedUp
    const thirdBlock = thirdRoundedUp * 2

    // Note this "grid" has only one row containing sub-elements of varying heights
    const firstColumn =
        total === 1 ? categoriesWithEquipmentItems[0] : categoriesWithEquipmentItems.slice(firstBlock, secondBlock)
    const secondColumn = total === 1 ? null : categoriesWithEquipmentItems.slice(secondBlock, thirdBlock)
    const thirdColumn = total === 1 ? null : categoriesWithEquipmentItems.slice(thirdBlock)

    return (
        <EquipmentLayout>
            <CardHeadline>{intl.formatMessage(messages.heading)}</CardHeadline>
            <ThreeColumnsOneRow>
                <Column>{firstColumn}</Column>
                <Column>{secondColumn}</Column>
                <Column>{thirdColumn}</Column>
            </ThreeColumnsOneRow>
        </EquipmentLayout>
    )
}

type EquipmentItemProps = { name: string; optionCode: string }
const EquipmentItem = ({ name, optionCode }: EquipmentItemProps) => {
    return (
        <EquipmentItemStyle>
            <OptionCode>{optionCode}</OptionCode>
            <OptionName>{name}</OptionName>
        </EquipmentItemStyle>
    )
}

export const OptionCode = styled(TextSmall)`
    color: ${themeLightContrastMedium};
    margin: 0;
    margin-inline-end: ${spacing.static.small};
`
export const OptionName = styled(TextSmall)`
    margin: 0;
    // = Don't break words
    hyphens: none;
`

export const EquipmentItemStyle = styled.div({
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBlockStart: spacing.fluid.small,
})

const Category = styled.div`
    margin-bottom: ${spacing.fluid.small};
`
const CardHeadline = styled(HeadingLarge)`
    margin-bottom: ${spacing.fluid.medium};
`
const CategoryHeadline = styled(HeadingMedium)``

const ThreeColumnsOneRow = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${spacing.fluid.medium};
    width: 100%;

    ${getMediaQueryMax('s')} {
        grid-template-columns: 1fr;
    }
`

export const Column = styled.div`
    display: flex;
    gap: ${spacing.fluid.medium};
    flex-direction: column;
    justify-content: flex-start;
`

const EquipmentLayout = styled(FullWidth)`
    margin: ${spacing.fluid.medium} 0;
`
