import { spacing } from '@porsche-design-system/components-react/styles'
import { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { HeadingXXLarge } from '~/app/base-components/styled/Heading'

interface PageTitleProps extends PropsWithChildren {
    headingComponent: ReactNode
}

export const PageTitle = ({ headingComponent, children }: PageTitleProps) => {
    return (
        <HeaderWrapper>
            <HeadingXXLarge>{headingComponent}</HeadingXXLarge>
            {children}
        </HeaderWrapper>
    )
}

export default PageTitle

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${spacing.fluid.medium};
    padding-bottom: ${spacing.fluid.small};
`
