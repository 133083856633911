import type { AppProps } from './app/app'

export const defaultMockContext: AppProps['context'] = {
    dealershipInfo: {
        name: 'Porsche Zentrum Stuttgart',
        locationCode: 'STT',
    },
    username: 'cspurchase',
    availableLocales: ['de'],
    countryCode: 'DE',
    currency: 'EUR',
    dateFormat: 'dd.MM.yyyy',
    dealershipId: '11556',
    distance: 'km',
    environment: 'local',
    getAuthorizationHeader: () => Promise.resolve({ Authorization: 'Bearer xxxx' }),
    marketplaceKey: 'de',
    partnerNumber: '133742',
    locale: 'de-DE',
    permissions: {
        addVehicle: false,
        accountSettings: false,
        manageExtraCosts: false,
    },
}

export const getMockContext = (overrides?: Partial<AppProps['context']>): AppProps['context'] => ({
    ...defaultMockContext,
    ...(overrides ?? {}),
})

export const getMockContextFromPathname = (location: Location): AppProps['context'] => {
    const match = location.pathname.match(/\/(\d+)\/([\w-]+)\//)
    if (match === null) {
        console.log('[local-dev]: Pathname did not match pattern, redirecting to default pathname')
        // User
        location.pathname = '/11556/de-DE/purchase-requests'
        // just for completeness, won't be used for long
        return getMockContext()
    } else {
        const [, dealershipId, locale] = location.pathname.match(/\/(\d+)\/([\w-]+)\//)!
        console.log(
            `[local-dev]: Using default mock context with these overrides: dealershipId: '${dealershipId}', locale: '${locale}'`,
        )
        return getMockContext({
            dealershipId,
            locale: locale as AppProps['context']['locale'],
        })
    }
}
