import { PLink } from '@porsche-design-system/components-react'
import { spacingFluidSmall } from '@porsche-design-system/components-react/styles'
import styled from 'styled-components'

interface MarketplaceLinkButtonProps {
    title: string
    link: string
}

const PLinkWithSpacing = styled(PLink)`
    &:first-of-type {
        margin-inline-end: ${spacingFluidSmall};
    }
`

const MarketplaceLinkButton = ({ title, link }: MarketplaceLinkButtonProps) => {
    return (
        <PLinkWithSpacing href={link} icon="external" variant={'tertiary'} target={'_blank'}>
            {title}
        </PLinkWithSpacing>
    )
}

export default MarketplaceLinkButton
