import { PSpinner } from '@porsche-design-system/components-react'
import { PriceEstimationPriceDtoCurrencyEnum } from 'dealer-prs-api-client'
import { useIntl } from 'react-intl'

import { PriceTagWithWarnings } from './components/PriceTagWithWarnings'
import { priceEstimationMessages } from './messages'
import {
    ContentBox,
    DataProviderExplanation,
    PopoverWithWrapText,
    SpacingFluidSmall,
    TextSmall,
    TextWithoutWrap,
} from './PriceEstimation.styled'
import { PriceEstimationFeedback } from './PriceEstimationFeedback'
import { PriceTile, PriceTileGrid } from './PriceTile'

export interface PriceEstimationViewProps {
    currency: PriceEstimationPriceDtoCurrencyEnum | undefined
    error: Error | null
    isLoading: boolean
    marketPrice: number | undefined
    marketPriceWarnings: string[] | undefined
    purchaseRequestId: string
}
export const PriceEstimationView = ({
    currency,
    error,
    isLoading,
    marketPrice,
    marketPriceWarnings,
    purchaseRequestId,
}: PriceEstimationViewProps) => {
    const intl = useIntl()

    if (isLoading) {
        return <PriceEstimationLoadingView />
    }

    if (error || !marketPrice || !currency) {
        return <PriceEstimationErrorView />
    }

    const elements = [
        {
            label: intl.formatMessage(priceEstimationMessages.labelMarketPriceEstimation),
            element: (
                <PriceTagWithWarnings
                    currency={currency}
                    explainerText={intl.formatMessage(priceEstimationMessages.labelPriceEstimationWarningsPopup)}
                    marketPrice={marketPrice}
                    marketPriceWarnings={marketPriceWarnings}
                />
            ),
        },
        {
            label: intl.formatMessage(priceEstimationMessages.labelSource),
            element: (
                <DataProviderExplanation>
                    <TextWithoutWrap>Analytic Company</TextWithoutWrap>
                    <SpacingFluidSmall />
                    <PopoverWithWrapText
                        direction={'top'}
                        description={intl.formatMessage(priceEstimationMessages.infoPopupAboutAnalyticCompany)}
                    />
                </DataProviderExplanation>
            ),
        },
    ]

    return (
        <PriceTile
            heading={intl.formatMessage(priceEstimationMessages.tileHeading)}
            elements={elements}
            FeedbackElement={<PriceEstimationFeedback purchaseRequestId={purchaseRequestId} />}
        />
    )
}

export const PriceEstimationErrorView = () => {
    const intl = useIntl()

    return (
        <PriceTileGrid>
            <ContentBox>
                <TextSmall>{intl.formatMessage(priceEstimationMessages.labelPriceEstimationNotPossible)}</TextSmall>
            </ContentBox>
        </PriceTileGrid>
    )
}

export const PriceEstimationLoadingView = () => {
    const intl = useIntl()

    return (
        <PriceTileGrid>
            <ContentBox>
                <TextSmall>{intl.formatMessage(priceEstimationMessages.labelPriceEstimationIsLoading)}</TextSmall>
                <PSpinner size="medium" aria={{ 'aria-label': 'Loading page content' }} />
            </ContentBox>
        </PriceTileGrid>
    )
}
