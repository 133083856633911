/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service API
 * API of Purchase Request Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PurchaseRequestDto } from './PurchaseRequestDto';
import {
    PurchaseRequestDtoFromJSON,
    PurchaseRequestDtoFromJSONTyped,
    PurchaseRequestDtoToJSON,
} from './PurchaseRequestDto';

/**
 * 
 * @export
 * @interface PurchaseRequestListPage
 */
export interface PurchaseRequestListPage {
    /**
     * Total count of requests in database e.g. 0 or 5000.
     * @type {number}
     * @memberof PurchaseRequestListPage
     */
    totalNrOfItems: number;
    /**
     * Purchase requests for given market
     * @type {Array<PurchaseRequestDto>}
     * @memberof PurchaseRequestListPage
     */
    items: Array<PurchaseRequestDto>;
}

/**
 * Check if a given object implements the PurchaseRequestListPage interface.
 */
export function instanceOfPurchaseRequestListPage(value: object): value is PurchaseRequestListPage {
    if (!('totalNrOfItems' in value) || value['totalNrOfItems'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function PurchaseRequestListPageFromJSON(json: any): PurchaseRequestListPage {
    return PurchaseRequestListPageFromJSONTyped(json, false);
}

export function PurchaseRequestListPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseRequestListPage {
    if (json == null) {
        return json;
    }
    return {
        
        'totalNrOfItems': json['totalNrOfItems'],
        'items': ((json['items'] as Array<any>).map(PurchaseRequestDtoFromJSON)),
    };
}

export function PurchaseRequestListPageToJSON(value?: PurchaseRequestListPage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalNrOfItems': value['totalNrOfItems'],
        'items': ((value['items'] as Array<any>).map(PurchaseRequestDtoToJSON)),
    };
}

