import { purchaseRequestDealerAppEndpointsApi } from 'dealer-prs-api-client'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useDealerFrontendContext } from '~/dealerFrontendProvider'

import { Feedback, FeedbackProps } from './Feedback'

export type FeedbackPayload = {
    feedback: string
    feedbackQuestion: string
    likertQuestion: {
        event: {
            category: string
            action: string
        }
        answerLabel: string
        questionTitle: string
    }
    timestamp: Date
}

export type FeedbackContainerProps = Omit<FeedbackProps, 'onSubmit'>

export const FeedbackContainer = ({
    page,
    likert,
    feedbackQuestion,
    theme,
    alwaysShowFeedbackQuestion,
}: FeedbackContainerProps) => {
    const { locale } = useIntl()
    const {
        dealerContext: { marketplaceKey },
    } = useDealerFrontendContext()

    const submit = useCallback(
        async (params: FeedbackPayload): Promise<boolean> => {
            try {
                await purchaseRequestDealerAppEndpointsApi.submitFeedback({
                    feedbackInputDto: {
                        feedback: {
                            ...params,
                            application: 'Sell your Porsche',
                            page,
                            url: window.location.href,
                            marketplaceId: marketplaceKey,
                            languageTag: locale,
                        },
                    },
                })
                return true
            } catch (e) {
                console.error(e)
                return false
            }
        },
        [locale, marketplaceKey, page],
    )

    return (
        <Feedback
            theme={theme}
            onSubmit={submit}
            likert={likert}
            feedbackQuestion={feedbackQuestion}
            page={page}
            alwaysShowFeedbackQuestion={alwaysShowFeedbackQuestion}
        />
    )
}
