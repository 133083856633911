import { border, spacingFluidMedium, themeLightBackgroundBase } from '@porsche-design-system/components-react/styles'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

export const Card = ({ children }: PropsWithChildren) => (
    <CardWrapper>
        <CardLayout>{children}</CardLayout>
    </CardWrapper>
)

const CardWrapper = styled.div`
    border-radius: ${border.radius.medium};
    padding: 0 ${spacingFluidMedium};
    background: ${themeLightBackgroundBase};
`

const CardLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
