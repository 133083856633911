import { useIntl } from 'react-intl'

interface FormattedMarketPriceProps {
    currency: string // TODO: Use type for supported currencies
    marketPrice: number
}

export function FormattedMarketPrice({ currency, marketPrice }: FormattedMarketPriceProps) {
    const intl = useIntl()

    return new Intl.NumberFormat(intl.locale, {
        currency: currency,
        style: 'currency',
        maximumFractionDigits: 0,
    }).format(marketPrice)
}
